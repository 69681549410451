import { get_img } from "../Services/images.jsx";
import Title_Text from "./title_text.jsx";
import external_links from "../Config/external_links.json";
import { validate_touch } from "../Services/helper_functions.jsx";


let credit_card_options_icons = get_img("credit_card_options_icons");
let three_coins_overhang = get_img("three_coins_overhang");
let all_cryptos = get_img("all_cryptos");
let coins_tiled = get_img("coins_tiled");
let credit_card = get_img("credit_card");
let low_gas_icon = get_img("low_gas_icon");

function How_To_Buy({launch_purchase_funnel}) {

    function open_purchase_funnel(credit_card=false) {
        launch_purchase_funnel(0,credit_card,false);
    }
    
    return (
        <div className="component how_to_buy" style={{backgroundColor: 'var(--light_orange)'}}>
            <div className="inner_component inner_component_with_overhang">
                
                
                <div className="block_with_inner"  style={{backgroundImage: `url(${coins_tiled})`}}>
                    <div className="inner_block">
                        <Title_Text text={<>HOW TO BUY</>} text_color={"var(--title_orange)"} outline_color={"black"}/>
                        <div className="inner_title">The ApeMax purchase funnel has been designed to be as easy as possible to use. Please don't hesitate to contact support if you are experiencing any issues and we will get back to you ASAP.</div>
                    </div>

                    <div className="inner_block">
                        <img src={all_cryptos} className="extra_top_image_large"/>
                        <Title_Text text={<>BUYING WITH CRYPTO</>} text_color={"var(--title_orange)"} outline_color={"black"} override_class={"title_text title_text_extra"}/>
                        <div className="inner_title">ApeMax can be purchased in six cryptocurrencies including Ethereum, USDT, USDC, Polygon / Matic, BNB, and BUSD! Simply press the button below and follow the instructions on screen to purchase your ApeMax.</div>
                        <div className="full_width_button"
                            onClick={()=>open_purchase_funnel(false)}
                            onTouchEnd={(e)=>{
                                if (validate_touch(e)) {
                                    e.preventDefault();
                                    open_purchase_funnel(false)
                                }
                            }}
                        ><b>Buy with Crypto</b></div>
                        
                    </div>

                    {/* <div className="inner_block">
                        <img src={credit_card} className="extra_top_image"/>
                        <Title_Text text={<>BUYING WITH CREDIT CARD</>} text_color={"var(--title_orange)"} outline_color={"black"} override_class={"title_text title_text_extra"}/>
                        <div className="inner_title">ApeMax can also be purchased with a Credit Card. This is ideal for users without an existing crypto wallet. For users who already have a wallet, you can also use this purchase funnel and immediately transfer the ApeMax to your prefered wallet in just a few clicks!</div>
                        <div className="full_width_button credit_card_dissabled credit_card_dissabled_show"
                            onClick={()=>open_purchase_funnel(true)}
                            onTouchEnd={(e)=>{
                                if (validate_touch(e)) {
                                    e.preventDefault();
                                    open_purchase_funnel(true);
                                }
                            }}
                        ><b>Buy with Credit Card</b><div className="coming_soon_extra_text">Coming Soon</div></div>
                        <img src={credit_card_options_icons} className="regular_extra_img"/>
                    </div> */}

                    {/* <div className="inner_block">
                        <img src={low_gas_icon} className="extra_top_image"/>
                        <br/><br/><br/>
                        <Title_Text text={<>GAS SAVER MODE</>} text_color={"var(--title_orange)"} outline_color={"black"} override_class={"title_text title_text_extra"}/>
                        <div className="inner_title">You can now purchase ApeMax and save a ton of gas using our Gas Saver Mode! When minting this way, your tokens are reserved for you and can be minted onto the blockchain starting from end of May. You will have ample time to mint them out and therefore choose the best day and time with lowest gas.<br/><br/>If you want your ApeMax coins immediately and do not care about the current high gas prices, you can mint using the Direct Mint Mode.</div>
                        <div className="full_width_button"
                            onClick={()=>open_purchase_funnel(false)}
                            onTouchEnd={(e)=>{
                                if (validate_touch(e)) {
                                    e.preventDefault();
                                    open_purchase_funnel(false);
                                }
                            }}
                        ><b>Buy with Crypto</b></div>
                        <div className="full_width_button credit_card_dissabled"
                            onClick={()=>open_purchase_funnel(true)}
                            onTouchEnd={(e)=>{
                                if (validate_touch(e)) {
                                    e.preventDefault();
                                    open_purchase_funnel(true);
                                }
                            }}
                        ><b>Buy with Credit Card</b><div className="coming_soon_extra_text">Coming Soon</div></div>
                        <div className="full_width_more_info"
                                onClick={()=>{window.open(external_links.guides)}}
                                onTouchEnd={(e)=>{
                                    if (validate_touch(e)) {
                                        e.preventDefault();
                                        window.open(external_links.guides);
                                    }
                                }}><u>Full Purchasing Guide</u></div>
                    </div> */}


                </div>

                <img src={three_coins_overhang} className="overhang"/>
                
            </div>

        </div>
    )
}

export default How_To_Buy;