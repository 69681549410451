import Title_Text from "./title_text.jsx";
import external_links from "../Config/external_links.json";
import { get_img } from "../Services/images.jsx";
import { validate_touch } from "../Services/helper_functions.jsx";

let safe_overhang = get_img("safe_overhang");
let tiled_keys = get_img("tiled_keys");
let kyc_icons = get_img("kyc_icons");
let wheel_barrel_ape = get_img("wheel_barrel_ape");

function Safe_Zone({launch_purchase_funnel}) {

    function open_purchase_funnel(credit_card=false) {
        launch_purchase_funnel(0,credit_card,false);
    }

    function go_to_how_to_buy() {
        const element = document.querySelector('.how_to_buy');
        element.scrollIntoView({ behavior: 'smooth' });
    }

    return (
        <div className="component" style={{backgroundColor: 'var(--light_blue)'}}>
            <div className="inner_component inner_component_with_overhang">
                
                
                <div className="block_with_inner"  style={{backgroundImage: `url(${tiled_keys})`}}>
                    <div className="inner_block">
                        <Title_Text text={<>SAFE ZONE</>} text_color={"var(--title_blue)"} outline_color={"black"}/>
                        <div className="inner_title">The ApeMax smart contract has undergone scrutiny by Saulidity, a renowned security auditing firm in the blockchain space. Read this comprehensive audit about the ApeMax smart contract.</div>
                        <img src={kyc_icons} className="big_img"/>
                        <div className="full_width_button"
                        onClick={() => {
                            window.open(external_links.audit);
                        }}
                        onTouchEnd={(e)=>{
                            e.preventDefault();
                            window.open(external_links.audit);
                        }}><b>Audit Report</b></div>
                        {/* <div className="full_width_button"
                        onClick={() => {
                            window.open(external_links.kyc);
                        }}
                        onTouchEnd={(e)=>{
                            e.preventDefault();
                            window.open(external_links.kyc);
                        }}><b>KYC Certificate</b></div> */}
                    </div>
                </div>
                <img src={safe_overhang} className="overhang"/>
            </div>
            <div className="inner_component inner_component_sequential_extra">
                <div className="standard_block">
                    <div className="safe_inner">
                        <div className="inner_half_split">
                            <Title_Text text={<>SMART CONTRACT DETAILS</>} text_color={"var(--title_blue)"} outline_color={"black"}/>
                            
                            <table className="standard_table">
                                <tr>
                                    <td>Blockchain</td>
                                    <td>Binance Smart Chain (BSC)</td>
                                </tr>
                                <tr>
                                    <td>Contract Address</td>
                                    <td>0x5bd97d0c8e668298f2a6baa069f47f556a447a78</td>
                                </tr>
                                <tr>
                                    <td>Token Symbol</td>
                                    <td>APEMAX</td>
                                </tr>
                                <tr>
                                    <td>Token Decimals</td>
                                    <td>18</td>
                                </tr>
                            </table><br/>
                            {/* <div className="full_width_button"
                            onClick={()=>open_purchase_funnel(false)}
                            onTouchEnd={(e)=>{
                                if (validate_touch(e)) {
                                    e.preventDefault();
                                    open_purchase_funnel(false);
                                }
                            }}><b>Buy with Crypto</b></div>
                            <div className="full_width_button credit_card_dissabled"
                                onClick={()=>open_purchase_funnel(true)}
                                onTouchEnd={(e)=>{
                                    if (validate_touch(e)) {
                                        e.preventDefault();
                                        open_purchase_funnel(true);
                                    }
                                }}><b>Buy with Credit Card</b><div className="coming_soon_extra_text">Coming Soon</div></div>
                            <img className="all_cryptos_img" src={get_img("all_cryptos")}/>
                            <div className="full_width_more_info"
                                onClick={go_to_how_to_buy}
                                onTouchEnd={(e)=>{
                                    if (validate_touch(e)) {
                                        e.preventDefault();
                                        go_to_how_to_buy();
                                    }
                                }}
                            ><u>How to buy?</u></div> */}
                        </div>
                        <div className="inner_half_split">
                            <img src={wheel_barrel_ape} className="full_img_in_split"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Safe_Zone;