import React from 'react';
import styled, { keyframes } from 'styled-components';
import { get_img } from "../Services/images.jsx";
let dollar_bill_overlay = get_img("dollar_bill_overlay"); 

const float = keyframes`
  0% { transform: translate(0, 0) rotate(0deg); }
  50% { transform: translate(0, 50px) rotate(10deg); }
  100% { transform: translate(0, 100px) rotate(0deg); }
`;

const float_and_fall = keyframes`
  0% { transform: translate(0, 0px) rotate(0deg); top: -100px; opacity: 0; }
  25% { transform: translate(-10px, 25%) rotate(50deg); opacity: 1; }
  50% { transform: translate(10px, 50%) rotate(0deg); }
  75% { transform: translate(-10px, 75%) rotate(30deg); opacity: 1; }
  100% { transform: translate(0, 100%) rotate(0deg); top: 115%; opacity: 0; }
`;


// ${float} 2s infinite ease-in-out, 
const Dollar_Bill_Wrapper = styled.div`
  position: absolute;
  animation: ${float_and_fall} 5s forwards;
  top: 110%;
  left: ${props => props.left}%;
  transform: rotate(${props => props.rotation}deg);
  width: 15%;
  object-fit: contain;
  pointer-events: none; 

  @keyframes fall {
    0% { top: -100px; }
    100% { top: 100%; }
  }
`;

const Dollar_Bill_Img = styled.img`
  width: 100%;
  height: 100%;
`;

const Dollar_Bill = ({ left, rotation, top }) => {
  return (
    <Dollar_Bill_Wrapper left={left} rotation={rotation} top={top}>
      <Dollar_Bill_Img src={dollar_bill_overlay} alt="Dollar Bill" />
    </Dollar_Bill_Wrapper>
  );
};

export default Dollar_Bill;
