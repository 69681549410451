import { useState, useEffect, useRef } from 'react';
// import { block_explorer } from "../Config/config.jsx";
import { get_img } from "../Services/images.jsx";
import { waitForTransaction } from '@wagmi/core'
import { shorten_crypto_string, validate_touch } from "../Services/helper_functions.jsx";



let purchase_funnel_icon_4 = get_img("purchase_funnel_icon_4");
let purchase_funnel_icon_4_spin = get_img("purchase_funnel_icon_4_spin");
let purchase_funnel_icon_5 = get_img("purchase_funnel_icon_5");
let cross_logo = get_img("cross_logo");
let error_icon = get_img("error_icon");

const block_explorer = `https://bscscan.com/tx`;

function Transaction_Tracker({hash="0x0000000"}) {

    const [transaction_hash, set_transaction_hash] = useState("");
    const [transaction_stage, set_transaction_stage] = useState(0);
    const [has_error, set_has_error] = useState(false);

    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    useEffect(()=> {
        set_transaction_stage(0);
        set_transaction_hash(<u onClick={() => {window.open(`${block_explorer}/${hash}`)}}
        onTouchEnd={(e)=>{
            if (validate_touch(e)) {
                e.preventDefault();
                window.open(`${block_explorer}/${hash}`)
            }
        }}
        className="clickable_text">{shorten_crypto_string(hash)}</u>);

        if (hash != "0x0000000") {
            (async function() {

                for (let i = 0; i < 3; i++) {
                    try {
                        let data = await waitForTransaction({hash});
                        set_transaction_stage(1);
                        return;
                    } catch (error) {
                        console.log(error);
                    }

                    await sleep(3000);
                }
                
                set_has_error(true);

                
            })()
        }
    },[hash])

    let pop_up_container = useRef();

    function close_pop_up() {
        document.querySelector('body').style.overflow = 'auto'
        pop_up_container.current.style.display = 'none';
    }

    return (
        <div className="overlay_container transaction_tracker" ref={pop_up_container}>
            <div className="pop_up_close_outter" onClick={close_pop_up}
            onTouchEnd={(e)=>{
                if (validate_touch(e)) {
                    e.preventDefault();
                    close_pop_up();
                }
            }}></div>
            <div className="pop_up_container pop_up_container_small">
                <div className="pop_up_title_simple"><b>Transaction Tracker</b></div>
                <img className="pop_up_close_simple" src={cross_logo} onClick={close_pop_up}
                onTouchEnd={(e)=>{
                    if (validate_touch(e)) {
                        e.preventDefault();
                        close_pop_up();
                    }
                }}/>
                
                {
                has_error == true ?
                    <div className="purchase_error_container" style={{marginTop: '70px'}}>
                        <img className="purchase_stage_icon" src={error_icon}/>
                        <div className="purchase_stage_title"><b>Transaction Error</b></div>
                        <div className="purchase_text">An error has occured during the transaction process. Please try again and pay attention to the instructions on screen. If the issue persists, please <u className="clickable_text">contact our customer support</u> for assistance.</div>
                    </div>
                    :
                    transaction_stage == 0 ?

                        <>
                            <img className="pop_up_track_icon" src={purchase_funnel_icon_4}/>
                            <img className="pop_up_icon_track_spin" src={purchase_funnel_icon_4_spin}/>
                            <div className="warning_title"><b>Transaction Pending</b></div>
                            <div className="warning_text">Your transaction has been submitted to the Ethereum blockchain and is now pending. It usually takes a few minutes to be executed on the blockchain. If your transaction appears to be taking too long you can speed it up from within your wallet app.<br/><br/>Transaction hash: {transaction_hash}</div>
                        </>
                        :
                        <>
                            <img className="pop_up_track_icon" src={purchase_funnel_icon_5}/>
                            <div className="warning_title"><b>Approved</b></div>
                            <div className="warning_text">Congragulations! Your transaction has been approved. It might take a few minutes for it to be reflected on the ApeMax dashboard.</div>
                        </>
                
                    
                }

                
                <br/>
            </div>
        </div>
    );
}

export default Transaction_Tracker;