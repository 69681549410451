import { useRef, useEffect, useState } from 'react';
import contract_abi from "../Config/token_abi.json";
import nft_abi from "../Config/nft_abi.json";
import { Web3Button, useWeb3Modal, Web3NetworkSwitch } from '@web3modal/react'
import { token_address, nft_address, admin_wallet, rpc_url, stable_coin_decimals, busd_address, polygon_rpc_url, bnb_rpc_url } from "../Config/config.jsx";
import { useAccount, useConnect, useNetwork } from 'wagmi';
import { prepareWriteContract, prepareSendTransaction, sendTransaction, writeContract, waitForTransaction, readContract, fetchBalance, InjectedConnector, switchNetwork, getNetwork } from '@wagmi/core'
import { get_price_auth, get_info, register_email, register_purchase_intent, retrieve_purchase_status, register_purchase_tx } from "../Services/api.jsx";
import { usdt_address, usdc_address, block_explorer } from '../Config/config.jsx';
import { short_format_number, shorten_crypto_string, track_event, preload_img, validate_touch, is_mobile_device } from "../Services/helper_functions.jsx";
import { CoinbaseWalletConnector } from '@wagmi/core/connectors/coinbaseWallet';
import { LedgerConnector } from '@wagmi/connectors/ledger';
import { MetaMaskConnector } from '@wagmi/core/connectors/metaMask';
import { get_native_token_balance, get_20_token_balance, wait_for_tx } from "../Services/web3_functions";
// import { CrossmintPayButton } from "@crossmint/client-sdk-react-ui";

// import { mainnet, polygon, bsc } from 'wagmi/chains'
// import { WalletConnectConnector } from 'wagmi/connectors/walletConnect'

import { ethers } from 'ethers';

import { crossmintModalService, clientNames } from "@crossmint/client-sdk-base";

import external_links from "../Config/external_links.json";

import Cookies from 'js-cookie';

import Discalimer from "../Components/disclaimer.jsx";

import UseTrackUser from "../Services/sales_attribution.jsx";

import { BigNumber } from 'ethers';

import { get_img } from "../Services/images.jsx";

import { mainnet, polygon, bsc, sepolia, polygonMumbai, hardhat } from "wagmi/chains";
const projectId = '3e07e365ebfe5653a62f32020fb507a1';

let credit_card_icon = get_img("credit_card_icon");
let metmask_icon = get_img("metmask_icon");
let coinbase_wallet_icon = get_img("coinbase_wallet_icon");
let ledger_wallet_icon = get_img("ledger_wallet_icon");
let plugin_wallet_icon = get_img("plugin_wallet_icon");
let other_wallets_icon = get_img("other_wallets_icon");
let walletconnect_icon = get_img("walletconnect_icon");
let ethereum_icon = get_img("ethereum_icon");
let usd_t_icon = get_img("usd_t_icon");
let usd_c_icon = get_img("usd_c_icon");
let bnb_icon = get_img("bnb_icon");
let busd_icon = get_img("busd_icon");
let polygon_icon = get_img("polygon_icon");
let apemax_coin = get_img("apemax_coin");
let purchase_funnel_icon_2 = get_img("purchase_funnel_icon_2");
let purchase_funnel_icon_3 = get_img("purchase_funnel_icon_3");
let purchase_funnel_icon_4 = get_img("purchase_funnel_icon_4");
let purchase_funnel_icon_4_spin = get_img("purchase_funnel_icon_4_spin");
let purchase_funnel_icon_5 = get_img("purchase_funnel_icon_5");
let error_icon = get_img("error_icon");
let apemax_rocket = get_img("apemax_rocket");
let cross_logo = get_img("cross_logo");
let tick_icon = get_img("tick_icon");



let lootbox_imgs = [];
for (let i = 0; i < 5; i++) {
    lootbox_imgs.push(get_img(`lootbox_${i}`));
}
let purchase_funnel_icons = [apemax_rocket, apemax_coin, purchase_funnel_icon_2, purchase_funnel_icon_3, purchase_funnel_icon_4, purchase_funnel_icon_5]

/*

TODO


- Final links



----------------

--> readmes
--> final deployments of contract
    --> find max runs
    --> choose compiler version

--> make deployment checklist
        --> !!!! change USDT and USDC addis on deploys depends on network
        --> reset server price
        --> clear events cache
        --> clear other caches

--> index token on etherscan, tokenlists.org, and trustwallet


TESTING
    --> delay times
    --> server price logic
    --> Cannot reproduce
        —> refresh stuck
    --> upgrade script

AFTER LAUNCH
    --> email me leads

*/



function Purchase_Funnel({shared_info, init_lootbox_index, lootboxes=false, credit_card=false, set_credit_card, purchase_id, s, ss, set_global_currency_index=function(){}}) {

    const { connect, connectors, isLoading, error, pendingConnector } = useConnect()
    
    const { get_tracking_data, attribute_sale } = UseTrackUser();

    const purchase_amount_ref = useRef();
    const purchase_apemax_qty_ref = useRef();

    const [needs_tos, set_needs_tos] = useState(true);
    const [agreed_tos, set_agreed_tos] = useState(false);

    const [purchase_stage, set_purchase_stage] = useState(0);
    const [currency_index, set_currency_index] = useState(0);
    const [approval_status, set_approval_status] = useState(0);
    const [apemax_price, set_apemax_price] = useState(void 0);
    const [transaction_hash, set_transaction_hash] = useState("");
    const [payment_amount, set_payment_amount] = useState("");
    const [has_purchase_error, set_has_purchase_error] = useState(false);
    const [purchase_error_debug, set_purchase_error_debug] = useState("");
    const [lootbox_info, set_lootbox_info] = useState([]);
    const [lootbox_index, set_lootbox_index] = useState(init_lootbox_index);
    const [iframe_url, set_iframe_url] = useState("");
    const [loading, set_loading] = useState(false);
    const [gas_saver_mode, set_gas_saver_mode] = useState(true);

    const email_input = useRef();
    async function add_email() {
        try {
            await register_email(email_input.current.value);
            alert("Thank you for your submission!.");
        } catch (error) {
            alert("Oh no, it looks like your response is invalid or incomplete.")
        }
    }
    
    const injected_connector = connectors[1]; //new InjectedConnector();
    const coinbase_connector = new CoinbaseWalletConnector({
        options: {
          appName: 'wagmi.sh',
          jsonRpcUrl: rpc_url,
        },
    });
    const ledger_connector = new LedgerConnector({
        chains: [mainnet, polygon, bsc],
        projectId,
    });
    const metamask_connector = new MetaMaskConnector();

    // const wallet_connector = new WalletConnectConnector({
    //     chains: [mainnet, polygon, bsc],
    //     options: {
    //       projectId: '3e07e365ebfe5653a62f32020fb507a1'
    //     },
    // })


    const from = useAccount();
    const { isConnected } = from;

    const web3Modal = useWeb3Modal();

    async function add_apemax_token() {
        if (window.ethereum) {

            const is_token_added = localStorage.getItem('is_token_added');

            if (chain.id != 56) {
                set_global_currency_index(56);
                return;
            }
            
            //if (is_token_added !== 'true') {
                try {
                    await window.ethereum.request({
                        method: 'wallet_watchAsset',
                        params: {
                            type: 'ERC20',
                            options: {
                                address: `0x5bd97d0c8e668298f2a6baa069f47f556a447a78`,
                                symbol: "APEMAX",
                                decimals: 18,
                                image: apemax_coin,
                            },
                        },
                        chainId: 56
                    });
    
                    localStorage.setItem('is_token_added', 'true');
                } catch (error) {
                    console.error('Something went wrong while trying to add the token.', error);
                }
            // } else {
            //     console.log('Token has already been added.')
            // }
        }
    }

    let { chain } = useNetwork();

    function get_chain_id() {
        if (currency_index < 5) {
            return 1;
        }
        else if (currency_index == 5) {
            return 137;
        }
        else if (currency_index < 8) {
            return 56;
        }
    }

    async function fix_network() {

        web3Modal.setDefaultChain(get_chain_id());

        if (isConnected) {
            if (currency_index < 5) {
                set_global_currency_index(1);
            }
            else if (currency_index == 5) {
                set_global_currency_index(137);
            }
            else if (currency_index < 8) {
                set_global_currency_index(56);
            }
        }
    }

    let has_switched = useRef(false);

    function check_network() {

        let net = getNetwork()

        console.log("CURRENT CHAIN",chain.id, net.chain.id)

        if (isConnected) {

            // if (!has_switched.current && chain.id != 1) {
            //     has_switched.current = true;
            //     return false;
            // }

            if (currency_index < 5 && net.chain.id != 1) {
                return false;
            }
            else if (currency_index == 5 && net.chain.id != 137) {
                return false;
            }
            else if (currency_index > 5 && currency_index < 8 && net.chain.id != 56) {
                return false;
            }
        }
        // else {
        //     return false;
        // }

        return true;
    }
    
    async function mint_handler() {


        // console.log(connectors[0])

        // await web3Modal.connect();

        if (!check_network()) {
            fix_network();
            return;
        }

        set_loading(true)
        set_has_purchase_error(false);

        try {

            if (currency_index == 0) {
                // await mint_tokens();
                await mint_native_token();
                set_loading(false);
            }
            else if (currency_index < 3) {
                // await mint_erc20();
                await mint_erc_token();
                set_loading(false);
            }
            else if (currency_index < 5) {
                await mint_via_nft();
            }
            else if (currency_index == 5) {
                await mint_native_token();
                set_loading(false);
            }
            else if (currency_index == 6) {
                await mint_native_token();
                set_loading(false);
            }
            else if (currency_index == 7) {
                // await mint_other_20token();
                await mint_erc_token();
                set_loading(false);
            }
        } catch (error) {
            console.log(error);
            set_purchase_stage(2);
            set_has_purchase_error(true);
            set_purchase_error_debug(error.toString());
            set_loading(false);
        }

        

    }

    /*

    Twitter

Intiate checkout
 twq('event', 'tw-oera3-oera6', {
    value: null, // use this to pass the value of the conversion (e.g. 5.00)
    currency: null, // use this to pass the currency of the conversion with an ISO 4217 code (e.g. ‘USD’)
    conversion_id: null // use this to pass a unique ID for the conversion event for deduplication (e.g. order id '1a2b3c')


    Purchase

    'tw-oera3-oeraa'

    Add to cart

    'tw-oera3-oerab'


    Google
        window.gtag('event', 'initiate_checkout', {
            'value': usd_price,
            'currency': 'USD',
            'transaction_id': purchase_id
        });

        add_to_cart

        add_to_cart


        fbq('track', 'Purchase');
        fbq('track', 'InitiateCheckout');
        fbq('track', 'AddToCart');

        add second twitter id...
        add fb
        make track fn

        make uuid gen client side...

    */

    

    function sleep (time) {
        return new Promise((resolve) => setTimeout(resolve, time));
    }

    async function wait_for_status_change(purchase_id) {
        let attempts = 0;
        const maxAttempts = 120;

        let overlay = document.querySelector("#__crossmint-overlay__");
        while (!overlay) {
            await sleep(200);
            overlay = document.querySelector("#__crossmint-overlay__");
        }
    
        return new Promise((resolve, reject) => {
            let overlay = document.querySelector("#__crossmint-overlay__");
    
            const overlayIntervalId = setInterval(() => {
                overlay = document.querySelector("#__crossmint-overlay__");
    
                if (!overlay) {
                    clearInterval(overlayIntervalId);
                    clearInterval(purchaseStatusIntervalId);
                    reject(new Error('Pop up closed'));
                }
            }, 1000);  // 1 second
    
            const purchaseStatusIntervalId = setInterval(async () => {
                if (attempts >= maxAttempts) {
                    clearInterval(purchaseStatusIntervalId);
                    clearInterval(overlayIntervalId);
                    reject(new Error('Max attempts reached'));
                }
    
                let { status } = await retrieve_purchase_status(purchase_id);
    
                if (status !== 'pending') {
                    clearInterval(purchaseStatusIntervalId);
                    clearInterval(overlayIntervalId);
                    resolve(status);
                }
    
                attempts++;
            }, 10000);  // 10 seconds
        });
    }    

    async function mint_via_nft() {

        

        let account = from.address;

        let amount_payable, quantity, timestamp, v, r, s;

        console.log({lootboxes})

        if (lootboxes) {
            ({ amount_payable, quantity, timestamp, v, r, s } = await get_price_auth(lootbox_index, 0, gas_saver_mode?4:currency_index));
        }
        else {
            // let purchase_value = document.querySelector(".purchase_funnel_input").value;
            let purchase_value = purchase_amount_ref.current.value;

            if (purchase_value <= 0) {
                alert("Oops! It seems like you've discovered the secret realm of negative numbers. Unfortunately, our system only accepts positive vibes (and numbers)!")
                set_loading(false);
                return;
            }

            if (purchase_value > 1000) {
                alert(`Our credit card provider currently does not support transactions worth over US$1,000. You can either split your transaction into multiple smaller transactions or purchase using cryptocurrency.`)
                set_loading(false);
                return;
            }

            ({ amount_payable, quantity, timestamp, v, r, s } = await get_price_auth(void 0, purchase_value, gas_saver_mode?4:currency_index));
        }

        console.log({
            amount_payable: amount_payable / 10**18,
            quantity: quantity / 10**18
        })
        
        // console.log({amount_payable});


        let usd_price = shared_info.exchange_rate * amount_payable / 10**18;
        if (gas_saver_mode) {
            usd_price = shared_info.polygon_exchange_rate * amount_payable / 10**18;
        }
        track_event(purchase_id, 1, usd_price);
        

        // twitter ffs!
        let query_string = new URLSearchParams(window.location.search);

        let twclid;

        if (query_string.has('twclid')) {
            twclid = query_string.get('twclid');
            localStorage.setItem('twclid', twclid);
        }
        else {
            twclid = localStorage.getItem('twclid');
        }
        
        twclid = twclid || "";

        let utm_params = {};
        let referrer = "";
        let ref_timestamp = Date.now() / 1000;

        try {
            ({utm_params, referrer, ref_timestamp } = get_tracking_data());
        } catch (error) {}

        await register_purchase_intent(
            purchase_id,
            navigator.userAgent,
            Cookies.get('_fbc'),
            Cookies.get('_fbp'),
            twclid,
            usd_price,
            utm_params,
            referrer,
            ref_timestamp,
            lootboxes
        );        

        const LIB_VERSION = "0.2.7-alpha.27";
        let clientId = "93763318-2ccc-4c62-b9af-fb953d09a2c8";
        let urlOrigin = "https://www.crossmint.com";
        let environment = "production";
        
        if (gas_saver_mode) {
            clientId = "6dbfa880-9312-4511-bb68-510afe18ce89";
            // clientId = "27387550-cc4c-4418-9d33-a64cb287e861";
            // urlOrigin = "https://staging.crossmint.com";
            // environment = "staging";
        }

        let locale = "en-US";
        let currency =  "usd";
        const { connect } = crossmintModalService({
            clientId: clientId,
            showOverlay: true,
            dismissOverlayOnClick: void 0,
            setConnecting: set_loading,
            libVersion: LIB_VERSION,
            environment,
            clientName: clientNames.reactUi,
            locale,
            currency,
            successCallbackURL: "", // --> Callbacks
            failureCallbackURL: "",
        });
        
        let mintConfig = {
            type: "erc-721",
            quantity: "1",
            totalPrice: (amount_payable / 10**18).toString(),
            
            // args
            recipient: account,
            amount_payable,
            quantity,
            timestamp,
            currency_index:gas_saver_mode?4:currency_index,
            v, r, s,
        }

        try {
            connect(
                mintConfig,
                void 0,
                void 0,
                void 0,
                purchase_id,
                void 0,
                void 0,
                void 0
            );

            let overlay = document.querySelector("#__crossmint-overlay__");
            for (let i = 0; i < 15; i++) {
                if (!overlay) {
                    await sleep(200);
                    overlay = document.querySelector("#__crossmint-overlay__");
                }
                else {
                    break;
                }
            }

            if (!overlay) {
                throw "error";
            }

            set_loading(false);

            set_purchase_stage(3);
            set_approval_status(3);

            try {

                let status = await wait_for_status_change(purchase_id);

                if (status == "success") {
                    set_purchase_stage(5);
                }
                else {
                    set_purchase_stage(2);
                    set_has_purchase_error(true);
                }

            } catch (error) {
                // Timeout
                set_purchase_stage(2);
                set_has_purchase_error(true);
                console.error('Error:', error);
            }

        } catch (error) {

            set_purchase_stage(3);
            set_approval_status(4);

            // "https://www.crossmint.com"
            

            const getMintQueryParams = () => {
                const mintQueryParams = {
                    clientId: clientId,
                    clientName: clientNames.reactUi,
                    clientVersion: LIB_VERSION,
                    mintConfig: JSON.stringify(mintConfig),
                    locale,
                    currency,
                    whPassThroughArgs: JSON.stringify(purchase_id)
                };
                return new URLSearchParams(mintQueryParams).toString();
            };

            const callbackUrl = encodeURIComponent(`${urlOrigin}/checkout/mint?${getMintQueryParams()}`);
            const url = `${urlOrigin}/signin?callbackUrl=${callbackUrl}&locale=${locale}&currency=${currency.toLowerCase()}`;

            // let url = `${urlOrigin}/signin?callbackUrl=https://apemax.io`
            // window.open(url);


            // console.log({url});
            window.location = url;


            

            // set_iframe_url(url);
            // set_purchase_stage(3);
            // set_approval_status(4);


        }

        


        /*

        let config = await prepareWriteContract({
            address: nft_address,
            abi: nft_abi,
            functionName: 'mint_apemax',
            args: [
                account,
                amount_payable,
                quantity,
                timestamp,
                currency_index,
                v, r, s,
            ],
            overrides: {
                gasLimit: 250000,
                value: amount_payable
            }
        });

        let { hash } = await sendTransaction(config);

        set_purchase_stage(4);

        console.log(hash);

        set_transaction_hash(<u onClick={() => {window.open(`${block_explorer}/${hash}`)}} className="clickable_text">{shorten_crypto_string(hash)}</u>)

        let data = await waitForTransaction({hash});
        

        set_purchase_stage(5);

        */

    }

    let relevant_abi = [
        {
          "constant": true,
          "inputs": [],
          "name": "name",
          "outputs": [{ "name": "", "type": "string" }],
          "payable": false,
          "type": "function",
        },
        {
            "constant": true,
            "inputs": [{"name": "_owner", "type": "address"}],
            "name": "balanceOf",
            "outputs": [{"name": "balance", "type": "uint256"}],
            "type": "function"
        },
        {
            "constant": true,
            "inputs": [
              { "name": "_owner", "type": "address" },
              { "name": "_spender", "type": "address" }
            ],
            "name": "allowance",
            "outputs": [{ "name": "", "type": "uint256" }],
            "payable": false,
            "type": "function"
        },
        {
          "constant": false,
          "inputs": [
            { "name": "_spender", "type": "address" },
            { "name": "_value", "type": "uint256" },
          ],
          "name": "approve",
          "outputs": [{ "name": "", "type": "bool" }],
          "payable": false,
          "type": "function",
        },
        {
            "inputs": [
              {
                "internalType": "address",
                "name": "_to",
                "type": "address"
              },
              {
                "internalType": "uint256",
                "name": "_value",
                "type": "uint256"
              }
            ],
            "name": "transfer",
            "outputs": [
              {
                "internalType": "bool",
                "name": "",
                "type": "bool"
              }
            ],
            "stateMutability": "nonpayable",
            "type": "function"
        }
    ];

    let usdt_abi = [
        {"constant":false,"inputs":[{"name":"_to","type":"address"},{"name":"_value","type":"uint256"}],"name":"transfer","outputs":[],"payable":false,"stateMutability":"nonpayable","type":"function"}
    ]
/*
    async function mint_erc20() {

        let account = from.address;


        let amount_payable, quantity, timestamp, v, r, s, purchase_value;

        if (lootboxes) {
            ({ amount_payable, quantity, timestamp, v, r, s } = await get_price_auth(lootbox_index, 0, currency_index));
            
            if (currency_index == 0) {
                purchase_value = lootbox_info[lootbox_index].eth_price;
            }
            else {
                purchase_value = lootbox_info[lootbox_index].usd_price;
            }

        }
        else {
            // purchase_value = document.querySelector(".purchase_funnel_input").value;
            purchase_value = purchase_amount_ref.current.value;

            if (purchase_value <= 0) {
                alert("Oops! It seems like you've discovered the secret realm of negative numbers. Unfortunately, our system only accepts positive vibes (and numbers)!")
                return;
            }

            ({ amount_payable, quantity, timestamp, v, r, s } = await get_price_auth(void 0, purchase_value, currency_index));

        }


        let usd_price = amount_payable / 10**stable_coin_decimals;
        track_event(purchase_id, 1, usd_price);

        set_payment_amount(`${purchase_value} ${currency_short_desc[currency_index]}`)

        
        let user_balance = await readContract({
            address: [usdt_address, usdc_address][currency_index-1],
            abi: relevant_abi,
            functionName: 'balanceOf',
            args: [
                account
            ]
        });

        if (Number(user_balance) < amount_payable) {
            alert(`You do not have sufficient ${currency_short_desc[currency_index]} to complete this purchase. You currently have ${(Number(user_balance)/10**stable_coin_decimals).toFixed(currency_index==0?4:2)} ${currency_short_desc[currency_index]} and you are trying to purchase ${purchase_value} ${currency_short_desc[currency_index]} worth of ApeMax. Please note we support credit card payments if you do not have sufficient cryptocurrency.`)
            update_price();
            return;
        }

        let mint_config;

        if (gas_saver_mode) {

            mint_config = await prepareWriteContract({
                address: [usdt_address, usdc_address][currency_index-1],
                abi: relevant_abi,
                functionName: 'transfer',
                args: [
                    admin_wallet,
                    amount_payable
                ]
            });

        }
        else {

            set_purchase_stage(3);
            set_approval_status(0);

            let user_allowance = await readContract({
                address: [usdt_address, usdc_address][currency_index-1],
                abi: relevant_abi,
                functionName: 'allowance',
                args: [
                    account,
                    token_address
                ]
            });


            if (Number(user_allowance) < amount_payable) {

                let approval_config = await prepareWriteContract({
                    address: [usdt_address, usdc_address][currency_index-1],
                    abi: relevant_abi,
                    functionName: 'approve',
                    args: [
                        token_address,
                        amount_payable
                    ]
                });

                let { hash } = await sendTransaction(approval_config);

                set_transaction_hash(<u onClick={() => {window.open(`${block_explorer}/${hash}`)}} className="clickable_text">{shorten_crypto_string(hash)}</u>)
                set_approval_status(1);

                await wait_for_tx(hash, currency_index);
                // let data = await waitForTransaction({hash});

                user_allowance = await readContract({
                    address: [usdt_address, usdc_address][currency_index-1],
                    abi: relevant_abi,
                    functionName: 'allowance',
                    args: [
                        account,
                        token_address
                    ]
                });
            }

            set_approval_status(2);

            if (lootboxes && Number(user_allowance) < amount_payable) {
                alert("Oh no! it looks like you set an allowance for a smaller amount of stable coin than needed");
                throw "error";
            }
            else if (Number(user_allowance) < amount_payable) {
                ({ amount_payable, quantity, timestamp, v, r, s } = await get_price_auth(void 0, Number(user_allowance)/10**stable_coin_decimals, currency_index));
            }

            mint_config = await prepareWriteContract({
                address: token_address,
                abi: contract_abi,
                functionName: 'mint_apemax',
                args: [
                    account,
                    amount_payable,
                    quantity,
                    timestamp,
                    currency_index,
                    v, r, s,
                ],
                overrides: {
                    gasLimit: 180000
                }
            });
        }

        let mint_tx = await sendTransaction(mint_config);

        set_purchase_stage(4);

        set_transaction_hash(<u onClick={() => {window.open(`${block_explorer}/${mint_tx.hash}`)}} className="clickable_text">{shorten_crypto_string(mint_tx.hash)}</u>)

        await wait_for_tx(mint_tx.hash, currency_index);
        // let data = await waitForTransaction({hash: mint_tx.hash});
        

        track_event(purchase_id, 2, usd_price);

        set_purchase_stage(5);

        if (gas_saver_mode) {
            register_purchase_tx(mint_tx.hash, account, amount_payable, quantity, timestamp, currency_index, v, r, s);
        }

        let query_string = new URLSearchParams(window.location.search);

        let twclid;

        if (query_string.has('twclid')) {
            twclid = query_string.get('twclid');
            localStorage.setItem('twclid', twclid);
        }
        else {
            twclid = localStorage.getItem('twclid');
        }
        
        twclid = twclid || "";

        attribute_sale(
            purchase_id,
            usd_price,
            currency_index,
            lootboxes,
            Math.floor(Date.now() / 1000),
            navigator.userAgent,
            Cookies.get('_fbc'),
            Cookies.get('_fbp'),
            twclid,
        );
        
        if (!gas_saver_mode) {
            add_apemax_token();
        }

    }

    async function mint_other_20token() {
        let account = from.address;
        let amount_payable, quantity, timestamp, v, r, s, purchase_value;

        if (lootboxes) {
            ({ amount_payable, quantity, timestamp, v, r, s } = await get_price_auth(lootbox_index, 0, currency_index));
            purchase_value = lootbox_info[lootbox_index].usd_price;
        }
        else {
            purchase_value = purchase_amount_ref.current.value;

            if (purchase_value <= 0) {
                alert("Oops! It seems like you've discovered the secret realm of negative numbers. Unfortunately, our system only accepts positive vibes (and numbers)!")
                return;
            }

            ({ amount_payable, quantity, timestamp, v, r, s } = await get_price_auth(void 0, purchase_value, currency_index));

        }

        let usd_price = amount_payable / 10**18;
        track_event(purchase_id, 1, usd_price);

        set_payment_amount(`${purchase_value} ${currency_short_desc[currency_index]}`)

        // let user_balance = await get_20_token_balance(account, busd_address, currency_index);

        let user_balance = await readContract({
            address: busd_address,
            abi: relevant_abi,
            functionName: 'balanceOf',
            args: [
                account
            ]
        });

        if (Number(user_balance) < amount_payable) {
            alert(`You do not have sufficient ${currency_short_desc[currency_index]} to complete this purchase. You currently have ${(Number(user_balance)/10**18).toFixed(currency_index==0?4:2)} ${currency_short_desc[currency_index]} and you are trying to purchase ${purchase_value} ${currency_short_desc[currency_index]} worth of ApeMax. Please note we support credit card payments if you do not have sufficient cryptocurrency.`)
            update_price();
            return;
        }

        let mint_config = await prepareWriteContract({
            address: busd_address,
            abi: relevant_abi,
            functionName: 'transfer',
            args: [
                admin_wallet,
                amount_payable
            ]
        });


        let mint_tx = await sendTransaction(mint_config);

        set_purchase_stage(4);

        let explorer = [
            block_explorer,
            block_explorer,
            block_explorer,
            block_explorer,
            block_explorer,
            "https://polygonscan.com/tx",
            "https://bscscan.com/tx",
            "https://bscscan.com/tx",
        ][currency_index];

        set_transaction_hash(<u onClick={() => {window.open(`${explorer}/${mint_tx.hash}`)}} className="clickable_text">{shorten_crypto_string(mint_tx.hash)}</u>)

        await wait_for_tx(mint_tx.hash, currency_index);

        // let data = await waitForTransaction({hash: mint_tx.hash});
        
        track_event(purchase_id, 2, usd_price);

        set_purchase_stage(5);

        register_purchase_tx(mint_tx.hash, account, amount_payable, quantity, timestamp, currency_index, v, r, s);

        let query_string = new URLSearchParams(window.location.search);

        let twclid;

        if (query_string.has('twclid')) {
            twclid = query_string.get('twclid');
            localStorage.setItem('twclid', twclid);
        }
        else {
            twclid = localStorage.getItem('twclid');
        }
        
        twclid = twclid || "";

        attribute_sale(
            purchase_id,
            usd_price,
            currency_index,
            lootboxes,
            Math.floor(Date.now() / 1000),
            navigator.userAgent,
            Cookies.get('_fbc'),
            Cookies.get('_fbp'),
            twclid,
        );
        

    }
*/
    
    let minting_info = useRef(void 0);

    async function update_minting_info() {

        //set_loading(true);

        let amount_payable, quantity, timestamp, v, r, s;

        if (lootboxes) {
            ({ amount_payable, quantity, timestamp, v, r, s } = await get_price_auth(lootbox_index, 0, currency_index));
        }
        else {
            let purchase_value = purchase_amount_ref.current.value;
            if (purchase_value > 0) {
                ({ amount_payable, quantity, timestamp, v, r, s } = await get_price_auth(void 0, purchase_value, currency_index));
            }
        }

        minting_info.current = { amount_payable, quantity, timestamp, v, r, s };
       // set_loading(false);

       return { amount_payable, quantity, timestamp, v, r, s };

    }

/*
    function get_amount_to_pay() {

        let full_pay_amount;
        let usd_pay_amount;

        if (lootboxes) {

            // Calculate token amount to pay in full units
            let lootbox = lootbox_info[lootbox_index];

            if (currency_index == 0 || currency_index == 3) {
                full_pay_amount = lootbox.eth_price * 10**18;
            }
            else if (currency_index < 3) {
                full_pay_amount = lootbox.usd_price * 10**6;
            }
            else if (currency_index == 4 || currency_index == 5) {
                full_pay_amount = lootbox.polygon_price * 10**18;
            }
            else if (currency_index == 6) {
                full_pay_amount = lootbox.bnb_price * 10**18;
            }
            else if (currency_index == 7) {
                full_pay_amount = lootbox.usd_price * 10**18;
            }

            // Calculate USD equivalent
            usd_pay_amount = lootbox.usd_price;

        }
        else {

            // Calculate token amount to pay in full units
            if ([0,3,4,5,6,7].includes(currency_index)) {
                full_pay_amount = purchase_amount_ref.current.value * 10**18;
            }
            else {
                full_pay_amount = purchase_amount_ref.current.value * 10**6;
            }


            // Calculate USD equivalent
            if ([1,2,7].includes(currency_index)) {
                usd_pay_amount = purchase_amount_ref.current.value;
            }
            else {
                let exchange = shared_info.exchange_rate;

                if (currency_index == 4 || currency_index == 5) {
                    exchange = shared_info.polygon_exchange_rate;
                }
                else if (currency_index == 6) {
                    exchange = shared_info.bnb_exchange_rate;
                }

                usd_pay_amount = exchange * purchase_amount_ref.current.value;
            }
            
        }

        full_pay_amount = BigNumber.from(full_pay_amount.toString());

        return {
            full_pay_amount,
            usd_pay_amount
        }

    }
*/

    function get_usd_estimate() {
        let usd_pay_amount;

        if (lootboxes) {
            let lootbox = lootbox_info[lootbox_index];
            usd_pay_amount = lootbox.usd_price;
        }
        else {
            if ([1,2,7].includes(currency_index)) {
                usd_pay_amount = purchase_amount_ref.current.value;
            }
            else {
                let exchange = shared_info.exchange_rate;

                if (currency_index == 4 || currency_index == 5) {
                    exchange = shared_info.polygon_exchange_rate;
                }
                else if (currency_index == 6) {
                    exchange = shared_info.bnb_exchange_rate;
                }

                usd_pay_amount = exchange * purchase_amount_ref.current.value;
            }
        }

        return usd_pay_amount;
    }

    function update_purchase_pending(hash) {
        let explorer = [
            block_explorer,
            block_explorer,
            block_explorer,
            block_explorer,
            block_explorer,
            "https://polygonscan.com/tx",
            "https://bscscan.com/tx",
            "https://bscscan.com/tx",
        ][currency_index];
        set_transaction_hash(<u onClick={() => {window.open(`${explorer}/${hash}`)}} className="clickable_text">{shorten_crypto_string(hash)}</u>);
    }

    function track_purchase(usd_price, hash) {

        if (!usd_price) {
            try {
                usd_price = get_usd_estimate();
                usd_price = usd_price > 0 ? usd_price : 0;
            } catch(error) {}
        }

        let query_string = new URLSearchParams(window.location.search);

        let twclid;

        if (query_string.has('twclid')) {
            twclid = query_string.get('twclid');
            localStorage.setItem('twclid', twclid);
        }
        else {
            twclid = localStorage.getItem('twclid');
        }
        
        twclid = twclid || "";

        attribute_sale(
            purchase_id,
            usd_price,
            currency_index,
            lootboxes,
            Math.floor(Date.now() / 1000),
            navigator.userAgent,
            Cookies.get('_fbc'),
            Cookies.get('_fbp'),
            twclid,
            agreed_tos,
            hash
        );

    }

    function is_mobile_safari() {

        return is_mobile_device(); // for now just get all mobiles

        // const userAgent = navigator.userAgent;
        // return /iP(ad|hone|od).+Version\/[\d.]+.*Safari/i.test(userAgent);
    }

    async function complete_tx() {
        try {

            let config = config_safari.current;
            let  { amount_payable, quantity, timestamp, v, r, s, usd_pay_amount } = minting_info.current;

            let ext_hash;

            if (![0,3,4,5,6].includes(currency_index)) {
                const { hash } = await writeContract({
                    address: {1:usdt_address,2:usdc_address,7:busd_address}[currency_index],
                    abi: usdt_abi, //relevant_abi,
                    functionName: 'transfer',
                    args: [admin_wallet, amount_payable],
                    // overrides: {
                    //     gasLimit: 50000,
                    // },
                    chainId: get_chain_id(),
                    // gas: 50_000n,
                })
                ext_hash = hash;
            }
            else {
                let { hash } = await sendTransaction(config);
                ext_hash = hash;
            }

            

            set_purchase_stage(4);
            update_purchase_pending(ext_hash);

            try {
                track_event(purchase_id, 2, usd_pay_amount);
                track_purchase(usd_pay_amount, ext_hash);
            } catch (error) {}
            
            await wait_for_tx(ext_hash, currency_index);
            set_purchase_stage(5);

            register_purchase_tx(ext_hash, from.address, amount_payable, quantity, timestamp, currency_index, v, r, s);

        } catch (error) {
            try {

                let  { amount_payable } = minting_info.current;

                if ([0,3,4,5,6].includes(currency_index)) {
                    let user_balance = await fetchBalance({ address: from.address });
                    user_balance = user_balance.formatted * 10**18;
            
                    if (Number(user_balance) < amount_payable) {
                        set_purchase_stage(2);
                        set_loading(false);
                        alert(`You do not have sufficient ${currency_short_desc[currency_index]} to complete this purchase. Please make sure you are not trying to purchase for a value greater than the amount of ${currency_short_desc[currency_index]} you have in your wallet.`)
                        return;
                    }
                }
                else if ([1,2,7].includes(currency_index)) {
                    let user_balance = await readContract({
                        address: {1:usdt_address,2:usdc_address,7:busd_address}[currency_index],
                        abi: relevant_abi,
                        functionName: 'balanceOf',
                        args: [
                            from.address
                        ]
                    });

                    if (Number(user_balance) < amount_payable) {
                        set_purchase_stage(2);
                        set_loading(false);
                        alert(`You do not have sufficient ${currency_short_desc[currency_index]} to complete this purchase. Please make sure you are not trying to purchase for a value greater than the amount of ${currency_short_desc[currency_index]} you have in your wallet.`)
                        return;
                    }
                }
            } catch (error) {}

            if (error.code) {
                if (error.code == 4001 || error.code == -32000) {
                    set_purchase_stage(2);
                    set_loading(false);
                    return;
                }
            }

            console.log(error);
            set_purchase_stage(2);
            set_has_purchase_error(true);
            // set_purchase_error_debug(JSON.stringify(error));
            set_loading(false);

        }
    }
    
    let config_safari = useRef();

    async function mint_native_token() {

        // let { full_pay_amount, usd_pay_amount } = get_amount_to_pay();

        //if (minting_info.current) {

            let usd_pay_amount = get_usd_estimate();

            if (usd_pay_amount < 0) {
                alert("Oops! It seems like you've discovered the secret realm of negative numbers. Unfortunately, our system only accepts positive vibes (and numbers)!")
                return;
            }
            else if (usd_pay_amount < 5) {
                alert("We currently have a minimum purchase value of $5. This is because we are covering the minting gas costs on BSC and therefore cannot accept micro transactions.")
                return;
            }
            else if (usd_pay_amount > 1250) {
                alert("We currently have a maximum purchase value of $1,250 per transaction.")
                return;
            }

            let { amount_payable, quantity, timestamp, v, r, s } = await update_minting_info();
            
            try {

                track_event(purchase_id, 1, usd_pay_amount);

                set_purchase_stage(3);
                set_approval_status(2);

                let config = //await prepareSendTransaction({
                    {
                        chainId: [1,1,1,1,137,137,56,56][currency_index],
                        //request: {
                            to: admin_wallet, value: amount_payable, data: "0x", gas: 21_000n
                        //}
                }
                //});

                // // await prepareSendTransaction(
                // let config = {
                //     chainId: [1,1,1,1,137,137,56,56][currency_index],
                //     request: { chainId: [1,1,1,1,137,137,56,56][currency_index], to: admin_wallet, value: amount_payable, data: "0x", gasPrice: 0 }
                // };

                if (is_mobile_safari() && !window.ethereum) {

                    let config = //await prepareSendTransaction({
                    {
                        chainId: [1,1,1,1,137,137,56,56][currency_index],
                        //request: {
                            to: admin_wallet, value: amount_payable, data: "0x"
                            , gasLimit: 21000, gas: 21_000n
                        //}
                    }

                    config_safari.current = config;
                    minting_info.current = { amount_payable, quantity, timestamp, v, r, s, usd_pay_amount };
                    return;
                }
                
                console.log(config)
                let { hash } = await sendTransaction(config);

                set_purchase_stage(4);
                update_purchase_pending(hash);

                try {
                    track_event(purchase_id, 2, usd_pay_amount);
                    track_purchase(usd_pay_amount, hash);
                } catch (error) {}
                
                await wait_for_tx(hash, currency_index);
                set_purchase_stage(5);

                register_purchase_tx(hash, from.address, amount_payable, quantity, timestamp, currency_index, v, r, s);

            } catch (error) {

                console.log(error);

                

                let user_balance = await fetchBalance({ address: from.address });
                user_balance = user_balance.formatted * 10**18;
        
                if (Number(user_balance) < amount_payable) {
                    set_purchase_stage(2);
                    alert(`You do not have sufficient ${currency_short_desc[currency_index]} to complete this purchase. Please make sure you are not trying to purchase for a value greater than the amount of ${currency_short_desc[currency_index]} you have in your wallet.`)
                }
                else {

                    if (error.code) {
                        if (error.code == 4001 || error.code == -32000) {
                            set_purchase_stage(2);
                            return;
                        }
                    }

                    console.log(error);
                    throw error;
                    // throw 'Error';
                }

            }

        //}
        
    }

    async function mint_erc_token() {
        //if (minting_info.current) {

            let usd_pay_amount = get_usd_estimate();

            if (usd_pay_amount < 0) {
                alert("Oops! It seems like you've discovered the secret realm of negative numbers. Unfortunately, our system only accepts positive vibes (and numbers)!")
                return;
            }
            else if (usd_pay_amount < 5) {
                alert("We currently have a minimum purchase value if $5. This is because we are covering the minting gas costs on BSC and therefore cannot accept micro transactions.")
                return;
            }
            else if (usd_pay_amount > 1250) {
                alert("We currently have a maximum purchase value of $1,250 per transaction.")
                return;
            }

            let { amount_payable, quantity, timestamp, v, r, s } = await update_minting_info();

            try {

                track_event(purchase_id, 1, usd_pay_amount);

                set_purchase_stage(3);
                set_approval_status(2);

                let mint_config;

                if (is_mobile_safari() && !window.ethereum) {

                    // mint_config = await prepareWriteContract({
                    //     address: {1:usdt_address,2:usdc_address,7:busd_address}[currency_index],
                    //     abi: relevant_abi,
                    //     functionName: 'transfer',
                    //     args: [
                    //         admin_wallet,
                    //         amount_payable
                    //     ],
                    //     overrides: {
                    //         gasLimit: 50000,
                    //     }
                    // });

                    mint_config = {};

                    config_safari.current = mint_config;
                    minting_info.current = { amount_payable, quantity, timestamp, v, r, s, usd_pay_amount };
                    return;
                }


                // const minimizedAbi = relevant_abi.filter(
                //     (abiItem) => "name" in abiItem && abiItem.name === 'transfer'
                // );

                // const config = {
                //     mode: "prepared",
                //     address: {1:usdt_address,2:usdc_address,7:busd_address}[currency_index],
                //     abi: minimizedAbi,
                //     functionName: 'transfer',
                //     args: [admin_wallet, amount_payable],
                //     gasLimit: 50000,
                //     chainId: 0,
                //     result: true
                // }

                // const { hash } = await writeContract(config)


                const { hash } = await writeContract({
                    address: {1:usdt_address,2:usdc_address,7:busd_address}[currency_index],
                    abi: usdt_abi, //relevant_abi,
                    functionName: 'transfer',
                    args: [admin_wallet, amount_payable],
                    // overrides: {
                    //     gasLimit: 60000,
                    // },
                    chainId: get_chain_id(),
                    // mode: "prepared"
                    // gas: 60_000n,
                })

                // admin_wallet
                   

                // mint_config = await prepareWriteContract({
                //     address: {1:usdt_address,2:usdc_address,7:busd_address}[currency_index],
                //     abi: relevant_abi,
                //     functionName: 'transfer',
                //     args: [
                //         admin_wallet,
                //         amount_payable
                //     ],
                //     overrides: {
                //         gasLimit: 50000,
                //     }
                // });

                console.log("THERE");

                
        
                // let { hash } = await writeContract(mint_config);

                set_purchase_stage(4);
                update_purchase_pending(hash);

                try {
                    track_event(purchase_id, 2, usd_pay_amount);
                    track_purchase(usd_pay_amount, hash);
                } catch (error) {}
                
                await wait_for_tx(hash, currency_index);
                set_purchase_stage(5);

                register_purchase_tx(hash, from.address, amount_payable, quantity, timestamp, currency_index, v, r, s);

            }
            catch (error) {

                console.log(error)

                let user_balance = await readContract({
                    address: {1:usdt_address,2:usdc_address,7:busd_address}[currency_index],
                    abi: relevant_abi,
                    functionName: 'balanceOf',
                    args: [
                        from.address
                    ]
                });

                if (Number(user_balance) < amount_payable) {
                    set_purchase_stage(2);
                    alert(`You do not have sufficient ${currency_short_desc[currency_index]} to complete this purchase. Please make sure you are not trying to purchase for a value greater than the amount of ${currency_short_desc[currency_index]} you have in your wallet.`)
                }
                else {

                    if (error.code) {
                        if (error.code == 4001 || error.code == -32000) {
                            set_purchase_stage(2);
                            return;
                        }
                    }

                    console.log(error);
                    throw 'Error';
                }

            }
        //}
    }

    /*


    async function mint_native_token_old() {

        let account = from.address;
        let amount_payable, quantity, timestamp, v, r, s, purchase_value;
        if (lootboxes) {
            ({ amount_payable, quantity, timestamp, v, r, s } = await get_price_auth(lootbox_index, 0, currency_index));
        }
        else {
            purchase_value = purchase_amount_ref.current.value;
            if (purchase_value <= 0) {
                alert("Oops! It seems like you've discovered the secret realm of negative numbers. Unfortunately, our system only accepts positive vibes (and numbers)!")
                return;
            }
            ({ amount_payable, quantity, timestamp, v, r, s } = await get_price_auth(void 0, purchase_value, currency_index));
        }

        console.log({
            amount_payable: amount_payable / 10**18,
            quantity: quantity / 10**18
        })

        console.log({
            amount_payable, quantity, timestamp, v, r, s
        });

        let exchange = shared_info.exchange_rate;
        if (currency_index == 5) {
            exchange = shared_info.polygon_exchange_rate;
        }
        else if (currency_index == 6) {
            exchange = shared_info.bnb_exchange_rate;
        }

        let usd_price = exchange * amount_payable / 10**18;
        track_event(purchase_id, 1, usd_price);

        // let user_balance = await get_native_token_balance(account, currency_index);
        let user_balance = await fetchBalance({
            address: account,
        });

        user_balance = user_balance.formatted * 10**18;

        console.log({user_balance})


        if (Number(user_balance) < amount_payable) {
            alert(`You do not have sufficition ${currency_short_desc[currency_index]} to complete this purchase. You currently have ${(Number(user_balance)/10**18).toFixed(currency_index==0?4:2)} ${currency_short_desc[currency_index]} and you are trying to purchase ${purchase_value} ${currency_short_desc[currency_index]} worth of ApeMax. Please note we support credit card payments if you do not have sufficient cryptocurrency.`)
            return;
        }

        set_purchase_stage(3);
        set_approval_status(2);

        let config = await prepareSendTransaction({
            request: { to: admin_wallet, value: amount_payable, data: "0x" }
        });

        let { hash } = await sendTransaction(config);
        

        set_purchase_stage(4);

        console.log(hash);

        let explorer = [
            block_explorer,
            block_explorer,
            block_explorer,
            block_explorer,
            block_explorer,
            "https://polygonscan.com/tx",
            "https://bscscan.com/tx",
            "https://bscscan.com/tx",
        ][currency_index];

        set_transaction_hash(<u onClick={() => {window.open(`${explorer}/${hash}`)}} className="clickable_text">{shorten_crypto_string(hash)}</u>)

        await wait_for_tx(hash, currency_index);
        // let data = await waitForTransaction({hash});
        
        track_event(purchase_id, 2, usd_price);

        set_purchase_stage(5);

        register_purchase_tx(hash, account, amount_payable, quantity, timestamp, currency_index, v, r, s);

        let query_string = new URLSearchParams(window.location.search);

        let twclid;

        if (query_string.has('twclid')) {
            twclid = query_string.get('twclid');
            localStorage.setItem('twclid', twclid);
        }
        else {
            twclid = localStorage.getItem('twclid');
        }
        
        twclid = twclid || "";

        attribute_sale(
            purchase_id,
            usd_price,
            currency_index,
            lootboxes,
            Math.floor(Date.now() / 1000),
            navigator.userAgent,
            Cookies.get('_fbc'),
            Cookies.get('_fbp'),
            twclid,
        );

    }

    async function mint_tokens() {

        let account = from.address;


        let amount_payable, quantity, timestamp, v, r, s, purchase_value;

        if (lootboxes) {
            ({ amount_payable, quantity, timestamp, v, r, s } = await get_price_auth(lootbox_index, 0, currency_index));
        
            if (currency_index == 0) {
                purchase_value = lootbox_info[lootbox_index].eth_price;
            }
            else {
                purchase_value = lootbox_info[lootbox_index].usd_price;
            }
        }
        else {
            // purchase_value = document.querySelector(".purchase_funnel_input").value;
            purchase_value = purchase_amount_ref.current.value;

            if (purchase_value <= 0) {
                alert("Oops! It seems like you've discovered the secret realm of negative numbers. Unfortunately, our system only accepts positive vibes (and numbers)!")
                return;
            }

            ({ amount_payable, quantity, timestamp, v, r, s } = await get_price_auth(void 0, purchase_value, currency_index));
        }

        console.log({
            amount_payable: amount_payable / 10**18,
            quantity: quantity / 10**18
        })

        console.log({
            amount_payable, quantity, timestamp, v, r, s
        });


        let usd_price = shared_info.exchange_rate * amount_payable / 10**18;
        track_event(purchase_id, 1, usd_price);
        
        let user_balance = await fetchBalance({
            address: account,
        })

        user_balance = user_balance.formatted * 10**18;

        if (Number(user_balance) < amount_payable) {
            alert(`You do not have sufficition ${currency_short_desc[currency_index]} to complete this purchase. You currently have ${(Number(user_balance)/10**18).toFixed(currency_index==0?4:2)} ${currency_short_desc[currency_index]} and you are trying to purchase ${purchase_value} ${currency_short_desc[currency_index]} worth of ApeMax. Please note we support credit card payments if you do not have sufficient cryptocurrency.`)
            return;
        }

        set_purchase_stage(3);
        set_approval_status(2);

        let config;

        if (gas_saver_mode) {
            config = await prepareSendTransaction({
                request: { to: admin_wallet, value: amount_payable, data: "0x" }
            });
        }
        else {
            config = await prepareWriteContract({
                address: token_address,
                abi: contract_abi,
                functionName: 'mint_apemax',
                args: [
                    account,
                    amount_payable,
                    quantity,
                    timestamp,
                    currency_index,
                    v, r, s,
                ],
                overrides: {
                    gasLimit: 180000,
                    value: amount_payable
                }
            });
        }

        let { hash } = await sendTransaction(config);
        

        set_purchase_stage(4);

        console.log(hash);

        set_transaction_hash(<u onClick={() => {window.open(`${block_explorer}/${hash}`)}} className="clickable_text">{shorten_crypto_string(hash)}</u>)

        await wait_for_tx(hash, currency_index);
        // let data = await waitForTransaction({hash});
        
        track_event(purchase_id, 2, usd_price);

        set_purchase_stage(5);

        if (gas_saver_mode) {
            register_purchase_tx(hash, account, amount_payable, quantity, timestamp, currency_index, v, r, s);
        }

        let query_string = new URLSearchParams(window.location.search);

        let twclid;

        if (query_string.has('twclid')) {
            twclid = query_string.get('twclid');
            localStorage.setItem('twclid', twclid);
        }
        else {
            twclid = localStorage.getItem('twclid');
        }
        
        twclid = twclid || "";

        attribute_sale(
            purchase_id,
            usd_price,
            currency_index,
            lootboxes,
            Math.floor(Date.now() / 1000),
            navigator.userAgent,
            Cookies.get('_fbc'),
            Cookies.get('_fbp'),
            twclid,
        );

        if (!gas_saver_mode) {
            add_apemax_token();
        }

        


    }
    */

    async function set_max(e) {

        e.target.innerHTML = `<b></b>`;
        let dot_count = 0;
        let loading_interval = setInterval(function(){
            dot_count++;
            dot_count = dot_count == 4 ? 0 : dot_count;
            let dots = '';
            for (let i = 0; i < dot_count; i++) {
                dots += '. ';
            }
            e.target.innerHTML = `<b>${dots}</b>`;
        },100)

        if (purchase_amount_ref.current) {

            let account = from.address;

            let max_value = 1000;

            if (currency_index == 0) {
                let user_balance = await get_native_token_balance(account, currency_index);
                max_value = (Number(user_balance) / 10**18).toFixed(6) - (gas_saver_mode ? 0.0045 : 0.015);
                max_value = max_value < 0 ? 0 : max_value;
            }
            else if (currency_index < 3) {
                let user_balance = await get_20_token_balance(account, [usdt_address, usdc_address][currency_index-1], currency_index);
                max_value = (Number(user_balance) / 10**stable_coin_decimals).toFixed(2);
            }
            else if (currency_index < 5) {}
            else if (currency_index == 5) {
                let user_balance = await get_native_token_balance(account, currency_index);
                max_value = (Number(user_balance) / 10**18 - 0.5).toFixed(4);
                max_value = max_value < 0 ? 0 : max_value;
            }
            else if (currency_index < 7) {
                let user_balance = await get_native_token_balance(account, currency_index);
                max_value = (Number(user_balance) / 10**18 - 0.001).toFixed(4);
                max_value = max_value < 0 ? 0 : max_value;
            }
            else if (currency_index == 7) {
                let user_balance = await get_20_token_balance(account, busd_address, currency_index);
                max_value = (Number(user_balance) / 10**18).toFixed(2);
            }

            purchase_amount_ref.current.value = max_value;
        }

        clearInterval(loading_interval);
        e.target.innerHTML = `<b>MAX</b>`;
    }

    let recent_value = useRef();

    function update_price(e) {

        // 6 decimals max
        /*
        let input = purchase_amount_ref.current;
        if (input) {
            input = input.value;
            if (input.includes('.')) {
                let decimalPos = input.indexOf('.');
                let decimalPart = input.slice(decimalPos+1, input.length);
                if (decimalPart.length > 6 && [0,5,6].includes(currency_index)) {
                    input = input.slice(0, decimalPos) + '.' + decimalPart.slice(0, 6);
                }
                else if (decimalPart.length > 2 && ![0,5,6].includes(currency_index)) {
                    input = input.slice(0, decimalPos) + '.' + decimalPart.slice(0, 2);
                }
            }

            purchase_amount_ref.current.value = input;
        }
        */

        // update_minting_info();

        // other stuff
        if (purchase_apemax_qty_ref.current && !lootboxes && apemax_price) {
            let amount_payable = purchase_amount_ref.current.value;

            if (currency_index == 0) {
                purchase_apemax_qty_ref.current.value = short_format_number(amount_payable / apemax_price.eth_price);
            }
            else if (currency_index < 5 || currency_index == 7) {
                purchase_apemax_qty_ref.current.value = short_format_number(amount_payable / apemax_price.base_price);
            }
            else if (currency_index == 5) {
                purchase_apemax_qty_ref.current.value = short_format_number(amount_payable / apemax_price.polygon_price);
            }
            else if (currency_full_desc == 6) {
                purchase_apemax_qty_ref.current.value = short_format_number(amount_payable / apemax_price.bnb_price);
            }

            recent_value.current = amount_payable;

            // update_minting_info();
        }
        else if (purchase_apemax_qty_ref.current && lootboxes && lootbox_info) {
            let quantiy = lootbox_info[lootbox_index].quantity;
            let og_quantity = lootbox_info[lootbox_index].og_quantity;
            purchase_apemax_qty_ref.current.value = `${short_format_number(quantiy)} (${short_format_number(quantiy-og_quantity)} free coins)`;
            
            // update_minting_info();
        }

    }

    function set_default_price() {

        if (purchase_amount_ref.current && !lootboxes) {
            purchase_amount_ref.current.value = default_values[currency_index];
            purchase_amount_ref.current.step = step_size[currency_index];
            update_price();
        }
        else if (lootboxes) {
            update_price();
        }
    }

    useEffect(() => {

        set_default_price();

        // if (isConnected) {
        //     switchNetwork({chainId: [1,1,1,1,137,137,56,56][currency_index]});
        // }

    },[currency_index]);

    let previous_stage = useRef(0);

    useEffect(() => {

        if (purchase_stage == 2 && previous_stage.current < 2) {
            set_default_price();
        }
        else if (recent_value.current && purchase_amount_ref.current && !lootboxes) {
            purchase_amount_ref.current.value = recent_value.current;
            purchase_amount_ref.current.step = step_size[currency_index];
            update_price();
        }

        previous_stage.current = purchase_stage;

    },[purchase_stage]);

    useEffect(()=>{
        set_lootbox_index(init_lootbox_index);
    },[init_lootbox_index]);


    useEffect(() => {
        if (shared_info) {
            set_apemax_price({
                base_price: shared_info.base_price,
                eth_price: shared_info.eth_price,
                polygon_price: shared_info.base_price / shared_info.polygon_exchange_rate,
                bnb_price: shared_info.base_price / shared_info.bnb_exchange_rate
            });
            set_lootbox_info(shared_info.lootboxes);
        }
        set_default_price();
    },[shared_info]);

    const [currency_selector_visible, set_currency_selector_visible] = useState(false);

    useEffect(() => {
        let purchase_currency_drop_down_list = document.querySelector(".purchase_currency_drop_down_list");
        if (purchase_currency_drop_down_list) {
            purchase_currency_drop_down_list.style.display = currency_selector_visible ? 'block':'none';
            update_price()
        }
        
    },[currency_selector_visible])

    const [lootbox_selector_visible, set_lootbox_selector_visible] = useState(false);
    useEffect(() => {
        let purchase_lootbox_drop_down_list = document.querySelector(".purchase_lootbox_drop_down_list");
        if (purchase_lootbox_drop_down_list) {
            purchase_lootbox_drop_down_list.style.display = lootbox_selector_visible ? 'block':'none';
            update_price();
        }
    },[lootbox_selector_visible]);

    useEffect(() => {
        if (credit_card) {
            set_currency_index(3);
        }

        // if (credit_card && purchase_stage < 2) {
        //     set_purchase_stage(2);
        // }
    },[credit_card]);

    useEffect(() => {
        set_default_price();
        set_agreed_tos(false);
    },[s])

    useEffect(()=> {
        preload_img(purchase_funnel_icon_4);
        preload_img(purchase_funnel_icon_4_spin);
    },[])


    

    const purchase_funnel_ref = useRef(null);

    function close_pop_up() {

        // ADD LOGIC
        document.querySelector('body').style.overflow = 'auto'
        purchase_funnel_ref.current.style.display = 'none';
        set_has_purchase_error(false);
        set_currency_selector_visible(false);
        set_purchase_stage(0);
        ss(false);
        set_loading(false)
    }

    let currency_icons = [ethereum_icon, usd_t_icon, usd_c_icon, credit_card_icon, credit_card_icon, polygon_icon, bnb_icon, busd_icon];
    let currency_titles = ["ETH","USDT","USDC","CARD","CARD","MATIC","BNB","BUSD"];
    let currency_short_desc = ["ETH","USDT","USDC","US$","US$","MATIC","BNB","BUSD"];
    let currency_full_desc = ["Pay in Ethereum","Pay in USDT","Pay in USDC","Pay by Credit Card","Pay by Credit Card","Pay in Polygon / Matic","Pay in BNB","Pay in BUSD"]
    let blockchain_desc = ["Ethereum","Ethereum","Ethereum","Ethereum","Ethereum","Polygon / Matic","Binance Smart Chain","Binance Smart Chain"]
    let default_values = [0.05, 100, 100, 100, 100, 100, 0.3, 100];
    let step_size = [0.01, 10, 10, 10, 10, 10, 10, 10];

    let purchase_stage_titles = [
        "Choose Payment Method",
        "Choose Cryptocurrency",
        "Choose ApeMax Quantity",
        "Approve Transaction",
        "Track Transaction",
        "Purchase Complete"
    ];

    if (isConnected && purchase_stage == 0) {
        set_purchase_stage(1);
    }
    
    async function handler_injected_provider() {
        if (window.ethereum) {
            await connect({ connector: injected_connector });
        } else {
            await web3Modal.open();
        }
    }

    // useEffect(()=>{
    //     set_purchase_stage(5)
    // },[])

    return (
        <div ref={purchase_funnel_ref} className="overlay_container purchase_funnel">
            <div className="pop_up_close_outter" onClick={close_pop_up}
            onTouchEnd={(e)=>{
                if (validate_touch(e)) {
                    e.preventDefault();
                    close_pop_up();
                }
            }}
            ></div>
            <div className="pop_up_container">

                <img className="pop_up_close" src={cross_logo}
                    onClick={close_pop_up}
                    onTouchEnd={(e)=>{
                        if (validate_touch(e)) {
                            e.preventDefault();
                            close_pop_up();
                        }
                    }}
                />

                <div className="pop_up_title"><b>{purchase_stage==-1?purchase_stage_titles[0]:purchase_stage_titles[purchase_stage]}</b></div>
                
                {
                    (!isConnected && !credit_card) || purchase_stage == -1 ?
                        (<div>
                            

                            
                            <div className="pop_up_inner">
                                <div className="purchase_qty_container">
                                    <img className="purchase_stage_icon" src={purchase_stage==-1?purchase_funnel_icons[0]:purchase_funnel_icons[purchase_stage]}/>
                                    <div className="purchase_stage_title"><b>How to buy?</b></div>
                                    {/* <div className="button_purchase_funnel" onClick={()=>{
                                        set_credit_card(true); set_currency_index(3); set_purchase_stage(2);
                                        }}
                                        onTouchEnd={(e)=>{
                                            if (validate_touch(e)) {
                                                e.preventDefault();
                                                set_credit_card(true); set_currency_index(3); set_purchase_stage(2);
                                            }
                                        }}>
                                        <img className="purchase_button_icon" src={credit_card_icon}/>
                                        Credit Card
                                    </div> */}

                                    

                                    <div className="button_purchase_funnel" onClick={handler_injected_provider}
                                        onTouchEnd={(e)=>{
                                            if (validate_touch(e)) {
                                                e.preventDefault();
                                                handler_injected_provider();
                                            }
                                        }}>
                                        <img className="purchase_button_icon" src={metmask_icon}/>
                                        Metamask
                                    </div>
                                    <div className="button_purchase_funnel" onClick={() => web3Modal.open()}
                                        onTouchEnd={(e)=>{
                                            if (validate_touch(e)) {
                                                e.preventDefault();
                                                web3Modal.open();
                                            }
                                        }}>
                                        <img className="purchase_button_icon" src={walletconnect_icon}/>
                                        Wallet Connect
                                    </div>
                                    <div className="button_purchase_funnel" onClick={() => connect({ connector: coinbase_connector })}
                                        onTouchEnd={(e)=>{
                                            if (validate_touch(e)) {
                                                e.preventDefault();
                                                connect({ connector: coinbase_connector });
                                            }
                                        }}>
                                        <img className="purchase_button_icon" src={coinbase_wallet_icon}/>
                                        Coinbase Wallet
                                    </div>
                                    <div className="button_purchase_funnel" onClick={() => connect({ connector: ledger_connector })}
                                        onTouchEnd={(e)=>{
                                            if (validate_touch(e)) {
                                                e.preventDefault();
                                                connect({ connector: ledger_connector });
                                            }
                                        }}>
                                        <img className="purchase_button_icon" src={ledger_wallet_icon}/>
                                        Ledger Wallet
                                    </div>
                                    <div className="button_purchase_funnel" onClick={handler_injected_provider}
                                        onTouchEnd={(e)=>{
                                            if (validate_touch(e)) {
                                                e.preventDefault();
                                                handler_injected_provider();
                                            }
                                        }}>
                                        <img className="purchase_button_icon" src={plugin_wallet_icon}/>
                                        Browser Wallet
                                    </div>
                                    <div className="button_purchase_funnel" onClick={() => web3Modal.open()}
                                        onTouchEnd={(e)=>{
                                            if (validate_touch(e)) {
                                                e.preventDefault();
                                                web3Modal.open();
                                            }
                                        }}>
                                        <img className="purchase_button_icon" src={other_wallets_icon}/>
                                        Most Other Wallets
                                    </div>
                                </div>
                                <Discalimer/>
                            </div>
                        </div>) :
                        isConnected && purchase_stage <= 1 && !credit_card ?
                        (<div>
                            <div className="pop_up_inner">
                                <div className="purchase_qty_container">
                                    <img className="purchase_stage_icon" src={purchase_funnel_icons[purchase_stage]}/>
                                    <div className="purchase_stage_title"><b>Pay in which crypto?</b></div>
                                    <div className="button_purchase_funnel" onClick={() => {set_currency_index(0); set_purchase_stage(2);}}
                                        onTouchEnd={(e)=>{
                                            if (validate_touch(e)) {
                                                e.preventDefault();
                                                set_currency_index(0); set_purchase_stage(2);
                                            }
                                        }}>
                                        <img className="purchase_button_icon" src={ethereum_icon}/>
                                        Pay in Ethereum
                                    </div>
                                    <div className="button_purchase_funnel" onClick={() => {set_currency_index(1); set_purchase_stage(2);}}
                                        onTouchEnd={(e)=>{
                                            e.preventDefault();
                                            set_currency_index(1); set_purchase_stage(2);
                                        }}>
                                        <img className="purchase_button_icon" src={usd_t_icon}/>
                                        Pay in USDT (ERC20)
                                    </div>
                                    <div className="button_purchase_funnel"  onClick={() => {set_currency_index(2); set_purchase_stage(2);}}
                                        onTouchEnd={(e)=>{
                                            if (validate_touch(e)) {
                                                e.preventDefault();
                                                set_currency_index(2); set_purchase_stage(2);
                                            }
                                        }}>
                                        <img className="purchase_button_icon" src={usd_c_icon}/>
                                        Pay in USDC (ERC20)
                                    </div>
                                    <div className="button_purchase_funnel"  onClick={() => {set_currency_index(5); set_purchase_stage(2);}}
                                        onTouchEnd={(e)=>{
                                            if (validate_touch(e)) {
                                                e.preventDefault();
                                                set_currency_index(5); set_purchase_stage(2);
                                            }
                                        }}>
                                        <img className="purchase_button_icon" src={polygon_icon}/>
                                        Pay in Polygon / Matic
                                    </div>
                                    <div className="button_purchase_funnel"  onClick={() => {set_currency_index(6); set_purchase_stage(2);}}
                                        onTouchEnd={(e)=>{
                                            if (validate_touch(e)) {
                                                e.preventDefault();
                                                set_currency_index(6); set_purchase_stage(2);
                                            }
                                        }}>
                                        <img className="purchase_button_icon" src={bnb_icon}/>
                                        Pay in BNB
                                    </div>
                                    <div className="button_purchase_funnel"  onClick={() => {set_currency_index(7); set_purchase_stage(2);}}
                                        onTouchEnd={(e)=>{
                                            if (validate_touch(e)) {
                                                e.preventDefault();
                                                set_currency_index(7); set_purchase_stage(2);
                                            }
                                        }}>
                                        <img className="purchase_button_icon" src={busd_icon}/>
                                        Pay in BUSD (BEP20)
                                    </div>
                                </div>
                                <Discalimer/>
                            </div>
                        </div>) :
                        purchase_stage == 2 || (credit_card && purchase_stage <= 2) ?
                            (<div>

                                {lootboxes ?
                                (<div className="pop_up_inner">
                                    {
                                        has_purchase_error ?
                                        (<div className="purchase_error_container">
                                            <img className="purchase_stage_icon" src={error_icon}/>
                                            <div className="purchase_stage_title"><b>Purchase Error</b></div>
                                            {/* {purchase_error_debug} */}
                                            <div className="purchase_text">An error has occured during the purchasing process. Please try again and pay attention to the instructions on screen. If the issue persists, please <u className="clickable_text" onClick={()=>{window.open(external_links.telegram)}}>contact our customer support</u> for assistance.</div>
                                            {
                                                !window.ethereum && is_mobile_device() ?
                                                <div className="purchase_text">We have detected that you may be using your default Web Browser on a mobile device such as Google Chrome or Safari. It is highly recommended for mobile users to <b>use the Web Browser in-built into your Web3 Wallet App.</b></div>:
                                                <></>
                                            }
                                            <div className="purchase_text"><b>NEW: You can now purchase manually simply by sending crypto to BuyApeMax.eth directly in your wallet!</b></div>
                                        </div>):
                                        (<div></div>)
                                    }
                                    
                                    <div className="purchase_qty_container">
                                        <img className="purchase_stage_icon" src={purchase_funnel_icons[purchase_stage]}/>
                                        <div className="purchase_stage_title"><b>Purchase Lootbox</b></div>

                                        <div className="purchase_funnel_label">Selected lootbox and amount you pay ({currency_short_desc[currency_index]})</div>
                                        <div className="lootbox_drop_down" onClick={() => {set_lootbox_selector_visible(!lootbox_selector_visible)}}
                                            onTouchEnd={(e)=>{
                                                if (validate_touch(e)) {
                                                    e.preventDefault();
                                                    set_lootbox_selector_visible(!lootbox_selector_visible)
                                                }
                                            }}>
                                            <img className="purchase_currency_drop_down_icon" src={lootbox_imgs[lootbox_index]}/>
                                            <div className="lootbox_drop_down_title"><b>{lootbox_info.length==0?"Loading...":`${lootbox_info[lootbox_index].name}`}</b></div>
                                            <div className="lootbox_drop_down_currency"><b>{

                                                function() {
                                                    if (lootbox_info.length == 0) {
                                                        return "Loading..."
                                                    }
                                                    else {
                                                        if (currency_index == 0) {
                                                            return (lootbox_info[lootbox_index].eth_price).toFixed(4);
                                                        }
                                                        else if (currency_index < 5 || currency_index == 7) {
                                                            return (lootbox_info[lootbox_index].usd_price).toFixed(2)
                                                        }
                                                        else if (currency_index == 5) {
                                                            return (lootbox_info[lootbox_index].polygon_price).toFixed(4);
                                                        }
                                                        else if (currency_index == 6) {
                                                            return (lootbox_info[lootbox_index].bnb_price).toFixed(4);
                                                        }
                                                    }
                                                }()
                                                }
                                                {currency_short_desc[currency_index]}  | <strike>{

                                                function() {
                                                    if (currency_index == 0) {
                                                        return (lootbox_info[lootbox_index].og_eth_cost).toFixed(4);
                                                    }
                                                    else if (currency_index < 5 || currency_index == 7) {
                                                        return (lootbox_info[lootbox_index].og_cost).toFixed(2);
                                                    }
                                                    else if (currency_index == 5) {
                                                        return (lootbox_info[lootbox_index].og_polygon_cost).toFixed(4);
                                                    }
                                                    else if (currency_index == 6) {
                                                        return (lootbox_info[lootbox_index].og_bnb_cost).toFixed(4);
                                                    }
                                                }()
                                                
                                                } {currency_short_desc[currency_index]}</strike> ({lootbox_info[lootbox_index].discount}% off)</b></div>
                                            <div className="purchase_currency_drop_down_extra">▼</div>
                                        </div>

                                        <div className="purchase_currency_drop_down" onClick={() => {set_currency_selector_visible(!currency_selector_visible)}}
                                            onTouchEnd={(e)=>{
                                                if (validate_touch(e)) {
                                                    e.preventDefault();
                                                    set_currency_selector_visible(!currency_selector_visible)
                                                }
                                            }}>
                                            <img className="purchase_currency_drop_down_icon" src={currency_icons[currency_index]}/>
                                            <div className="purchase_currency_drop_down_label"><b>{currency_titles[currency_index]}</b></div>
                                            <div className="purchase_currency_drop_down_extra">▼</div>
                                        </div>

                                        <div className="purchase_currency_drop_down_list">

                                            {(function() {
                                                let btns = [];
                                                for (let i = 0; i < 8; i++) {
                                                    if (i==3 || i==4) { continue; }
                                                    let curI = i;
                                                    btns.push(
                                                        <div className="purchase_currency_drop_down_item" onClick={() => {
                                                                if (isConnected || curI == 3) {
                                                                    set_currency_index(curI);
                                                                    set_currency_selector_visible(false);
                                                                }
                                                                else {
                                                                    set_purchase_stage(-1);
                                                                    set_currency_selector_visible(false);
                                                                }
                                                            }}
                                                            onTouchEnd={(e)=>{
                                                                if (validate_touch(e)) {
                                                                    e.preventDefault();
                                                                    if (isConnected || curI == 3) {
                                                                        set_currency_index(curI);
                                                                        set_currency_selector_visible(false);
                                                                    }
                                                                    else {
                                                                        set_purchase_stage(-1);
                                                                        set_currency_selector_visible(false);
                                                                    }
                                                                }
                                                            }}>
                                                            <img className="purchase_currency_drop_down_icon" src={currency_icons[i]}/>
                                                            <div className="purchase_currency_drop_down_label">{currency_titles[i]}</div>
                                                        </div>
                                                    )
                                                }
                                                return btns;
                                            })()}

                                        </div>

                                        <div className="purchase_lootbox_drop_down_list">

                                            {(function() {
                                                let btns = [];
                                                for (let i = 0; i < lootbox_info.length; i++) {
                                                    let curI = i;
                                                    btns.push(
                                                        <div className="purchase_currency_drop_down_item" onClick={() => {
                                                                set_lootbox_index(curI);
                                                                set_lootbox_selector_visible(false);
                                                            }}
                                                            onTouchEnd={(e)=>{
                                                                if (validate_touch(e)) {
                                                                    e.preventDefault();
                                                                    set_lootbox_index(curI);
                                                                    set_lootbox_selector_visible(false);
                                                                }
                                                            }}>
                                                            {/* <img className="purchase_background_drop_down_icon" src={blue_swirl_background}/> */}
                                                            <img className="purchase_currency_drop_down_icon" src={lootbox_imgs[i]}/>
                                                            <div className="lootbox_drop_down_title">{lootbox_info[i].name}</div>
                                                            <div className="lootbox_drop_down_currency">{

                                                                function() {
                                                                    if (lootbox_info.length == 0) {
                                                                        return "Loading..."
                                                                    }
                                                                    else {
                                                                        if (currency_index == 0) {
                                                                            return (lootbox_info[i].eth_price).toFixed(4);
                                                                        }
                                                                        else if (currency_index < 5 || currency_index == 7) {
                                                                            return (lootbox_info[i].usd_price).toFixed(2)
                                                                        }
                                                                        else if (currency_index == 5) {
                                                                            return (lootbox_info[i].polygon_price).toFixed(4);
                                                                        }
                                                                        else if (currency_index == 6) {
                                                                            return (lootbox_info[i].bnb_price).toFixed(4);
                                                                        }
                                                                    }
                                                                }()
                                                                }
                                                                {currency_short_desc[currency_index]}  | <strike>{

                                                                function() {
                                                                    if (currency_index == 0) {
                                                                        return (lootbox_info[i].og_eth_cost).toFixed(4);
                                                                    }
                                                                    else if (currency_index < 5 || currency_index == 7) {
                                                                        return (lootbox_info[i].og_cost).toFixed(2);
                                                                    }
                                                                    else if (currency_index == 5) {
                                                                        return (lootbox_info[i].og_polygon_cost).toFixed(4);
                                                                    }
                                                                    else if (currency_index == 6) {
                                                                        return (lootbox_info[i].og_bnb_cost).toFixed(4);
                                                                    }
                                                                }()

                                                                } {currency_short_desc[currency_index]}</strike> ({lootbox_info[i].discount}% off)


                                                            </div>
                                                        </div>
                                                    )
                                                }
                                                return btns;
                                            })()}

                                        </div>

                                        <div className="purchase_funnel_label">Amount of ApeMax you receive</div>
                                        <input className="purchase_funnel_input" value={
                                            apemax_price == void 0 || lootbox_info == void 0 ?
                                                "Loading..."
                                                :
                                                `${short_format_number(lootbox_info[lootbox_index].quantity)} (${short_format_number(lootbox_info[lootbox_index].quantity-lootbox_info[lootbox_index].og_quantity)} free coins)`
                                        } ref={purchase_apemax_qty_ref} readOnly/>
                                        <div className="purchase_currency_descriptor">
                                            <img className="purchase_currency_drop_down_icon" src={apemax_coin}/>
                                            <div className="purchase_currency_drop_down_label"><b>APEMAX</b></div>
                                        </div>

                                        <div className="form_tos_container" style={needs_tos?{display:"block"}:{display:"none"}}>
                                            <div className="form_tos_checkbox" style={agreed_tos?{backgroundColor: "var(--selected_blue)"}:{}}
                                                onClick={() => {
                                                    set_agreed_tos(!agreed_tos)
                                                }}
                                                onTouchEnd={async (e)=>{
                                                    if (validate_touch(e)) {
                                                        e.preventDefault();
                                                        set_agreed_tos(!agreed_tos);
                                                    }
                                                }}
                                            ><img className="tos_tick_icon" src={tick_icon}/></div>
                                            <div className="form_tos_text">I agree to the <u className="clickable_text" onClick={()=>{window.open(external_links.terms_of_use)}}>Terms of Service</u>, Disclaimer below, and <u className="clickable_text" onClick={()=>{window.open(external_links.privacy_policy)}}>Privacy Policy</u>. I have read and understood the guide on the <u className="clickable_text" onClick={()=>{window.open(external_links.disclaimer)}}>Risks of Buying ApeMax</u>. I hereby declare am not from any <u className="clickable_text" onClick={()=>{window.open(external_links.blocked_countries)}}>Banned Country</u>.</div>
                                        </div>

                                        <div className="button_purchase_funnel center_align"
                                        onClick={async (event) => {

                                            if (needs_tos && !agreed_tos) {
                                                alert("You must first read and agree to the Terms of Service and Privacy Policy. You must also declare that you understand the Risks of Purchasing ApeMax and are not from a country on the Banned Countries list.")
                                                return;
                                            }

                                            const btn = event.target;
                                            btn.style.pointerEvents = "none";
                                            btn.style.border = "5px solid var(--light_gray)";
                                            btn.style.backgroundColor = "var(--mid_gray)";
                                            await mint_handler();
                                            btn.style = "";
                                        }}
                                        onTouchEnd={async (e)=>{
                                            if (validate_touch(e)) {
                                                e.preventDefault();

                                                if (needs_tos && !agreed_tos) {
                                                    alert("You must first read and agree to the Terms of Service and Privacy Policy. You must also declare that you understand the Risks of Purchasing ApeMax and are not from a country on the Banned Countries list.")
                                                    return;
                                                }

                                                const btn = e.target;
                                                btn.style.pointerEvents = "none";
                                                btn.style.border = "5px solid var(--light_gray)";
                                                btn.style.backgroundColor = "var(--mid_gray)";
                                                await mint_handler();
                                                btn.style = "";
                                            }
                                        }}>
                                            {
                                                loading == true ? 
                                                <div className="purchase_button_icon">
                                                    <img className="purchase_button_icon_spin" src={currency_icons[currency_index]}/>
                                                </div>
                                                :
                                                <img className="purchase_button_icon" src={currency_icons[currency_index]}/>
                                            }
                                           
                                            {loading == true ? "Loading..." : currency_full_desc[currency_index]}
                                        </div>

                                        {
                                            !window.ethereum ?
                                            <>
                                                <div className="purchase_funnel_extra_label"><b>Please make sure your wallet is connected to the {blockchain_desc[currency_index]} blockchain. If you switch chains, please refresh this page for the change to take effect.</b></div>
                                            </> :
                                            <></>
                                        }

                                        {
                                            currency_index == 3 ?
                                            <div className="explanation_text_cc">
                                                <img className="crossmint_logo" src={"https://www.crossmint.io/assets/crossmint/logo.svg"}/>
                                                You will be redirected to Crossmint.io our credit card payment provider
                                            </div>
                                            :
                                            <div></div>
                                        }

                                        

                                    </div>
                                    <Discalimer/>
                                </div>)
                                :
                                (<div className="pop_up_inner">
                                    {
                                        has_purchase_error ?
                                        (<div className="purchase_error_container">
                                            <img className="purchase_stage_icon" src={error_icon}/>
                                            <div className="purchase_stage_title"><b>Purchase Error</b></div>
                                            {/* {purchase_error_debug} */}
                                            <div className="purchase_text">An error has occured during the purchasing process. Please try again and pay attention to the instructions on screen. If the issue persists, please <u className="clickable_text"  onClick={()=>{window.open(external_links.telegram)}}>contact our customer support</u> for assistance.</div>
                                            {
                                                !window.ethereum && is_mobile_device()  ?
                                                <div className="purchase_text">We have detected that you may be using your default Web Browser on a mobile device such as Google Chrome or Safari. It is highly recommended for mobile users to <b>use the Web Browser in-built into your Web3 Wallet App.</b></div>:
                                                <></>
                                            }
                                            <div className="purchase_text"><b>NEW: You can now purchase manually simply by sending crypto to BuyApeMax.eth directly in your wallet!</b></div>
                                        </div>):
                                        (<div></div>)
                                    }
                                    <div className="purchase_qty_container">
                                        <img className="purchase_stage_icon" src={purchase_funnel_icons[purchase_stage]}/>
                                        <div className="purchase_stage_title"><b>How Much ApeMax?</b></div>

                                        <div className="purchase_funnel_label">Amount you pay ({currency_short_desc[currency_index]})</div>
                                        {/* <div className="purchase_funnel_input"></div> */}

                                        <div className="purchase_funnel_input_container">
                                            <input className="purchase_funnel_input" type={"number"} onChange={update_price} ref={purchase_amount_ref} min="0" pattern="\d+(\.\d{1,2})?" inputmode="decimal"></input>
                                        

                                            <div className="purchase_currency_drop_down" onClick={() => {set_currency_selector_visible(!currency_selector_visible)}}
                                                onTouchEnd={async (e)=>{
                                                    if (validate_touch(e)) {
                                                        e.preventDefault();
                                                        set_currency_selector_visible(!currency_selector_visible);
                                                    }
                                                }}>
                                                <img className="purchase_currency_drop_down_icon" src={currency_icons[currency_index]}/>
                                                <div className="purchase_currency_drop_down_label"><b>{currency_titles[currency_index]}</b></div>
                                                <div className="purchase_currency_drop_down_extra">▼</div>
                                            </div>

                                            <div className="max_button" onClick={set_max} ><b>MAX</b></div>
                                        </div>

                                        <div className="purchase_currency_drop_down_list">

                                            {(function() {
                                                let btns = [];
                                                for (let i = 0; i < 8; i++) {
                                                    if (i==3 || i==4) { continue; }
                                                    let curI = i;
                                                    btns.push(
                                                        <div className="purchase_currency_drop_down_item" onClick={() => {
                                                                if (isConnected || curI == 3) {
                                                                    set_currency_index(curI);
                                                                    set_currency_selector_visible(false);
                                                                }
                                                                else {
                                                                    console.log("HERE")
                                                                    set_purchase_stage(-1);
                                                                    set_currency_selector_visible(false);
                                                                }
                                                            }}
                                                            onTouchEnd={async (e)=>{
                                                                if (validate_touch(e)) {
                                                                    e.preventDefault();

                                                                    if (isConnected || curI == 3) {
                                                                        set_currency_index(curI);
                                                                        set_currency_selector_visible(false);
                                                                    }
                                                                    else {
                                                                        console.log("HERE")
                                                                        set_purchase_stage(-1);
                                                                        set_currency_selector_visible(false);
                                                                    }
                                                                }
                                                            }}>
                                                            <img className="purchase_currency_drop_down_icon" src={currency_icons[i]}/>
                                                            <div className="purchase_currency_drop_down_label">{currency_titles[i]}</div>
                                                        </div>
                                                    )
                                                }
                                                return btns;
                                            })()}

                                        </div>


                                        <div className="purchase_funnel_label">Amount of ApeMax you receive</div>
                                        <input className="purchase_funnel_input" value={

                                            function() {

                                                if (apemax_price == void 0 || !purchase_amount_ref.current) {
                                                    return "Loading...";
                                                }
                                                else {
                                                    if (currency_index == 0) {
                                                        return short_format_number(purchase_amount_ref.current.value / apemax_price.eth_price);
                                                    }
                                                    else if (currency_index < 5 || currency_index == 7) {
                                                        return short_format_number(purchase_amount_ref.current.value / apemax_price.base_price);
                                                    }
                                                    else if (currency_index == 5) {
                                                        return short_format_number(purchase_amount_ref.current.value / apemax_price.polygon_price);
                                                    }
                                                    else if (currency_index == 6) {
                                                        return short_format_number(purchase_amount_ref.current.value / apemax_price.bnb_price);
                                                    }
                                                }

                                            }()

                                        }  ref={purchase_apemax_qty_ref} readOnly/>

                                        <div className="purchase_currency_descriptor">
                                            <img className="purchase_currency_drop_down_icon" src={apemax_coin}/>
                                            <div className="purchase_currency_drop_down_label"><b>APEMAX</b></div>
                                        </div>

                                        {/* {
                                            currency_index == 3 ?
                                            <CrossmintPayButton
                                                clientId=""
                                                environment="staging"
                                                mintConfig={{
                                                    type: "erc-721",
                                                    quantity: "_NUMBER_OF_NFTS_",
                                                    totalPrice: "_PRICE_IN_NATIVE_TOKEN_"
                                                    // your custom minting arguments...
                                                }}
                                            />
                                            :
                                            
                                        } */}

                                        <div className="form_tos_container" style={needs_tos?{display:"block"}:{display:"none"}}>
                                            <div className="form_tos_checkbox" style={agreed_tos?{backgroundColor: "var(--selected_blue)"}:{}}
                                                onClick={() => {
                                                    set_agreed_tos(!agreed_tos)
                                                }}
                                                onTouchEnd={async (e)=>{
                                                    if (validate_touch(e)) {
                                                        e.preventDefault();
                                                        set_agreed_tos(!agreed_tos);
                                                    }
                                                }}
                                            ><img className="tos_tick_icon" src={tick_icon}/></div>
                                            <div className="form_tos_text">I agree to the <u className="clickable_text" onClick={()=>{window.open(external_links.terms_of_use)}}>Terms of Service</u>, Disclaimer below, and <u className="clickable_text" onClick={()=>{window.open(external_links.privacy_policy)}}>Privacy Policy</u>. I have read and understood the guide on the <u className="clickable_text" onClick={()=>{window.open(external_links.disclaimer)}}>Risks of Buying ApeMax</u>. I hereby declare am not from any <u className="clickable_text" onClick={()=>{window.open(external_links.blocked_countries)}}>Banned Country</u>.</div>
                                        </div>

                                        <div className="button_purchase_funnel center_align" onClick={async (event) => {

                                            if (needs_tos && !agreed_tos) {
                                                alert("You must first read and agree to the Terms of Service and Privacy Policy. You must also declare that you understand the Risks of Purchasing ApeMax and are not from a country on the Banned Countries list.")
                                                return;
                                            }

                                            const btn = event.target;
                                            btn.style.pointerEvents = "none";
                                            btn.style.border = "5px solid var(--light_gray)";
                                            btn.style.backgroundColor = "var(--mid_gray)";
                                            await mint_handler();
                                            btn.style = "";
                                        }}
                                        onTouchEnd={async (e)=>{
                                            if (validate_touch(e)) {
                                                e.preventDefault();

                                                if (needs_tos && !agreed_tos) {
                                                    alert("You must first read and agree to the Terms of Service and Privacy Policy. You must also declare that you understand the Risks of Purchasing ApeMax and are not from a country on the Banned Countries list.")
                                                    return;
                                                }

                                                const btn = e.target;
                                                btn.style.pointerEvents = "none";
                                                btn.style.border = "5px solid var(--light_gray)";
                                                btn.style.backgroundColor = "var(--mid_gray)";
                                                await mint_handler();
                                                btn.style = "";
                                            }
                                        }}>
                                            {
                                                loading == true ? 
                                                <div className="purchase_button_icon">
                                                    <img className="purchase_button_icon_spin" src={currency_icons[currency_index]}/>
                                                </div>
                                                :
                                                <img className="purchase_button_icon" src={currency_icons[currency_index]}/>
                                            }
                                            {loading == true ? "Loading..." : currency_full_desc[currency_index]}
                                        </div>

                                        {
                                            !window.ethereum ?
                                            <>
                                                <div className="purchase_funnel_extra_label"><b>Please make sure your wallet is connected to the {blockchain_desc[currency_index]} blockchain. If you switch chains, please refresh this page for the change to take effect.</b></div>
                                            </> :
                                            <></>
                                        }

                                        {
                                            currency_index == 3 ?
                                            <div className="explanation_text_cc">
                                                <img className="crossmint_logo" src={"https://www.crossmint.io/assets/crossmint/logo.svg"}/>
                                                You will be redirected to Crossmint.io our credit card payment provider
                                            </div>
                                            :
                                            <div></div>
                                        }
                                        {/* 
                                        <div className="mint_gas_type_container">
                                            {
                                                gas_saver_mode ?
                                                <div className="explanation_text">Gas Saver Mode</div>
                                                :
                                                <div className="explanation_text">Direct Mint Mode</div>
                                            }
                                            <div className={gas_saver_mode?"gas_explanation_text green_text":"gas_explanation_text red_text"}><b>{gas_saver_mode ? "Highly Recommended":"Not Recommend"}</b></div>
                                            <div className={gas_saver_mode? "gas_switch":"gas_switch gas_switch_unselected"} onClick={
                                                () => {set_gas_saver_mode(!gas_saver_mode)}
                                            }>
                                                <div class={gas_saver_mode? "gas_slider":"gas_slider gas_slider_unselected"}></div>
                                            </div>
                                            {
                                                gas_saver_mode ?
                                                <div className="gas_explanation_text">Save a fortunue in gas by using our new gas saver mode!</div>
                                                :
                                                <div className="gas_explanation_text">Warning! Due to exceptionally high network activity, it is not currently recommeneded to mint in this mode.</div>
                                            }
                                            
                                        </div> */}

                                        
                                    </div>
                                    <Discalimer/>
                                </div>)}

                            </div>)
                            :
                            purchase_stage == 3 ?
                                (<div className="pop_up_inner">
                                    <div className="purchase_qty_container">
                                        {
                                            approval_status==0 ?
                                            (<div>
                                                <img className="purchase_stage_icon" src={purchase_funnel_icons[purchase_stage]}/>
                                                <div className="purchase_stage_title"><b>Approve Transfer</b></div>
                                                <div className="purchase_text">Please authorize our smart contract to transfer ${payment_amount} on your behalf. Please note this step is only required for payments in USDT and USDC.</div>
                                            </div>) :
                                            approval_status==1 ?
                                            (<div>
                                                <div className="purchase_stage_icon">
                                                    <img className="purchase_stage_icon_inner" src={purchase_funnel_icons[4]}/>
                                                    <img className="purchase_stage_icon_spin" src={purchase_funnel_icon_4_spin}/>
                                                </div>
                                                <div className="purchase_stage_title"><b>Approval Pending</b></div>
                                                <div className="purchase_text">Your authorization transaction has been submitted to the Ethereum blockchain and is now pending. It will usually take a few minutes to complete. Once completed you will be prompted the final transaction to finalize the purchase of ApeMax.<br/><br/>Transaction hash: {transaction_hash}<br/><br/><a style={{color: "red"}}>DO NOT CLOSE WINDOW (STEP 1/2)</a></div>
                                            </div>) :
                                            approval_status==2 ?
                                            (<div>
                                                <img className="purchase_stage_icon" src={purchase_funnel_icons[purchase_stage]}/>
                                                <div className="purchase_stage_title"><b>Approve Purchase</b></div>
                                                <div className="purchase_text">Please approve the purchase transaction to mint your ApeMax tokens!</div>
                                                {
                                                    (is_mobile_safari() && !window.ethereum) ?
                                                    <>

                                                    <div className="purchase_text">If you experience any issues please make sure your wallet is on the correct chain before proceeding. You may also need to refresh the page once you change the chain.</div>

                                                    <div className="button_purchase_funnel center_align" onClick={async (event) => {
                                                        const btn = event.target;
                                                        btn.style.pointerEvents = "none";
                                                        btn.style.border = "5px solid var(--light_gray)";
                                                        btn.style.backgroundColor = "var(--mid_gray)";
                                                        await complete_tx();
                                                        btn.style = "";
                                                    }}
                                                    onTouchEnd={async (e)=>{
                                                        if (validate_touch(e)) {
                                                            e.preventDefault();

                                                            const btn = e.target;
                                                            btn.style.pointerEvents = "none";
                                                            btn.style.border = "5px solid var(--light_gray)";
                                                            btn.style.backgroundColor = "var(--mid_gray)";
                                                            await complete_tx();
                                                            btn.style = "";
                                                        }
                                                    }}><b>Approve</b></div>
                                                    
                                                    
                                                    </> :
                                                    <></>
                                                }
                                            </div>)
                                            :
                                            approval_status==3 ?
                                            (<div>
                                                <div className="purchase_stage_icon">
                                                    <img className="purchase_stage_icon_inner" src={purchase_funnel_icons[4]}/>
                                                    <img className="purchase_stage_icon_spin" src={purchase_funnel_icon_4_spin}/>
                                                </div>
                                                <div className="purchase_stage_title"><b>Waiting For Crossmint</b></div>
                                                <div className="purchase_text">Please complete your purchase in the Crossmint user interface. You will be redirected here once your purchase is completed.</div>
                                                {/* <iframe className="iframe_purchase" src={iframe_url} frameBorder={"0"}/> */}
                                            </div>)
                                            :
                                            (<div>
                                                <div className="purchase_stage_icon">
                                                    <img className="purchase_stage_icon_inner" src={purchase_funnel_icons[4]}/>
                                                    <img className="purchase_stage_icon_spin" src={purchase_funnel_icon_4_spin}/>
                                                </div>
                                                <div className="purchase_stage_title"><b>Redirecting...</b></div>
                                                <div className="purchase_text">You will be redirected to Crossmint, our credit card payment provider shortly.</div>
                                            </div>)
                                        }
                                    </div>
                                    <Discalimer/>
                                </div>)
                                :
                                (<div className="pop_up_inner">
                                    <div className="purchase_qty_container">


                                        {
                                            purchase_stage == 4 ?
                                            (<div>
                                                <div className="purchase_stage_icon">
                                                    <img className="purchase_stage_icon_inner" src={purchase_funnel_icons[4]}/>
                                                    <img className="purchase_stage_icon_spin" src={purchase_funnel_icon_4_spin}/>
                                                </div>
                                                <div className="purchase_stage_title"><b>Track Transaction</b></div>
                                                <div className="purchase_text">Thank you for your purchase. Your transaction has been submitted to the Ethereum blockchain and is now pending. It usually takes a few minutes to be executed on the blockchain.<br/><br/>Transaction hash: {transaction_hash}<br/><br/><a style={{color: "red"}}>DO NOT CLOSE UNTIL PURCHASE IS COMPLETED</a></div>
                                            </div>) :
                                            (<div>
                                                <img className="purchase_stage_icon" src={purchase_funnel_icons[purchase_stage]}/>
                                                <div className="purchase_stage_title"><b>Purchase Complete</b></div>
                                                <div className="purchase_text">
                                                    <b style={{color: "var(--dark_blue)"}}>
                                                        Your tokens are on their way now.</b> It can take a few minutes for them to show up in your wallet.<br/><br/>Below are the instructions for adding the ApeMax tokens to your wallet. You can also view your token balance on the Profile page.<br/><br/>
                                                        <table className="standard_table">
                                                            <tr>
                                                                <td>Blockchain</td>
                                                                <td>Binance Smart Chain (BSC)</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Contract Address</td>
                                                                <td>0x5bd97d0c8e668298f2a6baa069f47f556a447a78</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Token Symbol</td>
                                                                <td>APEMAX</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Token Decimals</td>
                                                                <td>18</td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                        {
                                                            window.ethereum ? 
                                                            <>
                                                            <div className="button_purchase_funnel center_align" onClick={async (event) => {
                                                                add_apemax_token();
                                                            }}
                                                            onTouchEnd={async (e)=>{
                                                                if (validate_touch(e)) {
                                                                    e.preventDefault();
                                                                    add_apemax_token();
                                                                }
                                                            }}><b>Import Automatically</b></div>
                                                            </> :
                                                            <></>
                                                        }
                                                    <div className="purchase_text">
                                                        <b>What's next?</b><br/>
                                                        <br/>1. Get the latest updates on ApeMax by registering your email below.<br/>
                                                    </div>
                                                
                                                <input className="purchase_funnel_input email_input" placeholder='Email address' ref={email_input}/>
                                                <div className="submit_email_button">
                                                    <div className="submit_email_button_label" onClick={async (event)=> {
                                                        const btn = event.target;
                                                        btn.style.pointerEvents = "none";
                                                        btn.style.border = "5px solid var(--light_gray)";
                                                        btn.style.backgroundColor = "var(--mid_gray)";
                                                        btn.innerHTML = "Loading..."
                                                        await add_email();
                                                        btn.style = "";
                                                        btn.innerHTML = "Submit"
                                                    }}
                                                    onTouchEnd={async (e)=>{
                                                        if (validate_touch(e)) {
                                                            e.preventDefault();

                                                            const btn = e.target;
                                                            btn.style.pointerEvents = "none";
                                                            btn.style.border = "5px solid var(--light_gray)";
                                                            btn.style.backgroundColor = "var(--mid_gray)";
                                                            btn.innerHTML = "Loading..."
                                                            await add_email();
                                                            btn.style = "";
                                                            btn.innerHTML = "Submit"
                                                        }
                                                    }}><b>Submit</b></div>
                                                </div>
                                                <div className="purchase_text"> 
                                                    2. Join the ApeMax community:<br/><br/>
                                                    <u className="clickable_text" onClick={() => window.open(external_links.discord)}
                                                    onTouchEnd={(e)=>{
                                                        if (validate_touch(e)) {
                                                            e.preventDefault();
                                                            window.open(external_links.discord)
                                                        }
                                                    }}>Discord</u> | <u className="clickable_text" onClick={() => window.open(external_links.twitter)}
                                                    onTouchEnd={(e)=>{
                                                        if (validate_touch(e)) {
                                                            e.preventDefault();
                                                            window.open(external_links.twitter)
                                                        }
                                                    }}>Twitter</u> | <u className="clickable_text" onClick={() => window.open(external_links.telegram)}
                                                    onTouchEnd={(e)=>{
                                                        if (validate_touch(e)) {
                                                            e.preventDefault();
                                                            window.open(external_links.telegram)
                                                        }
                                                    }}>Telegram</u><br/>
                                                    <br/>
                                                    3. Start earning immediately by <u className="clickable_text" onClick={() => window.location = 'ranking'}
                                                    onTouchEnd={(e)=>{
                                                        if (validate_touch(e)) {
                                                            e.preventDefault();
                                                            window.location = 'ranking'
                                                        }
                                                    }}>Staking Your ApeMax</u><br/>
                                                </div>
                                            </div>)

                                        }

                                    </div>
                                    <Discalimer/>
                                </div>)
                }


                        

                

            </div>
            {/* <Web3Button/> */}
        </div>
    );
}

export default Purchase_Funnel;