import { useEffect, useState, useRef } from 'react';
import { Web3Button } from '@web3modal/react';
import { useAccount } from 'wagmi';
import { sales_amounts, get_unclaimed_ministerial_rewards, withdraw_currency, claim_ministerial_rewards, enable_transfers } from "../Services/web3_functions.jsx";
import { short_format_number } from '../Services/helper_functions.jsx';
import { admin_wallet, stable_coin_decimals } from "../Config/config.jsx";

function Admin() {

    const has_mounted = useRef(false);

    const selector = useRef();
    const transfers_selector = useRef();
    const { isConnected, address } = useAccount();
    const [balances, set_balances] = useState(["Loading...","Loading...","Loading...","Loading..."])
    useEffect(()=> {
        (async function() {

            if (has_mounted.current == false) {

                has_mounted.current = true;

                let b = [];
                for (let i = 0; i < 3; i++) {
                    let a = await sales_amounts(i);
                    if (i == 0) {
                        b.push(a.formatted);
                    }
                    else {
                        b.push(Number(a)/10**stable_coin_decimals);
                    }
                    
                }
                
                let ministerial = await get_unclaimed_ministerial_rewards();
                b.push(short_format_number(Number(ministerial)/10**18));

                set_balances(b);
            }
        })()
    },[])

    const withdraw = () => {
        withdraw_currency(selector.current.value)
    }

    const claim = () => {
        claim_ministerial_rewards();
    }

    const transfer_status = () => {
        enable_transfers(transfers_selector.current.value=='true')
    }

    //sales_amounts
    return (
        <div className="admin_container">
            <br/>
            Connect: <Web3Button/>
            {
                isConnected ?
                    address == admin_wallet ?
                        <div>
                            <br/><br/>
                            <hr/>
                            <br/>
                            <h2>Withdraw Sales</h2><br/>
                            Available balances:<br/><br/>
                            Ethereum: {balances[0]}<br/>
                            USDT: {balances[1]}<br/>
                            USDC: {balances[2]}<br/><br/>
                            <select className="padded_object" ref={selector}>
                                <option value="0">Ethereum</option>
                                <option value="1">USDT</option>
                                <option value="2">USDC</option>
                            </select><br/><br/>
                            <button className="padded_object" onClick={withdraw}>Withdraw Now</button>
                            <br/><br/>
                            <hr/>
                            <br/>
                            <h2>Withdraw ApeMax</h2><br/>
                            Available balance: {balances[3]} $APEMAX<br/><br/>
                            <button className="padded_object" onClick={claim}>Withdraw Now</button>
                            <br/><br/>
                            <hr/>
                            <br/>
                            <h2>Enable Transfers</h2><br/>
                            Transfers enabled: <br/><br/>
                            <select className="padded_object" ref={transfers_selector}>
                                <option value={true}>TRUE</option>
                                <option value={false}>FALSE</option>
                            </select><br/><br/>
                            <button className="padded_object" onClick={transfer_status}>Update Now</button>
                            <br/><br/>
                        </div>
                        :
                        <div>
                            <br/><br/>This area is retricted for admin access only<br/><br/>
                            <img src={"https://y.yarn.co/70017304-14d8-461f-a7d5-41ba1a026602_text.gif"}/>
                        </div>
                :
                <div><br/><br/>Pleae connect account to continue</div>
            }
        </div>
    )
}

export default Admin;