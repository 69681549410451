import React, { useRef, useEffect } from 'react';
import Chart from 'chart.js/auto';

function Pie_Chart({ data=[], labels=[] }) {
  const chartRef = useRef(null);
  const chartInstance = useRef(null);

  useEffect(() => {
    const myChartRef = chartRef.current.getContext('2d');

    // Destroy previous chart instance if it exists
    if (chartInstance.current !== null) {
      chartInstance.current.destroy();
    }

    chartInstance.current = new Chart(myChartRef, {
      type: 'pie',
      data: {
        labels: labels,
        datasets: [{
          label: 'User Stakes',
          data: data,
          backgroundColor: [
            '#ff6384',
            '#36a2eb',
            '#ffce56',
            '#cc65fe',
            '#ff9f40',
            '#4bc0c0',
            '#ffcd56'
          ],
        }]
      },
      options: {
        scales: {
          y: {
            beginAtZero: true
          }
        },
        maintainAspectRatio: false
      }
    });

    return () => {
      // Destroy chart instance on component unmount
      if (chartInstance.current !== null) {
        chartInstance.current.destroy();
      }
    };
  }, [data]);

  return (
    <div className="pie_chart">
        <canvas ref={chartRef}/>
    </div>
  );
}

export default Pie_Chart;
