import React from 'react';
// import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import reportWebVitals from './reportWebVitals';



// Pages
import Home from "./Pages/home.jsx";
import Ranking from "./Pages/ranking";
import Admin from "./Pages/admin";
import News_Page from "./Pages/news";
import Generic_Article_Page from "./Pages/generic_article";


// Styles
import "./Styles/common.css";
import "./Styles/colors.css";
import "./Styles/fonts.css";
import "./Styles/margins.css";
import "./Styles/containers.css";

import "./Styles/presale_stage.css";
import "./Styles/returns_calculator.css";
import "./Styles/live_sales.css";
import "./Styles/earn_with_apemax.css";
import "./Styles/how_it_works.css";
import "./Styles/loot_boxes.css";
import "./Styles/roadmap.css";
import "./Styles/talk_to_us.css";



import "./Styles/table.css";
import "./Styles/pop_ups.css";
import "./Styles/project_details.css";
import "./Styles/purchase_funnel.css";

// import "./Styles/hovers.css";

import "./Styles/news.css";

import "./Styles/admin.css";


// test
import { Web3Button, Web3NetworkSwitch } from "@web3modal/react";

// import "core-js";
// import regeneratorRuntime from "regenerator-runtime";

import {
  EthereumClient,
  w3mConnectors,
  w3mProvider,
} from "@web3modal/ethereum";
import { Web3Modal } from "@web3modal/react";
import { configureChains, createConfig, WagmiConfig } from "wagmi";
import { mainnet, polygon, bsc, sepolia, polygonMumbai, hardhat } from "wagmi/chains";

const projectId = '3e07e365ebfe5653a62f32020fb507a1';
const chains = [mainnet, polygon, bsc, sepolia, polygonMumbai, hardhat];

const { publicClient } = configureChains(chains, [w3mProvider({ projectId })]);
const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: w3mConnectors({ version: 1, chains, projectId }),
  publicClient,
});

const ethereumClient = new EthereumClient(wagmiConfig, chains);

const user_agent = navigator.userAgent || navigator.vendor;

if (/android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(user_agent.toLowerCase())) {
  import('./Styles/hovers_mobile.css');
} else {
  import('./Styles/hovers.css');
}


function App() {

  return (
    <>
      <WagmiConfig config={wagmiConfig}>
        <BrowserRouter>
          <Routes>
              <Route path="/" element={<Home />}/>
              <Route path="ranking" element={<Ranking />}/>
              <Route path="staking" element={<Ranking />}/>
              <Route path="admin" element={<Admin />}/>
              <Route path="news" element={<News_Page />}/>
              <Route path="article" element={<Generic_Article_Page />}/>
          </Routes>
        </BrowserRouter>
      </WagmiConfig>
      <Web3Modal projectId={projectId} ethereumClient={ethereumClient} />
    </>
  );
}

export default App;
