import { useState, useRef, useEffect, useCallback } from 'react';
import { uuid } from 'uuidv4';

import external_links from "../Config/external_links.json";

import { get_img } from "../Services/images.jsx";
let article_banners = [];
for (let i = 0; i < 4; i++) {
    let article_banner = get_img(`article_banner_${i}`);
    article_banners.push(article_banner);
}

let apemax_news_logo = get_img("apemax_news_logo");
let discord_icon = get_img("discord_icon");
let twitter_icon = get_img("twitter_icon");
let telegram_icon = get_img("telegram_icon");

let article_titles = [
    "Riding the Bull: Understanding the Phenomenon of Bull Markets",
    "What are Meme Coins? Exploring the Rise and Evolution of Internet-inspired Cryptocurrencies",
    "Exploring the Contrasts: Centralized vs. Decentralized Crypto Exchanges",
    "What is Ethereum Gas? Understanding what fuels Transactions on the Blockchain"
];

let article_content = [
    <><p>In the world of finance, the term "bull market" often finds its way into discussions, news headlines, and investment strategies. But what exactly does it mean? In this article, we unravel the concept of a bull market, exploring its history and providing a comprehensive understanding for the layperson.</p><br/>

    <p>A bull market refers to a sustained period of rising prices and optimism in the financial markets, particularly for stocks, cryptocurrencies, or commodities. It is characterized by investor confidence, increasing buying activity, and an overall positive sentiment towards the market. During a bull market, asset prices tend to rise steadily or experience substantial gains over an extended period.</p><br/>

    <p>The term "bull market" finds its roots in the world of stock trading and has been an integral part of financial jargon for centuries. Its origin can be traced back to the behavior of actual bulls, known for their tendency to charge forward with their horns raised, symbolizing an upward movement. This metaphorical representation captures the essence of a bull market—upward momentum and an optimistic outlook.</p><br/>

    <p>The history of bull markets can be observed throughout different economic periods. One of the most notable examples is the bull market that followed the Great Depression in the 1930s. The introduction of the New Deal by President Franklin D. Roosevelt helped stimulate economic growth, leading to a significant rally in stock prices and marking the end of a prolonged bear market.</p><br/>

    <p>Another iconic bull market unfolded during the late 1990s, known as the dot-com bubble. This period saw a surge in investment and speculation in internet-based companies, resulting in skyrocketing stock prices. However, the bubble eventually burst in 2000, leading to a prolonged bear market as investor enthusiasm waned.</p><br/>

    <p>In recent history, the cryptocurrency market has experienced its fair share of bull markets. Bitcoin, the pioneering cryptocurrency, has gone through multiple cycles of bull and bear markets. The most notable bull market occurred in 2017 when Bitcoin's price soared to unprecedented heights, attracting widespread attention and investor euphoria. However, the subsequent bear market in 2018 reminded investors of the inherent volatility and risks associated with the crypto market.</p><br/>

    <p>Bull markets are driven by various factors, including strong economic indicators, positive corporate earnings, government policies, and investor sentiment. During these periods, investors often exhibit a "buy and hold" strategy, anticipating further price appreciation. The overall optimism and wealth creation during bull markets can contribute to economic growth, as individuals and businesses feel more financially secure and are willing to invest and spend.</p><br/>

    <p>It is important to note that bull markets are not indefinite. Eventually, they can give way to bear markets, characterized by declining prices and pessimistic sentiment. Recognizing the signs of a maturing bull market and exercising caution is crucial to protect investments and navigate changing market conditions.</p><br/>

    <p>In conclusion, a bull market represents a period of optimism, rising prices, and overall positive sentiment in financial markets. Its historical roots and metaphorical origins highlight the upward momentum and confidence associated with this phenomenon. However, investors should approach bull markets with prudence, considering the potential risks and understanding that market dynamics can change over time.</p></>

    ,

    <><p>In recent years, a unique and intriguing phenomenon has emerged within the cryptocurrency space - the rise of meme coins. These digital assets, often inspired by internet memes and popular culture, have captured the attention of both crypto enthusiasts and casual investors alike. In this article, we’ll talk about the world of meme coins, tracing their history and shedding light on their appeal to a wide audience.</p><br/>

    <p>Meme coins, as the name suggests, are cryptocurrencies that draw inspiration from memes, jokes, or viral internet trends. While traditional cryptocurrencies like Bitcoin and Ethereum serve as digital currencies or platforms for decentralized applications, meme coins offer a more lighthearted and often experimental alternative. They aim to harness the power of internet culture to create communities and drive engagement within the crypto sphere.</p><br/>

    <p>The history of meme coins can be traced back to 2013 with the introduction of Dogecoin. Inspired by the "Doge" meme featuring a Shiba Inu dog, Dogecoin started as a joke or "fun" cryptocurrency. However, its popularity grew rapidly, and it soon gained a dedicated community and substantial market value.</p><br/>

    <p>Since the rise of Dogecoin, numerous meme coins have emerged, each with its own unique twist. Some mimic the playful nature of Dogecoin, while others draw inspiration from celebrities, animals, or internet trends. These coins often rely on viral marketing and social media to gain attention and attract a following.</p><br/>

    <p>Meme coins have gained traction due to several factors. Firstly, their accessibility and low entry barriers make them appealing to newcomers in the crypto space. Many meme coins are priced at fractions of a cent, allowing investors to acquire a large number of coins for a relatively small investment. This affordability, coupled with the hope of finding the next "moonshot" investment, attracts those seeking potential high returns.</p><br/>

    <p>Secondly, meme coins foster a sense of community and participation. Holding meme coins often grants individuals membership in a unique and often enthusiastic community. Online forums, social media groups, and Telegram channels become gathering places for like-minded individuals, creating a sense of belonging and shared excitement.</p><br/>

    <p>In conclusion, meme coins have carved a niche in the cryptocurrency landscape, offering a playful and community-driven alternative to traditional digital assets. While their history is relatively short, the rise of meme coins has demonstrated the power of internet culture and the evolving nature of cryptocurrency. As these coins continue to capture the attention of investors, it is crucial to approach them with caution and informed decision-making.</p></>

    ,

    <><p>Cryptocurrencies have revolutionized the way we think about finance, offering decentralized and borderless transactions. When it comes to buying, selling, and trading cryptocurrencies, individuals have two primary options: centralized exchanges and decentralized exchanges. In this article, we explore the differences between these two exchange types, shedding light on their functionalities and implications for crypto enthusiasts.</p><br/>

    <p>Centralized exchanges (CEX) operate similarly to traditional financial institutions. They act as intermediaries, facilitating the buying, selling, and trading of cryptocurrencies on behalf of users. These exchanges are run by a centralized entity, often a company, and require users to create accounts and go through a verification process to comply with regulatory requirements.</p><br/>

    <p>One of the key advantages of centralized exchanges is their user-friendly interface and familiar trading experience. They provide access to a wide range of cryptocurrencies and offer advanced trading features, such as limit orders and margin trading. Additionally, centralized exchanges typically have high liquidity, meaning there is a large pool of buyers and sellers, ensuring smooth and efficient transactions.</p><br/>

    <p>However, centralized exchanges also come with potential drawbacks. Since they are operated by a central authority, users must trust the exchange to handle their funds securely. This creates a single point of failure and exposes users to the risk of hacking, fraud, or mismanagement by the exchange itself. Furthermore, centralized exchanges often require users to undergo a Know Your Customer (KYC) process, which involves submitting personal identification documents, potentially compromising privacy.</p><br/>

    <p>On the other hand, decentralized exchanges (DEX) leverage blockchain technology to enable peer-to-peer trading without the need for intermediaries. DEX platforms operate on decentralized networks, allowing users to retain full control of their funds throughout the trading process. Instead of relying on a central authority, DEX transactions are executed through smart contracts, ensuring transparency and reducing the risk of manipulation.</p><br/>

    <p>Decentralized exchanges offer several advantages over their centralized counterparts. First and foremost, they prioritize user privacy and security. Since users retain control of their private keys and funds, there is no risk of a single point of failure or potential data breaches. Additionally, DEX platforms do not require users to undergo KYC processes, preserving their anonymity and privacy.</p><br/>

    <p>However, decentralized exchanges also have limitations. They typically have lower liquidity compared to centralized exchanges, which can result in higher slippage and potentially slower transaction times. The user interface of DEX platforms may be less intuitive for beginners, requiring some level of familiarity with blockchain technology.</p><br/>

    <p>In conclusion, centralized and decentralized exchanges offer distinct trading experiences within the cryptocurrency ecosystem. Centralized exchanges provide convenience, liquidity, and a familiar user interface, but at the cost of trusting a central authority and potentially compromising privacy. Decentralized exchanges prioritize user control, privacy, and security, but may have limitations in terms of liquidity and user experience. As the crypto market continues to evolve, individuals must consider their priorities and preferences when choosing an exchange that aligns with their needs.</p></>

    ,

    <><p>In the world of cryptocurrencies, Ethereum has emerged as a leading platform, offering not only digital currency but also a decentralized ecosystem for various applications. One crucial element that powers transactions on the Ethereum network is known as "gas." In this article, we'll explore what Ethereum gas is and its significance in facilitating smooth operations within the blockchain.</p><br/>

    <p>Gas, in the context of Ethereum, can be understood as a measure of computational effort required to execute operations or transactions on the Ethereum network. It acts as a fee mechanism and is denominated in a unit called "Gwei," which is a fraction of Ether (ETH), the native cryptocurrency of the Ethereum network.</p><br/>

    <p>When a user initiates a transaction or interacts with a smart contract on Ethereum, they need to pay a certain amount of gas to incentivize miners to process their request. Miners, who are responsible for validating and adding transactions to the blockchain, receive these gas fees as a reward for their computational efforts.</p><br/>

    <p>The amount of gas required for a transaction is determined by its complexity and the computational resources needed to execute it. Simple transactions, such as transferring Ether from one wallet to another, consume less gas compared to complex smart contract interactions that involve multiple operations and data manipulations.</p><br/>

    <p>Each operation within a transaction, such as reading data or writing to the blockchain, has a specific gas cost associated with it. Additionally, factors like network congestion and demand for computational resources can influence gas prices. Higher gas prices can lead to faster transaction confirmations, as miners prioritize transactions with higher fees.</p><br/>

    <p>Gas fees serve multiple purposes in the Ethereum ecosystem. Firstly, they act as a deterrent against spam and malicious activities by making it economically impractical to execute large-scale or frivolous transactions. Secondly, gas fees ensure that resources on the network are allocated fairly, preventing congestion and enabling smooth operation for all participants.</p><br/>

    <p>To estimate the gas cost of a transaction, users can utilize tools provided by Ethereum wallets or online calculators. These tools help users optimize their transactions by adjusting gas prices or gas limits, allowing them to balance the cost and speed of transaction confirmations based on their specific needs.</p><br/>

    <p>It's important to note that Ethereum gas fees can be volatile, especially during times of high network activity or congestion. Users should stay informed about current gas prices and consider the potential costs before initiating transactions or interacting on Ethereum.</p><br/>

    <p>In conclusion, Ethereum gas plays a vital role in powering transactions and operations on the Ethereum blockchain. By understanding the concept of gas and its significance, users can make informed decisions when utilizing the Ethereum network, ensuring efficient and secure interactions within the decentralized ecosystem.</p></>

]

function Generic_Article_Page() {

    const [article_index, set_article_index] = useState(0);

    useEffect(() => {
        const url = new URL(window.location.href);
        const params = url.searchParams;

        let temp_article_index = 0;

        if (params.get("index")) {
            temp_article_index = params.get("index");
            temp_article_index = temp_article_index < article_banners.length-1 ? temp_article_index:3;
        }

        set_article_index(temp_article_index);

        const script = document.createElement("script");

        script.src = "https://widgets.coingecko.com/coingecko-coin-price-marquee-widget.js";
        script.async = true;

        document.body.appendChild(script);

    },[]);

    return (
        <div>
            <div className="news_container">
                <img className="apemax_news_logo" src={apemax_news_logo}/>
                <div className="remove_lr_borders">
                <coingecko-coin-price-marquee-widget  coin-ids="bitcoin,ethereum,dogecoin,shiba-inu,pepe,floki" currency="usd" background-color="#ffffff" locale="en" font-color="#000000"></coingecko-coin-price-marquee-widget>
                </div>
                
                <div className="news_safe_inner">
                    <h3>Top Articles</h3>
                </div>
                <div className="other_articles_container">
                    <div className="other_articles_inner">
                    {function() {
                        let articles = [];

                        for (let i = 0; i < article_banners.length; i++) {
                            articles.push(
                                <div onClick={()=>{window.location = `article?index=${i}`}} className="other_article_div" style={i==3?{marginRight:'0px'}:{}}>
                                    <img src={article_banners[i]} className="other_article"/>
                                </div>
                            )
                        }

                        return articles;
                    }()}
                    </div>
                </div>
                <div className="extra_line_spacer"></div>
                <div className="news_safe_inner">
                    <h1>{article_titles[article_index]}</h1>
                </div>
                <img className="banner_img" src={article_banners[article_index]}/>
                <div className="news_header">
                    <div className="news_icon" style={{backgroundColor: 'var(--medium_red)'}}>News</div>
                    <div className="news_icon" style={{backgroundColor: 'var(--dark_blue)'}}>Guides</div>
                    <div className="social_icon" onClick={()=>{window.open(external_links.twitter)}}>
                        <img className="social_icon_inner" src={twitter_icon} />
                    </div>

                    <div className="social_icon" onClick={()=>{window.open(external_links.discord)}}>
                        <img className="social_icon_inner" src={discord_icon} />
                    </div>

                    <div className="social_icon" onClick={()=>{window.open(external_links.telegram)}}>
                        <img className="social_icon_inner" src={telegram_icon} />
                    </div>
                
                </div>
                <div className="news_safe_inner">
                    {article_content[0]}
                </div>
            </div>
        </div>
    )
}

export default Generic_Article_Page;