import { useState, useRef, useEffect, useCallback } from 'react';
import Revenue_Chart from "../Components/chart.jsx";
import Pie_Chart from "../Components/pie_chart.jsx";

import Jazzicon, { jsNumberForAddress } from 'react-jazzicon';
import { useAccount, useNetwork } from 'wagmi';
import { user_data, user_activity, user_stakes, claims_for_user, get_extra_unminted } from "../Services/api.jsx";
import { get_apemax_balance, get_staking_rewards_batch, claim_staking_rewards, unstake_tokens, get_polygon_balance, } from "../Services/web3_functions.jsx";
import { short_format_number, format_timestamp, shorten_crypto_string, process_rewards, validate_touch } from "../Services/helper_functions.jsx";
import { block_explorer } from "../Config/config.jsx";
import projects from "../Config/projects.json"
import { waitForTransaction, disconnect } from '@wagmi/core'

import { get_img } from "../Services/images.jsx";


let cross_logo = get_img("cross_logo");
let lootbox_0 = get_img("lootbox_0");
let stake_icon = get_img("stake_icon");
let mint_icon = get_img("mint_icon");
let royalties_icon = get_img("royalties_icon");
let contract_icon = get_img("contract_icon");
let unstake_icon = get_img("unstake_icon");
let claim_icon = get_img("claim_icon");
let refresh_icon = get_img("refresh_icon");
let year_icon = get_img("year_icon");
let month_icon = get_img("month_icon");
let day_icon = get_img("day_icon");
let disconnect_icon = get_img("disconnect_icon");
let low_gas_icon = get_img("low_gas_icon");


let contract_imgs = [];
for (let i = 0; i < 100; i++) {
    contract_imgs.push(get_img(i));
}




let time_icons = [year_icon, month_icon, day_icon];


let sample_img = "https://i.seadn.io/gae/Ju9CkWtV-1Okvf45wo8UctR-M9He2PjILP0oOvxE89AyiPPGtrR3gysu1Zgy0hjd2xKIgjJJtWIc0ybj4Vd7wv8t3pxDGHoJBzDB?auto=format&w=1920"




function Discalimer() {
    return (
        <div className="discalimer_text">
            <br/>
            LEGEND<br/><br/>
            [1]: Total $APEMAX is the amount of $APEMAX token you currently have in your wallet. This does not include the amount of $APEMAX you have staked.<br/><br/>
            [2]: Total Staked is the amount of $APEMAX token you have currently staked. This amount can be withdrawn at any time by using the unstake function on your staked tokens.<br/><br/>
            [3]: Total Reward Earned is the amount of $APEMAX you have earned from staking. You can claim $APEMAX rewards using the claim function. Please note when you unstake your remaining rewards will also be automatically claimed.<br/><br/>
            [4]: Realized Yield is the actual percentage return you have realized by looking at all your historic stakes and claims.<br/><br/>
            NEED HELP?<br/><br/>
            If you are having difficulty purchasing, need assistance, or have questions about ApeMax please feel free to reach out to our <u className="clickable_text">Customer Support Team</u>.
        </div>
    )
}


function Profile_Details({set_hash, set_global_currency_index}) {

    // FIX CHAIN

    let { chain } = useNetwork();

    async function fix_network() {

        if (isConnected) {
            set_global_currency_index(56);
        }
    }

    function check_network() {
        if (isConnected) {
            if (chain.id != 56) {
                return false;
            }
        }

        return true;
    }
    

    // TX TRACKING
    function track_transaction(hash) {
        set_hash(hash);
        close_pop_up();
        document.querySelector(".transaction_tracker").style.display = "block";
    }

    // SAMPLE CHART DATA
    const data = [10, 20, 30, 40, 50, 60, 70];

    function close_pop_up() {
        document.querySelector('.profile_pop_up').style.display = 'none';
        document.querySelector('body').style.overflow = 'auto';
    }

    const { isConnected, address } = useAccount();

    const [basic_info, set_basic_info] = useState({
        apemax_balance: "",
        total_rewards: "",
        total_staked: "",
        realized_yield: ""
    });
    const [recent_activity, set_recent_activity] = useState([]);

    const [pie_chart_data, set_pie_chart_data] = useState([]);
    const [pie_chart_labels, set_pie_chart_labels] = useState([]);

    const [user_active_stakes, set_user_active_stakes] = useState([]);
    const [user_inactive_stakes, set_user_inactive_stakes] = useState([]);

    const [vunminted_balance, set_vunminted_balance] = useState(0);

    let user_events = {
        '_claim_apemax':['amount_minted',' $APEMAX Minted', mint_icon],
        '_mint_apemax':['amount_minted',' $APEMAX Minted', mint_icon],
        '_stake_tokens':['amount_staked',' $APEMAX Staked', stake_icon],
        '_unstake_tokens':['amount_staked',' $APEMAX Unstaked', unstake_icon],
        '_claim_staking_rewards':['amount_claimed',' $APEMAX Claimed', claim_icon],
        '_create_staking_contract':['','Staking Contract Created', contract_icon],
        '_claim_creator_rewards':['amount_claimed',' $APEMAX Claimed', lootbox_0],
        '_update_royalties':['royalties','% Royalties Update', royalties_icon]
    }

    const has_mounted = useRef(false);

    useEffect(()=> {

        if (!isConnected) {
            has_mounted.current = false;
        }

        (async function() {
            if (isConnected && has_mounted.current == false) {
                try {

                    has_mounted.current = true;

                    // Basic info
                    update_user_info()

                    // Stakes
                    update_sakes();

                    // Rewards chart
                    update_chart(0);

                    // Recent activity
                    update_activity();

                } catch (error) {



                }
            }
        })()

    },[isConnected]);



    // ----- Update profile inf -----
    let [user_info_updated, set_user_info_updated] = useState("");
    async function update_user_info() {

        let [apemax_balance, unminted_balance, total_rewards, total_staked, realized_yield] = [0,0,0,0,0];

        try {
            ({ realized_yield, total_rewards, total_staked } = await user_data(address));
            realized_yield = realized_yield || 0;
        } catch (error) {}

        try {
            apemax_balance = await get_apemax_balance(address);
            apemax_balance = Number(apemax_balance) / 10**18;
        } catch (error) {}
/*
        try {
            let polygon_qty = Number(await get_polygon_balance(address)) / 10**18;
            apemax_balance += polygon_qty;
            unminted_balance += polygon_qty;
        } catch (error) {}
*/
/*
        try {
            let extra_uqty = Number((await get_extra_unminted(address)).extra_qty) / 10**18;
            apemax_balance += extra_uqty;
            unminted_balance += extra_uqty;
        } catch (error) {}
*/
        let info = {
            apemax_balance: short_format_number(apemax_balance),
            total_rewards: short_format_number(total_rewards / 10**18),
            total_staked: short_format_number(total_staked / 10**18),
            realized_yield: realized_yield.toFixed(2) + "%"
        }

        set_basic_info(info);

        set_user_info_updated(format_timestamp(Date.now()/1000))

        set_vunminted_balance(unminted_balance);

    }

    // ----- Update profile inf -----
    let [stakes_updated, set_stakes_updated] = useState("");
    async function update_sakes() {

        try {
            let stakes = await user_stakes(address, 10, 0);
            
            let active_stakes = [];
            let inactive_stakes = [];
            for (let stake_address in stakes) {
                let { amount_staked, contract_index, timestamp, unstaked } = stakes[stake_address];
                if (unstaked == false) {
                    active_stakes.push({
                        amount_staked: amount_staked / 10**18,
                        contract_index,
                        timestamp,
                        stake_address
                    });

                }
                else {
                    inactive_stakes.push({
                        amount_staked: amount_staked / 10**18,
                        contract_index,
                        timestamp,
                        stake_address
                    })
                }
            }

            // Sort for charts
            let sorted_stakes = active_stakes
            sorted_stakes = sorted_stakes.sort((a, b) => b.amount_staked - a.amount_staked);

            let amount_staked_arr = [];
            let contract_index_arr = [];

            for (let i = 0; i < sorted_stakes.length; i++) {
                const project_name = projects[sorted_stakes[i].contract_index].name;

                if (i < 9) {
                    const existingIndex = contract_index_arr.indexOf(project_name);

                    if (existingIndex === -1) {
                        amount_staked_arr.push(sorted_stakes[i].amount_staked);
                        contract_index_arr.push(project_name);
                    } else {
                        amount_staked_arr[existingIndex] += sorted_stakes[i].amount_staked;
                    }
                } else {
                    if (!contract_index_arr.includes("Other")) {
                        contract_index_arr.push("Other");
                        amount_staked_arr.push(0);
                    }

                    const otherIndex = contract_index_arr.indexOf("Other");
                    amount_staked_arr[otherIndex] += sorted_stakes[i].amount_staked;
                }
            }

            set_pie_chart_data(amount_staked_arr);
            set_pie_chart_labels(contract_index_arr);

            

            let stake_addresses = [];
            let contract_indexes = [];

            for (let i = 0; i < active_stakes.length; i++) {
                stake_addresses.push(active_stakes[i].stake_address);
                contract_indexes.push(active_stakes[i].contract_index);
            }

            let rewards = await get_staking_rewards_batch(stake_addresses, contract_indexes);

            for (let i = 0; i < active_stakes.length; i++) {
                active_stakes[i]['unclaimed_rewards'] = short_format_number(Number(rewards[i]) / 10**18);
            }

            

            // Sort lists
            active_stakes = active_stakes.sort((a, b) => b.timestamp - a.timestamp);
            inactive_stakes = inactive_stakes.sort((a, b) => b.timestamp - a.timestamp);

            set_user_active_stakes(active_stakes);
            set_user_inactive_stakes(inactive_stakes);

        } catch (error) {
            set_pie_chart_data([]);
            set_pie_chart_labels([]);
            set_user_active_stakes([]);
            set_user_inactive_stakes([]);
        }

        set_stakes_updated(format_timestamp(Date.now()/1000))
    }

    // ----- Update activity -----
    let [activity_updated, set_activity_updated] = useState("");
    async function update_activity() {

        let formatted_activity = [];

        try {
            let activity = await user_activity(address, 20, 0);
        

            activity.forEach(act => {
                let map = user_events[act.event];
                let desc = `${
                    map[0].length > 0 ?
                        map[0] == 'royalties' ?
                            act[map[0]].toFixed(2) :
                            short_format_number(act[map[0]] / 10**18)
                        :
                        ''
                }${map[1]}`;

                formatted_activity.push({
                    desc,
                    hash: act.hash,
                    timestamp: format_timestamp(act.timestamp),
                    icon: map[2]
                })

            })


        } catch (error) {}

        
        set_recent_activity(formatted_activity);
        
        set_activity_updated(format_timestamp(Date.now()/1000))
    }


    // ----- Updating chart ----
    
    let date_titles = ["Year","Month","Day"];
    let date_options = ["monthly","daily","hourly"];
    let [selected_option_index, set_selected_option_index] = useState(0);
    let [chart_last_updated, set_chart_last_updated] = useState("");
    const [rewards_chart_data, set_rewards_chart_data] = useState([]);
    const [rewards_chart_labels, set_rewards_chart_labels] = useState([]);

    async function update_chart(option_index) {
        try {

            set_selected_option_index(option_index)

            let option = date_options[option_index];

            let time_now = Date.now()/1000;
            let last_time = [
                time_now - (60*60*24*365),
                time_now - (60*60*24*30),
                time_now - (60*60*24)
            ]
            last_time = last_time[option_index];

            let rewards_history = await claims_for_user(
                address,
                last_time,
                time_now,
                option
            );

            console.log(rewards_history)

            let output = process_rewards(rewards_history,option,"amount_claimed");
            set_rewards_chart_data(output[0])
            set_rewards_chart_labels(output[1])
            
            
            

            //rewards_for_contract(contract_index, start_timestamp, end_timestamp, option)
        } catch (error) {
            set_rewards_chart_data([])
            set_rewards_chart_labels([])
        }

        set_chart_last_updated(format_timestamp(Date.now()/1000));
    }

    const [data_selector_visible, set_data_selector_visible] = useState(false);

    useEffect(() => {

        let timing_drop_down_list = document.querySelector(".time_drop");
        if (timing_drop_down_list) {
            timing_drop_down_list.style.display = data_selector_visible ? 'block':'none';
        }
        
    },[data_selector_visible])



    const claim_fn = async (stake_address, contract_index) => {

        if (!check_network()) {
            fix_network();
            return;
        }

        let result = await claim_staking_rewards(stake_address, contract_index)
        if (result.status == 200) {
            // alert("Claim transaction sent! It will take some time for it to be confirmed by the blockchain. After it is confirmed it will take around 60 seconds for it to appear on your profile.")
            // let data = await waitForTransaction({hash:result.hash});
            track_transaction(result.hash);
        }
        else {
            alert("An error has occured. Please make sure you have enough BNB to cover the transaction gas before trying again. If the problem persists please contact our customer support team.")
        }
    }

    const unstake_fn = async (stake_address, contract_index) => {

        if (!check_network()) {
            fix_network();
            return;
        }

        let result = await unstake_tokens(stake_address, contract_index)
        if (result.status == 200) {
            // alert("Unstake transaction sent! It will take some time for it to be confirmed by the blockchain. After it is confirmed it will take around 60 seconds for it to appear on your profile.")
            // let data = await waitForTransaction({hash:result.hash});
            track_transaction(result.hash);
        }
        else {
            alert("An error has occured. Please make sure you have enough BNB to cover the transaction gas before trying again. If the problem persists please contact our customer support team.")
        }
    }

    return (
        <div className="overlay_container profile_pop_up">
            <div className="pop_up_close_outter"
                onClick={close_pop_up}
                onTouchEnd={(e)=>{
                    if (validate_touch(e)) {
                        e.preventDefault();
                        close_pop_up();
                    }
                }}
            ></div>
            <div className="pop_up_container">
                <div className="pop_up_title"><b>User Profile</b></div>
                <img className="pop_up_close" src={cross_logo}
                    onClick={close_pop_up}
                    onTouchEnd={(e)=>{
                        if (validate_touch(e)) {
                            e.preventDefault();
                            close_pop_up();
                        }
                    }}
                />
                
                <div className="pop_up_inner">

                    {vunminted_balance>0 ?
                    <div className="purchase_error_container unminted_apemax">
                        <img className="purchase_stage_icon" src={low_gas_icon}/>
                        <div className="purchase_stage_title"><b>Unminted ApeMax</b></div>
                        <div className="purchase_text">As some of your ApeMax was minted in Gas Saver Mode, it has not yet been minted on the blockchain. However, you will soon be able to mint it on this platform at a time when the gas fees are lower.</div>
                    </div>
                    :
                    <></>
                    }

                    <div className="info_container">

                        <div className="info_image_icon">
                            {
                                isConnected ?
                                <Jazzicon diameter={50} seed={jsNumberForAddress(address)} /> :
                                <></>
                            }
                            
                        </div>


                        <div className="info_name"><b>{address?shorten_crypto_string(address):""}</b>
                            <img className="refresh_button" src={refresh_icon} onClick={async (event) => {
                                const refreshButton = event.target;
                                refreshButton.classList.add('spin_refresh');
                                await update_user_info();
                                refreshButton.classList.remove('spin_refresh');
                            }}
                            onTouchEnd={async (e)=>{
                                if (validate_touch(e)) {
                                    e.preventDefault();

                                    const refreshButton = e.target;
                                    refreshButton.classList.add('spin_refresh');
                                    await update_user_info();
                                    refreshButton.classList.remove('spin_refresh');
                                }
                            }}/>
                            <img className="disconnect_button" src={disconnect_icon} onClick={async () => {
                                await disconnect();
                                close_pop_up();
                            }}
                            onTouchEnd={async (e)=>{
                                if (validate_touch(e)) {
                                    e.preventDefault();
                                    await disconnect();
                                    close_pop_up();
                                }
                            }}/>
                            <div className="last_updated">Last updated {user_info_updated}</div>
                        </div>
                        <div className="key_figures">
                            <div className="key_figure_indicator">
                                <div className="key_figure_amount"><b>{basic_info.apemax_balance}</b></div>
                                <div className="key_figures_amount_title">Total $ApeMax<sup> [1]</sup></div>
                            </div>
                            <div className="key_figure_indicator key_figure_indicator_no_margin">
                                <div className="key_figure_amount"><b>{basic_info.total_staked}</b></div>
                                <div className="key_figures_amount_title">Total Staked<sup> [2]</sup></div>
                            </div>
                            <div className="key_figure_indicator">
                                <div className="key_figure_amount"><b>{basic_info.total_rewards}</b></div>
                                <div className="key_figures_amount_title">Total Rewards Earned<sup> [3]</sup></div>
                            </div>
                            <div className="key_figure_indicator key_figure_indicator_no_margin">
                                <div className="key_figure_amount"><b>{basic_info.realized_yield}</b></div>
                                <div className="key_figures_amount_title">Realized Yield<sup> [4]</sup></div>
                            </div>
                        </div>
                    </div>

                    <div className="pie_chart_container">
                        <div className="project_stake_title"><b>My Stakes</b>
                            <img className="refresh_button" src={refresh_icon} onClick={async (event) => {
                                const refreshButton = event.target;
                                refreshButton.classList.add('spin_refresh');
                                await update_sakes();
                                refreshButton.classList.remove('spin_refresh');
                            }}
                            onTouchEnd={async (e)=>{
                                if (validate_touch(e)) {
                                    e.preventDefault();
                                    const refreshButton = e.target;
                                    refreshButton.classList.add('spin_refresh');
                                    await update_sakes();
                                    refreshButton.classList.remove('spin_refresh');
                                }
                            }}/>
                            <div className="last_updated">Last updated {stakes_updated}</div>
                        </div>
                        <Pie_Chart data={pie_chart_data} labels={pie_chart_labels}/>
                    </div>

                    <div className="project_activity_container">
                        <div className="project_stake_title"><b>Current Stakes</b>
                            <img className="refresh_button" src={refresh_icon} onClick={async (event) => {
                                const refreshButton = event.target;
                                refreshButton.classList.add('spin_refresh');
                                await update_sakes();
                                refreshButton.classList.remove('spin_refresh');
                            }}
                            onTouchEnd={async (e)=>{
                                if (validate_touch(e)) {
                                    e.preventDefault();

                                    const refreshButton = e.target;
                                    refreshButton.classList.add('spin_refresh');
                                    await update_sakes();
                                    refreshButton.classList.remove('spin_refresh');
                                }
                            }}/>
                            <div className="last_updated">Last updated {stakes_updated}</div>
                        </div>
                        <div className="project_activity_inner_container">
                            {(function() {
                                let rows = [];
                                for (let i = 0; i < user_active_stakes.length; i++) {
                                    let curI = i;
                                    rows.push(<div className="claimable_row">
                                        <img className="activity_icon_rounded" src={contract_imgs[user_active_stakes[i].contract_index]}/>
                                        <div className="activity_title"><b>{short_format_number(user_active_stakes[i].amount_staked)} $ApeMax Staked</b></div>
                                        <div className="activity_sub_title">Unclaimed: {user_active_stakes[i].unclaimed_rewards} $ApeMax</div>
                                        <div className="activity_unstake_button"
                                            onClick={()=>{unstake_fn(user_active_stakes[i].stake_address, user_active_stakes[i].contract_index)}}
                                            onTouchEnd={(e)=>{
                                                if (validate_touch(e)) {
                                                    e.preventDefault();
                                                    unstake_fn(user_active_stakes[i].stake_address, user_active_stakes[i].contract_index);
                                                }
                                            }}
                                        >Unstake</div>
                                        <div className="activity_claim_button"
                                            onClick={()=>{claim_fn(user_active_stakes[i].stake_address, user_active_stakes[i].contract_index)}}
                                            onTouchEnd={(e)=>{
                                                if (validate_touch(e)) {
                                                    e.preventDefault();
                                                    claim_fn(user_active_stakes[i].stake_address, user_active_stakes[i].contract_index)
                                                }
                                            }}
                                        >Claim</div>
                                    </div>);
                                }
                                return rows;
                            })()}
                            {(function() {
                                let rows = [];
                                for (let i = 0; i < user_inactive_stakes.length; i++) {
                                    rows.push(<div className="activity_row">
                                        <img className="activity_icon_rounded" src={contract_imgs[user_inactive_stakes[i].contract_index]}/>
                                        <div className="activity_title"><b>{short_format_number(user_inactive_stakes[i].amount_staked)} $ApeMax Staked</b></div>
                                        <div className="activity_sub_title">Unstaked</div>
                                    </div>);
                                }
                                return rows;
                            })()}
                        </div>
                    </div>

                    
                    <div className="project_chart_container">
                        <div className="project_stake_title"><b>Reward Historic</b>
                            <img className="refresh_button" src={refresh_icon} onClick={async (event) => {
                                const refreshButton = event.target;
                                refreshButton.classList.add('spin_refresh');
                                await update_chart(selected_option_index);
                                refreshButton.classList.remove('spin_refresh');
                            }}
                            onTouchEnd={async (e)=>{
                                if (validate_touch(e)) {
                                    e.preventDefault();

                                    const refreshButton = e.target;
                                    refreshButton.classList.add('spin_refresh');
                                    await update_chart(selected_option_index);
                                    refreshButton.classList.remove('spin_refresh');
                                }
                            }}/>
                            <div className="last_updated">Last updated {chart_last_updated}</div>
                        </div>
                        <div className="timing_drop_down"
                            onClick={()=>{set_data_selector_visible(!data_selector_visible)}}
                            onTouchEnd={(e)=>{
                                if (validate_touch(e)) {
                                    e.preventDefault();
                                    set_data_selector_visible(!data_selector_visible);
                                }
                            }}
                        >
                            <img className="purchase_currency_drop_down_icon" src={time_icons[selected_option_index]}/>
                            <div className="purchase_currency_drop_down_label"><b>{date_titles[selected_option_index]}</b></div>
                            <div className="purchase_currency_drop_down_extra">▼</div>
                        </div>
                        <Revenue_Chart data={rewards_chart_data} labels={rewards_chart_labels}/>
                        <div className="timing_drop_down_list time_drop">
                            {(function() {
                                let btns = [];
                                for (let i = 0; i < 3; i++) {
                                    let curI = i;
                                    btns.push(
                                        <div className="purchase_currency_drop_down_item" onClick={() => {
                                                update_chart(curI)
                                                set_data_selector_visible(false);
                                            }}
                                            onTouchEnd={(e)=>{
                                                if (validate_touch(e)) {
                                                    e.preventDefault();
                                                    update_chart(curI)
                                                    set_data_selector_visible(false);
                                                }
                                            }}>
                                            <img className="purchase_currency_drop_down_icon" src={time_icons[i]}/>
                                            <div className="purchase_currency_drop_down_label">{date_titles[i]}</div>
                                        </div>
                                    )
                                }
                                return btns;
                            })()}
                        </div>
                    </div>

                    <div className="project_activity_container">
                        <div className="project_stake_title"><b>Recent Activity</b>
                            <img className="refresh_button" src={refresh_icon} onClick={async (event) => {
                                const refreshButton = event.target;
                                refreshButton.classList.add('spin_refresh');
                                await update_activity();
                                refreshButton.classList.remove('spin_refresh');
                            }}
                            onTouchEnd={async (e)=>{
                                if (validate_touch(e)) {
                                    e.preventDefault();

                                    const refreshButton = e.target;
                                    refreshButton.classList.add('spin_refresh');
                                    await update_activity();
                                    refreshButton.classList.remove('spin_refresh');
                                }
                            }}/>
                            <div className="last_updated">Last updated {activity_updated}</div>
                        </div>
                        <div className="project_activity_inner_container">
                            {(function() {
                                let rows = [];
                                for (let i = 0; i < recent_activity.length; i++) {
                                    let curI = i;
                                    rows.push(<div className="activity_row">
                                        <img className="activity_icon" src={recent_activity[i].icon}/>
                                        <div className="activity_title"><b>{recent_activity[i].desc}</b></div>
                                        <div className="activity_sub_title clickable_text"
                                            onClick={()=>{window.open(`https://bscscan.com/tx/${recent_activity[curI].hash}`)}}
                                            onTouchEnd={(e)=>{
                                                if (validate_touch(e)) {
                                                    e.preventDefault();
                                                    window.open(`https://bscscan.com/tx/${recent_activity[curI].hash}`)
                                                }
                                            }}
                                        ><u>{shorten_crypto_string(recent_activity[i].hash)}</u></div>
                                        <div className="activity_time_label">{recent_activity[i].timestamp}</div>
                                    </div>);
                                }
                                return rows;
                            })()}
                        </div>
                    </div>

                    <Discalimer/>

                </div>
            </div>
        </div>
    );
}

export default Profile_Details;