import axios from 'axios';
import { api_endpoint } from "../Config/config.jsx";

const custom_axios = axios.create({
    baseURL: api_endpoint,
});

custom_axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
        if (error.response && error.response.status === 429) {
            alert('You are making requests too quickly. Please slow down.');
        }
        return Promise.reject(error);
    }
);

export async function get_info() {
    let info = await custom_axios.get(`/pricing/info`);
    return info.data;
}

export async function get_price_auth(lootbox, price, currency_index) {
    let response = await custom_axios.post(`/pricing/get_price_auth`, { lootbox, price, currency_index })
    return response.data;
}

export async function get_sales(max_per_page, page_number) {
    let response = await custom_axios.post(`/data_farm/sales`, {max_per_page, page_number})
    return response.data;
}

export async function rewards_for_contract(contract_index, start_timestamp, end_timestamp, option) {
    let response = await custom_axios.post(`/data_farm/rewards_for_contract`, {contract_index, start_timestamp, end_timestamp, option})
    return response.data;
}

export async function claims_for_user(user_address, start_timestamp, end_timestamp, option) {
    let response = await custom_axios.post(`/data_farm/claims_for_user`, {user_address, start_timestamp, end_timestamp, option})
    return response.data;
}

export async function contract_activity(contract_index, max_per_page, page_number) {
    let response = await custom_axios.post(`/data_farm/contract_activity`, {contract_index, max_per_page, page_number})
    return response.data;
}

export async function user_activity(user_address, max_per_page, page_number) {
    let response = await custom_axios.post(`/data_farm/user_activity`, {user_address, max_per_page, page_number})
    return response.data;
}

export async function user_stakes(user_address, max_per_page, page_number) {
    let response = await custom_axios.post(`/data_farm/user_stakes`, {user_address, max_per_page, page_number})
    return response.data;
}

export async function project_ranking() {
    let response = await custom_axios.get(`/data_farm/project_ranking`)
    return response.data;
}

export async function user_data(user_address) {
    let response = await custom_axios.post(`/data_farm/user_data`, {user_address})
    return response.data;
}

export async function total_sales() {
    let response = await custom_axios.get(`/data_farm/total_sales`)
    return response.data;
}

export async function register_email(email_address) {
    let response = await custom_axios.post(`/data_collection/register_email`, {email_address})
    return response.data;
}

export async function register_lead(first_name, last_name, contact_number, email_address, investment_budget) {
    let response = await custom_axios.post(`/data_collection/register_lead`, {first_name, last_name, contact_number, email_address, investment_budget})
    return response.data;
}

export async function attribute_purchase(
    utm_params,
    referrer,
    ref_timestamp,

    purchase_id,
    usd_value,
    currency_index,
    is_lootbox,
    purchase_timestamp,

    client_user_agent,
    fbc,
    fbp,
    twclid,

    agreed_tos,
    hash    

    ) {

    let response = await custom_axios.post(`/data_collection/attribute_purchase`, {
        utm_params,
        referrer,
        ref_timestamp,

        purchase_id,
        usd_value,
        currency_index,
        is_lootbox,
        purchase_timestamp,

        client_user_agent,
        fbc,
        fbp,
        twclid,
        
        agreed_tos,
        hash
    })
    return response.data;
}

export async function register_purchase_intent(purchase_id, client_user_agent, fbc, fbp, twclid, usd_price, utm_params, referrer, ref_timestamp, is_lootbox) {
    let response = await custom_axios.post(`/crossmint_tracking/register_purchase_intent`, {purchase_id, client_user_agent, fbc, fbp, twclid, usd_price, utm_params, referrer, ref_timestamp, is_lootbox})
    return response.data;
}

export async function retrieve_purchase_status(purchase_id) {
    let response = await custom_axios.post(`/crossmint_tracking/retrieve_purchase_status`, {purchase_id})
    return response.data;
}


export async function register_purchase_tx(hash, recipient, amount_payable, quantity, timestamp, currency_index, v, r, s) {
    let response = await custom_axios.post(`/data_collection/register_purchase_tx`, {hash, recipient, amount_payable, quantity, timestamp, currency_index, v, r, s})
    return response.data;
}

export async function get_extra_unminted(recipient) {
    let response = await custom_axios.post(`/data_collection/get_extra_unminted`, {recipient})
    return response.data;
}

export async function get_usd_sales() {
    let response = await custom_axios.get(`/pricing/usd_sales`)
    return response.data;
}

