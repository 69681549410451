import Title_Text from "./title_text.jsx";
import external_links from "../Config/external_links.json"
import { get_img } from "../Services/images.jsx";
import { validate_touch } from "../Services/helper_functions.jsx"; 

let rocket_juice_overhang = get_img("rocket_juice_overhang");
let tokenomics_and_supply = get_img("tokenomics_and_supply");
let moons_background = get_img("moons_background");


function Tokenomics_And_Supply() {
    return (
        <div className="component" style={{backgroundColor: 'var(--light_purple)'}}>
            <div className="inner_component inner_component_with_overhang">
                
                <div className="block_with_inner"  style={{backgroundImage: `url(${moons_background})`}}>
                    <div className="inner_block">
                        <Title_Text text={<>TOKENOMICS AND SUPPLY</>} text_color={"var(--title_purple)"} outline_color={"black"}/>
                        <div className="inner_title">ApeMax is more than just an adorable mascot; it's a fun new cryptocurrency, with a playful facade and cool tokenomics. With a limited token supply, ApeMax is an awesome new meme coin.</div>
                        <div className="inner_title">Discover the full details in our Whitepaper.</div>

                        <img src={tokenomics_and_supply} className="big_img"/>
                        <div className="full_width_button"
                        onClick={() => {
                            window.open(external_links.whitepaper);
                        }}
                        onTouchEnd={(e)=>{
                            if (validate_touch(e)) {
                                e.preventDefault();
                                window.open(external_links.whitepaper);
                            }
                        }}><b>Read Whitepaper</b></div>

                    </div>
                    
                </div>
                <img src={rocket_juice_overhang} className="overhang"/>
            </div>
        </div>
    )
}

export default Tokenomics_And_Supply;