import { useState, useRef, useEffect, useCallback } from 'react';

import Project_Details from "./project.jsx";
import Profile_Details from "./profile.jsx";
import Purchase_Funnel from "./purchase_funnel.jsx";

import RankingTable from "../Components/table.jsx";


import Blocked_Countries from "./blocked_countries.jsx";
import { get_info, project_ranking } from '../Services/api';
import Connect_Wallet from "./connect_wallet.jsx";
import { useAccount } from 'wagmi';
import { get_global } from '../Services/web3_functions.jsx';
import Wrong_Network from "./wrong_network.jsx";
import Title_Text from "../Components/title_text.jsx";

import { track_event, validate_touch } from "../Services/helper_functions.jsx";


import { uuid } from 'uuidv4';

import Discalimer from "../Components/disclaimer.jsx"

import Transaction_Tracker from "./transaction_tracker.jsx";

import external_links from "../Config/external_links.json";

import { get_img } from "../Services/images.jsx";

import { check_if_location_blocked } from "../Services/location.jsx";

import Server_Outage from "./server_outage.jsx"

let apemax_coin = get_img("apemax_coin");
let apemax_rocket = get_img("apemax_rocket");


function Ranking() {


    const [shared_info, set_shared_info] = useState();
    const [selected_lootbox_index, set_selected_lootbox_index] = useState(2);
    const [is_lootbox_selected, set_is_lootbox_selected] = useState(false);
    const [is_credit_card_selected, set_is_credit_card_selected] = useState(false);
    const [s,ss] = useState(false);
    const [should_block, set_should_block] = useState(false);

    const [global_currency_index, set_global_currency_index] = useState(0);

    const [ranking, set_ranking] = useState([]);
    const [selected_project_index, set_selected_project_index] = useState();
    const [global_data, set_global_data] = useState();

    const [purchase_id, set_purchase_id] = useState();

    const [tracking_hash, set_tracking_hash] = useState("0x0000000");

    function launch_purchase_funnel(lootbox_index=0,is_credit_card=false,is_lootbox=false) {

        if (should_block) {
            alert("ApeMax is currently unavailable for purchase in your country or region");;
            return;
        }
        
        set_is_lootbox_selected(is_lootbox);
        set_selected_lootbox_index(lootbox_index);
        set_is_credit_card_selected(is_credit_card);
        ss(true);

        let new_id = uuid();
        set_purchase_id(new_id);
        track_event(new_id, 0, 0);

        document.querySelector('.purchase_funnel').style.display = 'block';
        document.querySelector('body').style.overflow = 'hidden'
    }

    const has_mounted = useRef(false);

    useEffect(() => {

        (async function(){
            if (has_mounted.current == false) {

                has_mounted.current = true;
            
                Promise.all([
                    get_info(),
                    get_global(),
                    project_ranking()
                ]).then(([info, global, ranked_projects]) => {
                    let { base_price, eth_price, polygon_exchange_rate, bnb_exchange_rate, lootboxes } = info;
                
                    set_shared_info({ base_price, eth_price, polygon_exchange_rate, bnb_exchange_rate, lootboxes });
                    set_global_data(global);
                    set_ranking(ranked_projects);

                }).catch(error => {
                    console.error('Error in fetching data:', error);
                });

                check_if_location_blocked().then((is_blocked) => {
                    if (is_blocked) {
                        set_should_block(true);
                    }
                }).catch(errpr => {});

                
            }

        })();

    },[]);

    // -- CONNECTION BASIC
    const { isConnected } = useAccount();
    function connect_wallet() {
        if (!isConnected) {
            document.querySelector('body').style.overflow = 'hidden';
            document.querySelector('.connect_wallet').style.display = 'block';
        }
        else {
            document.querySelector('body').style.overflow = 'hidden';
            document.querySelector('.profile_pop_up').style.display = 'block';
        }
    }

    /*
    // LANG
    let languages = ["EN","FR","ES","CN","EN","FR","ES","CN","EN","FR","ES","CN"];
    let emojis = ["🇬🇧","🇫🇷","🇪🇸","🇨🇳","🇬🇧","🇫🇷","🇪🇸","🇨🇳","🇬🇧","🇫🇷","🇪🇸","🇨🇳"]
    
    const [language_vis_0, set_language_vis_0] = useState(false);
    const [language_vis_1, set_language_vis_1] = useState(false);

    useEffect(() => {
        document.querySelector('.language_drop_down').style.display = language_vis_0 ? 'block':'none';
    },[language_vis_0]);

    useEffect(() => {
        document.querySelector('.footer_language_drop_down').style.display = language_vis_1 ? 'block':'none';
    },[language_vis_1]);

    function show_language_0() {
        set_language_vis_0(!language_vis_0);
    }

    function show_language_1() {
        set_language_vis_1(!language_vis_1);
    }
    
    function set_langage(index) {
        set_language_vis_0(false);
        set_language_vis_1(false);
        document.querySelectorAll('.language_selector')[0].innerHTML = `<b>${emojis[index]} ${languages[index]} ▼</b>`
        document.querySelectorAll('.language_selector')[1].innerHTML = `<b>${emojis[index]} ${languages[index]} ▲</b>`
    }

    
    const ignoreClasses = ['language_selector', 'footer_language_drop_down', 'language_drop_down'];

    const closeLanguages = useCallback((event) => {
        let target = event.target;
        let ignoreClick = false;

        while (target !== null) {
        if (ignoreClasses.some(className => target.classList.contains(className))) {
            ignoreClick = true;
            break;
        }
        target = target.parentElement;
        }

        if (!ignoreClick) {
            set_language_vis_0(false);
            set_language_vis_1(false);
        }
    }, []);
*/
    const closeLanguages = useCallback((event) => {});

    function go_to_home() {
        window.location = "/";
    }


    return (
        <div onClick={closeLanguages}>
            <div className="component" style={{backgroundColor: 'var(--light_blue)'}}>
                <div className="white_header">
                    <div className='additional_banner' style={{width: "100%", height: "50px", backgroundColor: "white", textAlign: "center", lineHeight: "50px"}}> <b>🚀 Live On Uniswap 🎉</b></div>
                </div>
                <div className="main_header">
                <img src={apemax_rocket} onClick={go_to_home} className="logo"/>
                    <Title_Text text={<>APEMAX</>} text_color={"var(--title_blue)"} outline_color={"black"} override_class={"text_logo"} on_click={go_to_home}/>
                    {/* <div className="text_logo" onClick={go_to_home}>APEMAX</div> */}
                    <div className="credit_card_button_header" style={{boxShadow: `var(--micro_shadow)`}}
                        onClick={()=>window.open(external_links.uniswap_guide)}
                        onTouchEnd={(e)=>{
                            if (validate_touch(e)) {
                                e.preventDefault();
                                window.open(external_links.uniswap_guide);
                            }
                        }}
                    ><b>Trade on Uniswap</b></div>
                    <div className="crypto_button_header" style={{boxShadow: `var(--micro_shadow)`}}
                        onClick={connect_wallet}
                        onTouchEnd={(e)=>{
                            if (validate_touch(e)) {
                                e.preventDefault();
                                connect_wallet();
                            }
                        }}
                    ><b>{isConnected ? "My Profile" : "Connect Wallet"}</b></div>
                    {/* <div className="language_selector" onClick={show_language_0} style={{boxShadow: `var(--micro_shadow)`}}><b>🇬🇧 EN ▼</b></div>
                    <div className="language_drop_down">{(function(){
                        // FILL DEM LANGUAGES;
                        let items = [];
                        
                        for (let i = 0; i < languages.length; i++) {
                            let curI = i;
                            items.push(<div className="language_item" onClick={() => {set_langage(curI)}}>
                                {emojis[i]} {languages[i]}
                            </div>)
                        }
                        return items;
                    })()}</div> */}
                </div>
                
                
                <div className="inner_component">
                    <div className="standard_block">
                        <div className="safe_inner">
                            <RankingTable ranking={ranking} set_selected_project_index={set_selected_project_index}/>
                        </div>
                    </div>
                </div>
            </div>


            <div className="component" style={{backgroundColor: 'white', overflow: 'visible'}}>
                <div className="main_footer">
                    <img src={apemax_rocket} className="footer_logo"/>
                    <Title_Text text={<>APEMAX</>} text_color={"var(--title_blue)"} outline_color={"black"} override_class={"footer_text_logo"}/>
                    {/* <div className="footer_text_logo">APEMAX</div> */}
                    <div className="credit_card_button_header card_footer button_footer"
                        onClick={()=>window.open(external_links.uniswap)}
                        onTouchEnd={(e)=>{
                            if (validate_touch(e)) {
                                e.preventDefault();
                                window.open(external_links.uniswap);
                            }
                        }}
                    ><b>Sell ApeMax</b>
                    {/* <div className="coming_soon_extra_text">Coming Soon</div> */}
                    </div>
                    <div className="crypto_button_header crypto_footer button_footer"
                        onClick={()=>window.open(external_links.uniswap)}
                        onTouchEnd={(e)=>{
                            if (validate_touch(e)) {
                                e.preventDefault();
                                window.open(external_links.uniswap);
                            }
                        }}
                    ><b>Buy ApeMax</b>
                    {/* <div className="coming_soon_extra_text">Coming Soon</div> */}
                    </div>
                    {/* <div className="language_selector language_selector_footer" onClick={show_language_1}><b>🇬🇧 EN ▲</b></div>
                    <div className="footer_language_drop_down">{(function(){
                        // FILL DEM LANGUAGES;
                        let items = [];
                        
                        for (let i = 0; i < languages.length; i++) {
                            let curI = i;
                            items.push(<div className="language_item" onClick={() => {set_langage(curI)}}>
                                {emojis[i]} {languages[i]}
                            </div>)
                        }
                        return items;
                    })()}</div> */}

                    <div className="footer_options_container">
                        <div className="about_apemax_footer">
                            <b className="footer_small_title">About</b>
                            <br/><u className="clickable_text" onClick={()=>{window.open(external_links.whitepaper)}}>Tokenomics</u>
                            <br/><u className="clickable_text" onClick={()=>{window.open(external_links.guides)}}>Guides</u>
                        </div>
                        <div className="documents_footer">
                            <b className="footer_small_title">Docs</b>
                            <br/><u className="clickable_text" onClick={()=>{window.open(external_links.whitepaper)}}>Whitepaper</u>
                            <br/><u className="clickable_text" onClick={()=>{window.open(external_links.audit)}}>Audit Report</u>
                        </div>
                        <div className="terms_footer">
                            <b className="footer_small_title">Terms</b>
                            <br/><u className="clickable_text" onClick={()=>{window.open(external_links.terms_of_use)}}>Terms of Use</u>
                            <br/><u className="clickable_text" onClick={()=>{window.open(external_links.privacy_policy)}}>Privacy Policy</u>
                            <br/><u className="clickable_text" onClick={()=>{window.open(external_links.disclaimer)}}>Disclaimer</u>
                        </div>
                        <div className="social_footer">
                            <b className="footer_small_title">Social</b>
                            <br/><u className="clickable_text" onClick={()=>{window.open(external_links.twitter)}}>Twitter</u>
                            <br/><u className="clickable_text" onClick={()=>{window.open(external_links.discord)}}>Discord</u>
                            <br/><u className="clickable_text" onClick={()=>{window.open(external_links.telegram)}}>Telegram</u>
                        </div>
                    </div>
                    <div className="footer_push"></div>
                    <div className="disclaimer_container">
                        <Discalimer help={false}/>
                    </div>  
                </div>
            </div>

            

            <Project_Details ranking={ranking} selected_project_index={selected_project_index} global={global_data} set_hash={set_tracking_hash} set_global_currency_index={set_global_currency_index}/>
            <Profile_Details set_hash={set_tracking_hash} set_global_currency_index={set_global_currency_index}/>
            <Transaction_Tracker hash={tracking_hash}/>
            
            <Connect_Wallet/>
            <Purchase_Funnel shared_info={shared_info} init_lootbox_index={selected_lootbox_index} lootboxes={is_lootbox_selected} credit_card={is_credit_card_selected}  set_credit_card={set_is_credit_card_selected} purchase_id={purchase_id} s={s} ss={ss} set_global_currency_index={set_global_currency_index}/>
            <Wrong_Network global_currency_index={global_currency_index} set_global_currency_index={set_global_currency_index}/>
            <Blocked_Countries/>
            {/* <Server_Outage/> */}
        </div>
    )
}

export default Ranking;