import axios from 'axios';

let ip_info_token = '40e21bbeb479ed';

let blocked_countries = [
    "US",
    "CA",
    "KP",
    "RU",
    "SS",
    "SD",
    "SY",
    "IR",
    "LY",
    "VE"
]

async function check_if_location_blocked() {
    // let response = await axios.get(`https://ipinfo.io/json?token=${ip_info_token}`);
    // return blocked_countries.includes(response.data.country);
    
    // let current_ip = await get_current_ip();
    // let response = await axios.get(`https://ipinfo.io/json?token=${current_ip.ip}`);
    // console.log(response.data.country)

    let response = await axios.get("https://api.country.is");
    return blocked_countries.includes(response.data.country);

}


async function get_current_ip() {
    let response = await axios.get(`https://api.ipify.org?format=json`);
    return response.data;
}

export {
    check_if_location_blocked
}