import React, { useState } from 'react';
import { register_lead } from "../Services/api";
import Title_Text from "./title_text.jsx";
import external_links from "../Config/external_links.json";
import { get_img } from "../Services/images.jsx";
import { validate_touch } from "../Services/helper_functions.jsx";

let balloons_overhang = get_img("balloons_overhang");
let apemax_with_friend = get_img("apemax_with_friend");
// let twitter_icon = get_img("twitter_icon");
// let discord_icon = get_img("discord_icon");
// let telegram_icon = get_img("telegram_icon");


function Talk_To_Us() {

    const [first_name, setFirst_Name] = useState("");
    const [last_name, setLast_Name] = useState("null");
    const [contact_number, setContact_Number] = useState("000000000");
    const [email_address, setEmail_Address] = useState("");
    const [investment_budget, setInvestment_Budget] = useState("");

    async function add_lead() {
        try {
            await register_lead(first_name, last_name, contact_number, email_address, investment_budget == "" ? "null":"");
            alert("Thank you for your submission!");
        } catch (error) {
            alert("Oh no, it looks like your response is invalid or incomplete.")
        }
    }

    return (
        <div className="component" style={{backgroundColor: 'var(--light_blue)'}}>
            <div className="inner_component inner_component_with_overhang_extra_extra">
                <img src={balloons_overhang} className="overhang"/>
                <div className="standard_block">
                    <div className="safe_inner safe_inner_form">
                        <Title_Text text={<>GET UPDATES</>} text_color={"var(--title_blue)"} outline_color={"black"}/>
                        <div className="inner_title">Leave your details below and we’ll email you the latest updates on ApeMax from time to time.</div>
                        
                        <div className="form_container">
                            <div className="form_item_container">
                                <div className="description_label">Name / Nickname</div>
                                <input className="form_text_input" placeholder={"Name / Nickname"} value={first_name} onChange={(e) => setFirst_Name(e.target.value)} />
                            </div>
                            {/* <div className="form_item_container">
                                <div className="description_label">Last Name</div>
                                <input className="form_text_input" placeholder={"Last Name"} value={last_name} onChange={(e) => setLast_Name(e.target.value)} />
                            </div>
                            <div className="form_item_container">
                                <div className="description_label">Contact Number</div>
                                <input className="form_text_input" placeholder={"Phone Number with Area Code"} value={contact_number} onChange={(e) => setContact_Number(e.target.value)} />
                            </div> */}
                            <div className="form_item_container">
                                <div className="description_label">Email Address</div>
                                <input className="form_text_input" placeholder={"Email Address"} value={email_address} onChange={(e) => setEmail_Address(e.target.value)} />
                            </div>
                            <div className="form_item_container">
                                <div className="description_label">Comments</div>
                                <input className="form_text_input" placeholder={"Comments (optional)"} value={investment_budget} onChange={(e) => setInvestment_Budget(e.target.value)} />
                            </div>
                            
                            <div className="form_tos_container">
                                <div className="form_tos_text">By submitting this form you agree to our Terms and Privacy Policy</div>
                            </div>

                            <div className="submit_form_button" onClick={async (event) => {
                                const btn = event.target;
                                btn.style.pointerEvents = "none";
                                btn.style.border = "5px solid var(--light_gray)";
                                btn.style.backgroundColor = "var(--mid_gray)";
                                btn.innerHTML = "Loading...";
                                await add_lead();
                                btn.style = "";
                                btn.innerHTML = "Submit";
                            }}
                            onTouchEnd={async (e)=>{
                                if (validate_touch(e)) {
                                    e.preventDefault();

                                    const btn = e.target;
                                    btn.style.pointerEvents = "none";
                                    btn.style.border = "5px solid var(--light_gray)";
                                    btn.style.backgroundColor = "var(--mid_gray)";
                                    btn.innerHTML = "Loading...";
                                    await add_lead();
                                    btn.style = "";
                                    btn.innerHTML = "Submit";
                                }
                            }}><b>Submit</b></div>

                        </div>
                    </div>
                    
                </div>
            </div>

            <div className="inner_component inner_component_sequential">
                <div className="standard_block">
                    <div className="safe_inner">
                        <div className="inner_half_split">
                            <Title_Text text={<>JOIN THE COMMUNITY!</>} text_color={"var(--title_blue)"} outline_color={"black"} override_class={"title_text nerf_top_margin"}/>
                            
                            <div className="full_width_button"
                                onClick={()=>window.open(external_links.twitter)}
                                onTouchEnd={(e)=>{
                                    if (validate_touch(e)) {
                                        e.preventDefault();
                                        window.open(external_links.twitter);
                                    }
                                }}><b>Twitter</b></div>
                            <div className="full_width_button"
                                onClick={()=>window.open(external_links.discord)}
                                onTouchEnd={(e)=>{
                                    if (validate_touch(e)) {
                                        e.preventDefault();
                                        window.open(external_links.discord)
                                    }
                                }}><b>Discord</b></div>
                            <div className="full_width_button"
                                onClick={()=>window.open(external_links.telegram)}
                                onTouchEnd={(e)=>{
                                    if (validate_touch(e)) {
                                        e.preventDefault();
                                        window.open(external_links.telegram);
                                    }
                                }}><b>Telegram</b></div>

                        </div>
                        <div className="inner_half_split">
                        <img src={apemax_with_friend} className="full_img_in_split"/>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default Talk_To_Us;