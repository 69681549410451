import { useRef, useEffect, useState } from 'react';
import contract_abi from "../Config/token_abi.json";
import nft_abi from "../Config/nft_abi.json";
import { Web3Button, useWeb3Modal, Web3NetworkSwitch } from '@web3modal/react'
import { token_address, nft_address, admin_wallet, rpc_url, stable_coin_decimals, busd_address, polygon_rpc_url, bnb_rpc_url } from "../Config/config.jsx";
import { useAccount, useConnect, useNetwork } from 'wagmi';
import { prepareWriteContract, prepareSendTransaction, sendTransaction, writeContract, waitForTransaction, readContract, fetchBalance, InjectedConnector, switchNetwork, getNetwork } from '@wagmi/core'
import { get_price_auth, get_info, register_email, register_purchase_intent, retrieve_purchase_status, register_purchase_tx } from "../Services/api.jsx";
import { usdt_address, usdc_address, block_explorer } from '../Config/config.jsx';
import { short_format_number, shorten_crypto_string, track_event, preload_img, validate_touch, is_mobile_device } from "../Services/helper_functions.jsx";
import { CoinbaseWalletConnector } from '@wagmi/core/connectors/coinbaseWallet';
import { LedgerConnector } from '@wagmi/connectors/ledger';
import { MetaMaskConnector } from '@wagmi/core/connectors/metaMask';
import { get_native_token_balance, get_20_token_balance, wait_for_tx } from "../Services/web3_functions";
import { ethers } from 'ethers';

import { crossmintModalService, clientNames } from "@crossmint/client-sdk-base";

import external_links from "../Config/external_links.json";

import Cookies from 'js-cookie';

import Discalimer from "../Components/disclaimer.jsx";

import UseTrackUser from "../Services/sales_attribution.jsx";

import { BigNumber } from 'ethers';

import { get_img } from "../Services/images.jsx";

import { mainnet, polygon, bsc, sepolia, polygonMumbai, hardhat } from "wagmi/chains";
const projectId = '3e07e365ebfe5653a62f32020fb507a1';


let credit_card_icon = get_img("credit_card_icon");
let metmask_icon = get_img("metmask_icon");
let coinbase_wallet_icon = get_img("coinbase_wallet_icon");
let ledger_wallet_icon = get_img("ledger_wallet_icon");
let plugin_wallet_icon = get_img("plugin_wallet_icon");
let other_wallets_icon = get_img("other_wallets_icon");
let walletconnect_icon = get_img("walletconnect_icon");
let ethereum_icon = get_img("ethereum_icon");
let usd_t_icon = get_img("usd_t_icon");
let usd_c_icon = get_img("usd_c_icon");
let bnb_icon = get_img("bnb_icon");
let busd_icon = get_img("busd_icon");
let polygon_icon = get_img("polygon_icon");
let apemax_coin = get_img("apemax_coin");
let purchase_funnel_icon_2 = get_img("purchase_funnel_icon_2");
let purchase_funnel_icon_3 = get_img("purchase_funnel_icon_3");
let purchase_funnel_icon_4 = get_img("purchase_funnel_icon_4");
let purchase_funnel_icon_4_spin = get_img("purchase_funnel_icon_4_spin");
let purchase_funnel_icon_5 = get_img("purchase_funnel_icon_5");
let error_icon = get_img("error_icon");
let apemax_rocket = get_img("apemax_rocket");
let cross_logo = get_img("cross_logo");
let tick_icon = get_img("tick_icon");


let currency_icons = [ethereum_icon, usd_t_icon, usd_c_icon, credit_card_icon, credit_card_icon, polygon_icon, bnb_icon, busd_icon];
let currency_titles = ["ETH","USDT","USDC","CARD","CARD","MATIC","BNB","BUSD"];
let currency_short_desc = ["ETH","USDT","USDC","US$","US$","MATIC","BNB","BUSD"];
let currency_full_desc = ["Pay in Ethereum","Pay in USDT","Pay in USDC","Pay by Credit Card","Pay by Credit Card","Pay in Polygon / Matic","Pay in BNB","Pay in BUSD"]
let blockchain_desc = ["Ethereum","Ethereum","Ethereum","Ethereum","Ethereum","Polygon / Matic","Binance Smart Chain","Binance Smart Chain"]
let default_values = [0.05, 100, 100, 100, 100, 100, 0.3, 100];
let step_size = [0.01, 10, 10, 10, 10, 10, 10, 10];



let lootbox_imgs = [];
for (let i = 0; i < 5; i++) {
    lootbox_imgs.push(get_img(`lootbox_${i}`));
}
let purchase_funnel_icons = [apemax_rocket, apemax_coin, purchase_funnel_icon_2, purchase_funnel_icon_3, purchase_funnel_icon_4, purchase_funnel_icon_5]

function Currency_Selector_Page({set_currency_index, set_purchase_stage}) {

    return (<>

        {/* Choose Payment Currency */}
        <img className="purchase_stage_icon" src={apemax_coin}/>
        <div className="purchase_stage_title"><b>Select Payment Currency</b></div>
        <div className="button_purchase_funnel" onClick={() => {set_currency_index(0); set_purchase_stage(1);}}
            onTouchEnd={(e)=>{
                if (validate_touch(e)) {
                    e.preventDefault();
                    set_currency_index(0); set_purchase_stage(1);
                }
            }}>
            <img className="purchase_button_icon" src={ethereum_icon}/>
            Pay in Ethereum
        </div>
        <div className="button_purchase_funnel" onClick={() => {set_currency_index(1); set_purchase_stage(1);}}
            onTouchEnd={(e)=>{
                e.preventDefault();
                set_currency_index(1); set_purchase_stage(1);
            }}>
            <img className="purchase_button_icon" src={usd_t_icon}/>
            Pay in USDT (ERC20)
        </div>
        <div className="button_purchase_funnel"  onClick={() => {set_currency_index(2); set_purchase_stage(1);}}
            onTouchEnd={(e)=>{
                if (validate_touch(e)) {
                    e.preventDefault();
                    set_currency_index(2); set_purchase_stage(1);
                }
            }}>
            <img className="purchase_button_icon" src={usd_c_icon}/>
            Pay in USDC (ERC20)
        </div>
        <div className="button_purchase_funnel"  onClick={() => {set_currency_index(5); set_purchase_stage(1);}}
            onTouchEnd={(e)=>{
                if (validate_touch(e)) {
                    e.preventDefault();
                    set_currency_index(5); set_purchase_stage(1);
                }
            }}>
            <img className="purchase_button_icon" src={polygon_icon}/>
            Pay in Polygon / Matic
        </div>
        <div className="button_purchase_funnel"  onClick={() => {set_currency_index(6); set_purchase_stage(1);}}
            onTouchEnd={(e)=>{
                if (validate_touch(e)) {
                    e.preventDefault();
                    set_currency_index(6); set_purchase_stage(1);
                }
            }}>
            <img className="purchase_button_icon" src={bnb_icon}/>
            Pay in BNB
        </div>
        <div className="button_purchase_funnel"  onClick={() => {set_currency_index(7); set_purchase_stage(1);}}
            onTouchEnd={(e)=>{
                if (validate_touch(e)) {
                    e.preventDefault();
                    set_currency_index(7); set_purchase_stage(1);
                }
            }}>
            <img className="purchase_button_icon" src={busd_icon}/>
            Pay in BUSD (BEP20)
        </div>
    </>);

}

function Lootbox_Selector_Page({apemax_price, set_currency_index, currency_index, set_lootbox_index, lootbox_index, lootbox_info, set_lootbox_selector_visible, lootbox_selector_visible, set_currency_selector_visible, currency_selector_visible, purchase_apemax_qty_ref, needs_tos, agreed_tos, set_agreed_tos, loading, set_purchase_stage}) {
    return (<>
        {/* Lootbox Selection */}

        <img className="purchase_stage_icon" src={purchase_funnel_icon_2}/>
        <div className="purchase_stage_title"><b>Purchase Lootbox</b></div>
        
        <div className="purchase_funnel_label">Selected lootbox and amount you pay ({currency_short_desc[currency_index]})</div>
        <div className="lootbox_drop_down" onClick={() => {set_lootbox_selector_visible(!lootbox_selector_visible)}}
            onTouchEnd={(e)=>{
                if (validate_touch(e)) {
                    e.preventDefault();
                    set_lootbox_selector_visible(!lootbox_selector_visible)
                }
            }}>
            <img className="purchase_currency_drop_down_icon" src={lootbox_imgs[lootbox_index]}/>
            <div className="lootbox_drop_down_title"><b>{lootbox_info.length==0?"Loading...":`${lootbox_info[lootbox_index].name}`}</b></div>
            <div className="lootbox_drop_down_currency"><b>{

                function() {
                    if (lootbox_info.length == 0) {
                        return "Loading..."
                    }
                    else {
                        if (currency_index == 0) {
                            return (lootbox_info[lootbox_index].eth_price).toFixed(4);
                        }
                        else if (currency_index < 5 || currency_index == 7) {
                            return (lootbox_info[lootbox_index].usd_price).toFixed(2)
                        }
                        else if (currency_index == 5) {
                            return (lootbox_info[lootbox_index].polygon_price).toFixed(4);
                        }
                        else if (currency_index == 6) {
                            return (lootbox_info[lootbox_index].bnb_price).toFixed(4);
                        }
                    }
                }()
                }
                {currency_short_desc[currency_index]}  | <strike>{

                function() {
                    if (currency_index == 0) {
                        return (lootbox_info[lootbox_index].og_eth_cost).toFixed(4);
                    }
                    else if (currency_index < 5 || currency_index == 7) {
                        return (lootbox_info[lootbox_index].og_cost).toFixed(2);
                    }
                    else if (currency_index == 5) {
                        return (lootbox_info[lootbox_index].og_polygon_cost).toFixed(4);
                    }
                    else if (currency_index == 6) {
                        return (lootbox_info[lootbox_index].og_bnb_cost).toFixed(4);
                    }
                }()
                
                } {currency_short_desc[currency_index]}</strike> ({lootbox_info[lootbox_index].discount}% off)</b></div>
            <div className="purchase_currency_drop_down_extra">▼</div>
        </div>

        <div className="purchase_currency_drop_down" onClick={() => {set_currency_selector_visible(!currency_selector_visible)}}
            onTouchEnd={(e)=>{
                if (validate_touch(e)) {
                    e.preventDefault();
                    set_currency_selector_visible(!currency_selector_visible)
                }
            }}>
            <img className="purchase_currency_drop_down_icon" src={currency_icons[currency_index]}/>
            <div className="purchase_currency_drop_down_label"><b>{currency_titles[currency_index]}</b></div>
            <div className="purchase_currency_drop_down_extra">▼</div>
        </div>

        <div className="purchase_currency_drop_down_list">

            {(function() {
                let btns = [];
                for (let i = 0; i < 8; i++) {
                    if (i==3 || i==4) { continue; }
                    let curI = i;
                    btns.push(
                        <div className="purchase_currency_drop_down_item" onClick={() => {
                                set_currency_index(curI);
                                set_currency_selector_visible(false);
                            }}
                            onTouchEnd={(e)=>{
                                if (validate_touch(e)) {
                                    e.preventDefault();
                                    set_currency_index(curI);
                                    set_currency_selector_visible(false);
                                }
                            }}>
                            <img className="purchase_currency_drop_down_icon" src={currency_icons[i]}/>
                            <div className="purchase_currency_drop_down_label">{currency_titles[i]}</div>
                        </div>
                    )
                }
                return btns;
            })()}

        </div>

        <div className="purchase_lootbox_drop_down_list">

            {(function() {
                let btns = [];
                for (let i = 0; i < lootbox_info.length; i++) {
                    let curI = i;
                    btns.push(
                        <div className="purchase_currency_drop_down_item" onClick={() => {
                                set_lootbox_index(curI);
                                set_lootbox_selector_visible(false);
                            }}
                            onTouchEnd={(e)=>{
                                if (validate_touch(e)) {
                                    e.preventDefault();
                                    set_lootbox_index(curI);
                                    set_lootbox_selector_visible(false);
                                }
                            }}>
                            {/* <img className="purchase_background_drop_down_icon" src={blue_swirl_background}/> */}
                            <img className="purchase_currency_drop_down_icon" src={lootbox_imgs[i]}/>
                            <div className="lootbox_drop_down_title">{lootbox_info[i].name}</div>
                            <div className="lootbox_drop_down_currency">{

                                function() {
                                    if (lootbox_info.length == 0) {
                                        return "Loading..."
                                    }
                                    else {
                                        if (currency_index == 0) {
                                            return (lootbox_info[i].eth_price).toFixed(4);
                                        }
                                        else if (currency_index < 5 || currency_index == 7) {
                                            return (lootbox_info[i].usd_price).toFixed(2)
                                        }
                                        else if (currency_index == 5) {
                                            return (lootbox_info[i].polygon_price).toFixed(4);
                                        }
                                        else if (currency_index == 6) {
                                            return (lootbox_info[i].bnb_price).toFixed(4);
                                        }
                                    }
                                }()
                                }
                                {currency_short_desc[currency_index]}  | <strike>{

                                function() {
                                    if (currency_index == 0) {
                                        return (lootbox_info[i].og_eth_cost).toFixed(4);
                                    }
                                    else if (currency_index < 5 || currency_index == 7) {
                                        return (lootbox_info[i].og_cost).toFixed(2);
                                    }
                                    else if (currency_index == 5) {
                                        return (lootbox_info[i].og_polygon_cost).toFixed(4);
                                    }
                                    else if (currency_index == 6) {
                                        return (lootbox_info[i].og_bnb_cost).toFixed(4);
                                    }
                                }()

                                } {currency_short_desc[currency_index]}</strike> ({lootbox_info[i].discount}% off)


                            </div>
                        </div>
                    )
                }
                return btns;
            })()}

        </div>

        <div className="purchase_funnel_label">Amount of ApeMax you receive</div>
        <input className="purchase_funnel_input" value={
            apemax_price == void 0 || lootbox_info == void 0 ?
                "Loading..."
                :
                `${short_format_number(lootbox_info[lootbox_index].quantity)} (${short_format_number(lootbox_info[lootbox_index].quantity-lootbox_info[lootbox_index].og_quantity)} free coins)`
        } ref={purchase_apemax_qty_ref} readOnly/>
        <div className="purchase_currency_descriptor">
            <img className="purchase_currency_drop_down_icon" src={apemax_coin}/>
            <div className="purchase_currency_drop_down_label"><b>APEMAX</b></div>
        </div>

        <div className="form_tos_container" style={needs_tos?{display:"block"}:{display:"none"}}>
            <div className="form_tos_checkbox" style={agreed_tos?{backgroundColor: "var(--selected_blue)"}:{}}
                onClick={() => {
                    set_agreed_tos(!agreed_tos)
                }}
                onTouchEnd={async (e)=>{
                    if (validate_touch(e)) {
                        e.preventDefault();
                        set_agreed_tos(!agreed_tos);
                    }
                }}
            ><img className="tos_tick_icon" src={tick_icon}/></div>
            <div className="form_tos_text">I agree to the <u className="clickable_text" onClick={()=>{window.open(external_links.terms_of_use)}}>Terms of Service</u>, Disclaimer below, and <u className="clickable_text" onClick={()=>{window.open(external_links.privacy_policy)}}>Privacy Policy</u>. I have read and understood the guide on the <u className="clickable_text" onClick={()=>{window.open(external_links.disclaimer)}}>Risks of Buying ApeMax</u>. I hereby declare am not from any <u className="clickable_text" onClick={()=>{window.open(external_links.blocked_countries)}}>Banned Country</u>.</div>
        </div>

        <div className="button_purchase_funnel center_align"
        onClick={async (event) => {

            if (needs_tos && !agreed_tos) {
                alert("You must first read and agree to the Terms of Service and Privacy Policy. You must also declare that you understand the Risks of Purchasing ApeMax and are not from a country on the Banned Countries list.")
                return;
            }

            const btn = event.target;
            btn.style.pointerEvents = "none";
            btn.style.border = "5px solid var(--light_gray)";
            btn.style.backgroundColor = "var(--mid_gray)";


            set_purchase_stage(2);

            btn.style = "";
        }}
        onTouchEnd={async (e)=>{
            if (validate_touch(e)) {
                e.preventDefault();

                if (needs_tos && !agreed_tos) {
                    alert("You must first read and agree to the Terms of Service and Privacy Policy. You must also declare that you understand the Risks of Purchasing ApeMax and are not from a country on the Banned Countries list.")
                    return;
                }

                const btn = e.target;
                btn.style.pointerEvents = "none";
                btn.style.border = "5px solid var(--light_gray)";
                btn.style.backgroundColor = "var(--mid_gray)";

                set_purchase_stage(2);

                btn.style = "";
            }
        }}>
            {
                loading == true ? 
                <div className="purchase_button_icon">
                    <img className="purchase_button_icon_spin" src={currency_icons[currency_index]}/>
                </div>
                :
                <img className="purchase_button_icon" src={currency_icons[currency_index]}/>
            }
            
            {loading == true ? "Loading..." : currency_full_desc[currency_index]}
        </div>

    </>)
}


function New_Purchase_Funnel({shared_info, purchase_id, is_lootbox=false, init_lootbox_index=-1}) {

    const purchase_amount_ref = useRef();
    const purchase_apemax_qty_ref = useRef();

    const [purchase_stage, set_purchase_stage] = useState(0);
    const [currency_index, set_currency_index] = useState(0);

    const [apemax_price, set_apemax_price] = useState(void 0);
    const [lootbox_info, set_lootbox_info] = useState([]);
    const [lootbox_index, set_lootbox_index] = useState(init_lootbox_index);

    const [needs_tos, set_needs_tos] = useState(true);
    const [agreed_tos, set_agreed_tos] = useState(false);

    const [loading, set_loading] = useState(false);

    const [currency_selector_visible, set_currency_selector_visible] = useState(false);
    const [lootbox_selector_visible, set_lootbox_selector_visible] = useState(false);

    // -- CLOSING / EXIT
    const purchase_funnel_ref = useRef(null);
    function close_pop_up() {
        document.querySelector('body').style.overflow = 'auto';
        purchase_funnel_ref.current.style.display = 'none';
        // set_has_purchase_error(false);
        // set_currency_selector_visible(false);
        // set_purchase_stage(0);
        // ss(false);
        // set_loading(false);
    }

    

    

    


    // USE STATES...

    let recent_value = useRef();

    function update_price(e) {

        // other stuff
        if (purchase_apemax_qty_ref.current && !is_lootbox && apemax_price) {
            let amount_payable = purchase_amount_ref.current.value;

            if (currency_index == 0) {
                purchase_apemax_qty_ref.current.value = short_format_number(amount_payable / apemax_price.eth_price);
            }
            else if (currency_index < 5 || currency_index == 7) {
                purchase_apemax_qty_ref.current.value = short_format_number(amount_payable / apemax_price.base_price);
            }
            else if (currency_index == 5) {
                purchase_apemax_qty_ref.current.value = short_format_number(amount_payable / apemax_price.polygon_price);
            }
            else if (currency_full_desc == 6) {
                purchase_apemax_qty_ref.current.value = short_format_number(amount_payable / apemax_price.bnb_price);
            }

            recent_value.current = amount_payable;

            // update_minting_info();
        }
        else if (purchase_apemax_qty_ref.current && is_lootbox && lootbox_info) {
            let quantiy = lootbox_info[lootbox_index].quantity;
            let og_quantity = lootbox_info[lootbox_index].og_quantity;
            purchase_apemax_qty_ref.current.value = `${short_format_number(quantiy)} (${short_format_number(quantiy-og_quantity)} free coins)`;
            
            // update_minting_info();
        }

    }

    function set_default_price() {

        if (purchase_amount_ref.current && !is_lootbox) {
            purchase_amount_ref.current.value = default_values[currency_index];
            purchase_amount_ref.current.step = step_size[currency_index];
            update_price();
        }
        else if (is_lootbox) {
            update_price();
        }
    }

    useEffect(() => {

        set_default_price();

    },[currency_index]);

    let previous_stage = useRef(0);

    useEffect(() => {

        if (purchase_stage == 2 && previous_stage.current < 2) {
            set_default_price();
        }
        else if (recent_value.current && purchase_amount_ref.current && !is_lootbox) {
            purchase_amount_ref.current.value = recent_value.current;
            purchase_amount_ref.current.step = step_size[currency_index];
            update_price();
        }

        previous_stage.current = purchase_stage;

    },[purchase_stage]);

    useEffect(()=>{
        set_lootbox_index(init_lootbox_index);
    },[init_lootbox_index]);

    useEffect(() => {

        let purchase_currency_drop_down_list = document.querySelector(".purchase_currency_drop_down_list");
        if (purchase_currency_drop_down_list) {
            purchase_currency_drop_down_list.style.display = currency_selector_visible ? 'block':'none';
            update_price();
        }
        
    },[currency_selector_visible])


    useEffect(() => {

        let purchase_lootbox_drop_down_list = document.querySelector(".purchase_lootbox_drop_down_list");
        if (purchase_lootbox_drop_down_list) {
            purchase_lootbox_drop_down_list.style.display = lootbox_selector_visible ? 'block':'none';
            update_price();
        }
    },[lootbox_selector_visible]);

    useEffect(() => {


        if (shared_info) {
            set_apemax_price({
                base_price: shared_info.base_price,
                eth_price: shared_info.eth_price,
                polygon_price: shared_info.base_price / shared_info.polygon_exchange_rate,
                bnb_price: shared_info.base_price / shared_info.bnb_exchange_rate
            });
            set_lootbox_info(shared_info.lootboxes);
        }
        set_default_price();
    },[shared_info]);

    return (
        <div ref={purchase_funnel_ref} className="overlay_container new_purchase_funnel">
            
            {/* If click outside exit */}

            <div className="pop_up_close_outter" onClick={close_pop_up}
            onTouchEnd={(e)=>{
                if (validate_touch(e)) {
                    e.preventDefault();
                    close_pop_up();
                }
            }}
            ></div>

            {/* If click close exit */}

            <div className="pop_up_container">
                <img className="pop_up_close" src={cross_logo}
                    onClick={close_pop_up}
                    onTouchEnd={(e)=>{
                        if (validate_touch(e)) {
                            e.preventDefault();
                            close_pop_up();
                        }
                    }}
                />
            
                <div className="pop_up_title"><b>Purchase ApeMax</b></div>
                
                <div className="pop_up_inner">
                    {
                        purchase_stage > 0 ?
                        <>
                            <div className="back_button"
                            onClick={()=>{set_purchase_stage(purchase_stage-1)}}
                            onTouchEnd={async (e)=>{
                                if (validate_touch(e)) {
                                    e.preventDefault();
                                    set_purchase_stage(purchase_stage-1);
                                }
                            }}
                            >{"<  Back"}</div>

                            <div className="help_button"><u>Get Help</u></div>

                        </> : (<></>)
                    }
                    <div className="purchase_qty_container">
                    {
                        purchase_stage == 0 ?
                        <Currency_Selector_Page set_currency_index={set_currency_index} set_purchase_stage={set_purchase_stage}/> :

                        purchase_stage == 1 ?
                        (<>
                            {is_lootbox ?
                                <Lootbox_Selector_Page apemax_price={apemax_price} set_currency_index={set_currency_index} currency_index={currency_index} set_lootbox_index={set_lootbox_index} lootbox_index={lootbox_index} lootbox_info={lootbox_info} set_lootbox_selector_visible={set_lootbox_selector_visible} lootbox_selector_visible={lootbox_selector_visible} set_currency_selector_visible={set_currency_selector_visible} currency_selector_visible={currency_selector_visible} purchase_apemax_qty_ref={purchase_apemax_qty_ref} needs_tos={needs_tos} agreed_tos={agreed_tos} set_agreed_tos={set_agreed_tos} loading={loading} set_purchase_stage={set_purchase_stage}/> :
                                (<></>)
                            }

                        </>) :

                        (<></>)
                    }

                
                    </div>
                    <Discalimer/>
                </div>
                
            </div>


        </div>
    )

}

export default New_Purchase_Funnel;