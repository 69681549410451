import { prepareWriteContract, sendTransaction, writeContract, waitForTransaction, readContract, fetchBalance } from '@wagmi/core'

// import token_abi from "../Config/token_abi.json";
import token_abi from "../Config/new_token_abi.json";
import nft_abi from "../Config/nft_abi.json";
import getter_abi from "../Config/getter_abi.json";
import nft_polygon_abi from "../Config/nft_polygon_abi.json";
import proxy_staking_abi from "../Config/proxy_staking_abi.json";

import { nft_address, rpc_url, polygon_rpc_url, usdt_address, usdc_address, polygon_nft_address, bnb_rpc_url } from "../Config/config.jsx";

import { ethers } from "ethers";


import { cast_to_bignumber } from "../Services/helper_functions.jsx";

let ethers_provider = new ethers.JsonRpcProvider(rpc_url);



let token_address = '0x5bd97d0c8e668298f2a6baa069f47f556a447a78';
let getter_address = '0x0923535be9dde48abbe95538174fea3dd3837fe1';
let proxy_staking_address = "0x6004De2F7Bd7490f8BDD7dC0f0edA9249d519B01"

let contract_provider = new ethers.JsonRpcProvider("https://prettiest-distinguished-meadow.bsc.quiknode.pro/9a9f361d202fe7c7205dc7e20bfcb679776f9d7d/");

let token_contract = new ethers.Contract(token_address, token_abi, contract_provider);
let getter_contract = new ethers.Contract(getter_address, getter_abi, contract_provider);
// let proxy_staking_contract = new ethers.Contract(proxy_staking_address, proxy_staking_abi, contract_provider);


let polygon_provider = new ethers.JsonRpcProvider(polygon_rpc_url);
let polygon_contract = new ethers.Contract(polygon_nft_address, nft_polygon_abi, polygon_provider);

let bnb_provider = new ethers.JsonRpcProvider(bnb_rpc_url);


export const wait_for_tx = async (hash, chain_id) => {

    let provider;
    if (chain_id < 5) {
        provider = ethers_provider;
    }
    else if (chain_id == 5) {
        provider = polygon_provider;
    }
    else if (chain_id < 8) {
        provider = bnb_provider;
    }

    const receipt = await provider.waitForTransaction(hash);
}

export const get_native_token_balance = async (account, chain_id) => {

    let balance;

    if (chain_id < 5) {
        balance = await ethers_provider.getBalance(account);
    }
    else if (chain_id == 5) {
        balance = await polygon_provider.getBalance(account);
    }
    else if (chain_id < 8) {
        balance = await bnb_provider.getBalance(account);
    }

    return balance;
}

export const get_20_token_balance = async (account, token_addr, chain_id) => {

    let provider;
    if (chain_id < 5) {
        provider = ethers_provider;
    }
    else if (chain_id == 5) {
        provider = polygon_provider;
    }
    else if (chain_id < 8) {
        provider = bnb_provider;
    }

    const contract = new ethers.Contract(token_addr, relevant_abi, provider);
    const user_balance = await contract.balanceOf(account);

    return user_balance;
}


export const get_apemax_balance = async (account) => {

    let user_balance = await token_contract.balanceOf(account);

    console.log({
        user_balance: Number(user_balance),
        account,
        token_address,
        token_contract
    })

    // let user_balance = await readContract({
    //     address: token_address,
    //     abi: token_abi,
    //     functionName: 'balanceOf',
    //     args: [
    //         account
    //     ]
    // });
    return user_balance;
}

export const stake_apemax = async (address, amount_staked, contract_index) => {

    try {

        let user_balance = await get_apemax_balance(address);
        if (user_balance < amount_staked) {
            return {status:400};
        }

        let stake_address = (ethers.Wallet.createRandom()).address;

        

        [amount_staked] = cast_to_bignumber([amount_staked]);

        console.log({address, amount_staked, contract_index, stake_address})

        let approval_config = await prepareWriteContract({
            address: proxy_staking_address, //token_address,
            abi: proxy_staking_abi, //token_abi,
            functionName: 'stake_tokens',
            args: [
                amount_staked,
                contract_index,
                stake_address
            ],
            overrides: {
                gasLimit: 300000
            },
            gas: 300_000n,
        });

        let { hash } = await writeContract(approval_config);
        return {status:200, hash};
        
    } catch (error) {
        console.log(error);
        return {status:500};
    }
}

export const get_staking_rewards_batch = async (stake_addresses,contract_indexes) => {
    // let unclaimed_rewards = await readContract({
    //     address: getter_address,
    //     abi: getter_abi,
    //     functionName: 'get_staking_rewards_batch',
    //     args: [
    //         stake_addresses,
    //         contract_indexes
    //     ]
    // });
    let unclaimed_rewards = await getter_contract.get_staking_rewards_batch(stake_addresses, contract_indexes)
    return unclaimed_rewards;
}


export const get_staking_fees = async (amount_staked,contract_indexes) => {
    [amount_staked] = cast_to_bignumber([amount_staked]);
    // let unclaimed_rewards = await readContract({
    //     address: getter_address,
    //     abi: getter_abi,
    //     functionName: 'get_staking_fees',
    //     args: [
    //         amount_staked,
    //         contract_indexes
    //     ]
    // });
    // return unclaimed_rewards;
    let unclaimed_rewards = await getter_contract.get_staking_fees(amount_staked, contract_indexes)
    return unclaimed_rewards;
}

export const get_contract = async (contract_index) => {
    // let contract = await readContract({
    //     address: token_address,
    //     abi: token_abi,
    //     functionName: 'get_contract',
    //     args: [
    //         contract_index
    //     ]
    // });
    // return contract;
    let contract = await token_contract.get_contract(contract_index);
    return contract;
}

export const get_global = async () => {
    // let global = await readContract({
    //     address: token_address,
    //     abi: token_abi,
    //     functionName: 'get_global',
    //     args: []
    // });
    // return global;
    let global = await token_contract.get_global();
    return global;
}

export const get_unclaimed_creator_rewards = async (contract_indexes) => {
    let unclaimed_rewards = await readContract({
        address: getter_address,
        abi: getter_abi,
        functionName: 'get_unclaimed_creator_rewards',
        args: [
            contract_indexes
        ]
    });
    return unclaimed_rewards;
}


export const claim_staking_rewards = async (stake_address, contract_index) => {
    try {
        let approval_config = await prepareWriteContract({
            address: proxy_staking_address, //token_address,
            abi: proxy_staking_abi, //token_abi,
            functionName: 'claim_staking_rewards',
            args: [
                stake_address,
                contract_index
            ],
            overrides: {
                gasLimit: 300000
            },
            gas: 300_000n,
        });

        let { hash } = await writeContract(approval_config);
        return {status:200, hash};
    } catch (error) {
        console.log(error);
        return {status:500};
    }
}

export const unstake_tokens = async (stake_address, contract_index) => {
    try {
        let approval_config = await prepareWriteContract({
            address: proxy_staking_address, //token_address,
            abi: proxy_staking_abi, //token_abi,
            functionName: 'unstake_tokens',
            args: [
                stake_address,
                contract_index
            ],
            overrides: {
                gasLimit: 300000
            },
            gas: 300_000n,
        });

        let { hash } = await writeContract(approval_config);
        return {status:200, hash};
    } catch (error) {
        console.log(error);
        return {status:500};
    }
}

export const update_royalties = async (contract_index, royalties) => {
    try {
        [royalties] = cast_to_bignumber([royalties]);
        let approval_config = await prepareWriteContract({
            address: token_address,
            abi: token_abi,
            functionName: 'update_royalties',
            args: [
                contract_index,
                royalties
            ],
            overrides: {
                gasLimit: 300000
            },
            gas: 300_000n,
        });

        let { hash } = await writeContract(approval_config);
        return {status:200, hash};
    } catch (error) {
        console.log(error);
        return {status:500};
    }
}

export const claim_creator_rewards = async (contract_index) => {
    try {
        let approval_config = await prepareWriteContract({
            address: token_address,
            abi: token_abi,
            functionName: 'claim_creator_rewards',
            args: [
                contract_index
            ],
            overrides: {
                gasLimit: 300000
            },
            gas: 300_000n,
        });

        let { hash } = await writeContract(approval_config);
        return {status:200, hash};
    } catch (error) {
        console.log(error);
        return {status:500};
    }
}

let relevant_abi = [
    {
      "constant": true,
      "inputs": [],
      "name": "name",
      "outputs": [{ "name": "", "type": "string" }],
      "payable": false,
      "type": "function",
    },
    {
        "constant": true,
        "inputs": [{"name": "_owner", "type": "address"}],
        "name": "balanceOf",
        "outputs": [{"name": "balance", "type": "uint256"}],
        "type": "function"
    }
];


export const sales_amounts = async (currency_index) => {

    if (currency_index == 0) {
        let balance = await fetchBalance({
            address: token_address,
        });

        return balance;
    }
    else {
        
    
        let balance = await readContract({
            address: [usdt_address, usdc_address][currency_index-1],
            abi: relevant_abi,
            functionName: 'balanceOf',
            args: [
                token_address
            ]
        });

        return balance
    }

    


}


export const withdraw_currency = async (currency_index) => {
    try {
        let approval_config = await prepareWriteContract({
            address: token_address,
            abi: token_abi,
            functionName: 'withdraw_currency',
            args: [
                currency_index
            ],
            overrides: {
                gasLimit: 150000
            },
            gas: 150_000n,
        });

        let { hash } = await writeContract(approval_config);
        return {status:200, hash};
    } catch (error) {
        console.log(error);
        return {status:500};
    }
}


export const claim_ministerial_rewards = async () => {
    try {
        let approval_config = await prepareWriteContract({
            address: token_address,
            abi: token_abi,
            functionName: 'claim_ministerial_rewards',
            args: [],
            overrides: {
                gasLimit: 150000
            },
            gas: 150_000n,
        });

        let { hash } = await writeContract(approval_config);
        return {status:200, hash};
    } catch (error) {
        console.log(error);
        return {status:500};
    }
}

export const get_unclaimed_ministerial_rewards = async () => {
    let unclaimed_rewards = await readContract({
        address: getter_address,
        abi: getter_abi,
        functionName: 'get_unclaimed_ministerial_rewards',
        args: []
    });
    return unclaimed_rewards;
}


export const enable_transfers = async (_transfers_allowed) => {
    try {
        let approval_config = await prepareWriteContract({
            address: token_address,
            abi: token_abi,
            functionName: 'enable_transfers',
            args: [
                _transfers_allowed
            ],
            overrides: {
                gasLimit: 150000
            },
            gas: 150_000n,
        });

        let { hash } = await writeContract(approval_config);
        return {status:200, hash};
    } catch (error) {
        console.log(error);
        return {status:500};
    }
}

export const get_polygon_balance = async (address) => {
    let balance = await polygon_contract.get_address_balance(address);
    return balance;
}



// export const get_contract_ranking = async () => {
//     let ranking = await readContract({
//         address: getter_address,
//         abi: getter_abi,
//         functionName: 'get_contract_ranking',
//         args: [
//             100,
//             0,
//             true
//         ]
//     });

//     console.log({ranking});
// }

// export async function test() {
//     console.log("RUNNING TEST", {token_address});
//     let provider = new ethers.providers.JsonRpcProvider(rpc_url)
//     let contract = new ethers.Contract(token_address, token_abi, provider);
//     let event_filter = contract.filters._distribute_rewards(null,null,null,null,null);
//     let blockNumber = await provider.getBlockNumber();

//     let collected_events = [];

//     for (let i = 0; i < 10; i++) {

//         if (collected_events.length >= 10) {
//             break;
//         }

//         event_filter = {
//             ...event_filter,
//             fromBlock: blockNumber-10000,
//             toBlock: blockNumber,
//         };

//         let events = await provider.getLogs(event_filter);

//         for (let x = 0; x < events.length; x++) {
//             collected_events.push(events[x]);
//             if (collected_events.length >= 10) {
//                 break;
//             } 
//         }

//         blockNumber-=10000;
//     }

//     let actual_info = [];
//     collected_events.forEach(event=> {
//         let info = contract.interface.parseLog(event);
//         actual_info.push(info);
//     })
//     console.log({actual_info})
// }

