import { useState, useRef, useEffect, useLayoutEffect } from 'react';

import { short_format_number, validate_touch } from "../Services/helper_functions.jsx";

import Title_Text from "./title_text.jsx";

import { useSwipeable } from 'react-swipeable';

import { get_img } from "../Services/images.jsx";

let treasure_overhang = get_img("treasure_overhang");
let blue_swirl_background = get_img("blue_swirl_background");

let lootbox_imgs = [];
for (let i = 0; i < 5; i++) {
    lootbox_imgs.push(get_img(`lootbox_${i}`));
}


function Loot_Boxes({launch_purchase_funnel, shared_info}) {

    const [currentIndex, setCurrentIndex] = useState(2);

    const swipe_handlers = useSwipeable({
        onSwipedLeft: () => {
            increment_carousel();
        },
        onSwipedRight: () => {
            decrement_carousel();
        },
        preventDefaultTouchmoveEvent: true,
        trackMouse: false
    });

    function open_purchase_funnel(credit_card=false) {
        launch_purchase_funnel(currentIndex,credit_card,true);
    }


    const carousel_container = useRef(null);
    useEffect(() => {
        if (carousel_container.current) {

            carousel_container.current.scroll({
                left: (2510-carousel_container.current.offsetWidth)/2 + 282*(currentIndex-2),
                behavior: 'smooth'
            });

            set_carousel_views(currentIndex);
        }
    },[currentIndex]);


    let carousel_go_decrement = useRef();
    let carousel_go_increment = useRef();
    const [carouselItems, setCarouselItems] = useState([]);

    useEffect(() => {
        if (carouselItems.length > 0) {
            set_carousel_views(currentIndex);
        }
    }, [currentIndex, carouselItems]);

    let lootbox_comp = useRef();

    useEffect(() => {
        const url = new URL(window.location.href);
        const params = url.searchParams;
        let has_lb = params.get("lootbox");
        console.log(has_lb);
        if (has_lb) {
            setTimeout(function() {
                lootbox_comp.current.scrollIntoView({ behavior: 'smooth' });
            },2500);
        }
    },[])

    const addToCarouselItems = (el) => {
        if (el && !carouselItems.includes(el)) {
            setCarouselItems(prevItems => [...prevItems, el]);
        }
    };

    const make_items_buttons_clickable = (item,can_click) => {
        let carousel_cover = item.querySelector(".carousel_cover");
        carousel_cover.style.display = can_click ? "none":"block";
    }

    function set_carousel_views(curIndex) {

        carousel_go_decrement.current.style.opacity = curIndex == 0 ? '0.25' : '1';
        carousel_go_increment.current.style.opacity = curIndex == 4 ? '0.25' : '1';



        for (let i = 0; i < carouselItems.length; i++) {
            let item = carouselItems[i];

            if (i == curIndex) {
                item.className = 'carousel_item carousel_item_full';
                make_items_buttons_clickable(item,true);
            }
            else if (i+1 == curIndex || i-1 == curIndex) {
                item.className = 'carousel_item carousel_item_medium';
                make_items_buttons_clickable(item,false);
            }
            else {
                item.className = 'carousel_item carousel_item_small';
                make_items_buttons_clickable(item,false);
            }
        }
    }

    function increment_carousel() {
        let curIndex = currentIndex;
        curIndex = curIndex < 4 ? curIndex+1 : 4;
        setCurrentIndex(curIndex);
    }

    function decrement_carousel() {
        let curIndex = currentIndex;
        curIndex = curIndex > 0 ? curIndex-1 : 0;
        setCurrentIndex(curIndex);
    }


    useLayoutEffect(() => {

        let prev_width = 0;

        if (shared_info != void 0) {
            window.addEventListener('resize', function() {

                if (prev_width == window.innerWidth) {
                    return;
                }
    
                prev_width = window.innerWidth;
                
                if (carousel_container.current) {
                    setCurrentIndex(currentIndex);
                    carousel_container.current.scroll({
                        left: (2510-carousel_container.current.offsetWidth)/2 + 282*(currentIndex-2),
                        behavior: 'smooth'
                    });
                    set_carousel_views(currentIndex);
                }
            });
        }
    },[shared_info]);


    return (
        <div className="component lootbox_comp" style={{backgroundColor: 'var(--light_green)'}} ref={lootbox_comp}>
            <div className="inner_component inner_component_with_overhang_extra">
                <img src={treasure_overhang} className="overhang"/>
                <div className="standard_block">
                    <div className="safe_inner">
                        <Title_Text text={<>LOOT BOXES</>} text_color={"var(--title_green)"} outline_color={"black"}/>
                        {/* <div className="title_text">LOOT BOXES</div> */}
                        <div className="inner_title">Discover guaranteed savings with our ApeMax Lootbox deals! Each purchase delivers discounted ApeMax tokens, ensuring you get more bang for your buck.</div>
                        <div className="carousel_super_container" {...swipe_handlers}>
                            <div className="carousel_container" ref={carousel_container}>
                                <div className="carousel_inner_max">
                                    {(function() {
                                        
                                        
                                        
                                       
                                        // let lootbox_prices = [29,99,299,499,999];
                                        // let lootbox_qtys = [1000000,1000000,1000000,1000000,1000000];
                                        // let lootbox_names = [
                                        //     "Super Saver",
                                        //     "Stack On Stacks",
                                        //     "Vault Overflow",
                                        //     "Treasure Chest",
                                        //     "Legendary"
                                        // ];
                                        // let discounts = [
                                        //     20,
                                        //     30,
                                        //     40,
                                        //     50,
                                        //     60
                                        // ];

                                        let items = [];

                                        if (shared_info != void 0 && shared_info.lootboxes && shared_info.lootboxes.length > 0) {
                                            for (let i = 0; i < 9; i++) {

                                                let curI = i;

                                                if (i < 2 || i > 6) {
                            
                                                    items.push(<div className="empty_carousel_item" style={{marginRight: i==8?'0px':'20px'}}></div>);
                                                    continue;
                                                }

                                                items.push(
                                                    <div className="carousel_item"
                                                        onClick={() => {setCurrentIndex(curI-2);}}
                                                        // onTouchEnd={(e)=>{
                                                        //     e.preventDefault();
                                                        //     setCurrentIndex(curI-2);
                                                        // }}
                                                        ref={addToCarouselItems}>
                                                        <div className="carousel_title">{shared_info.lootboxes[i-2].name}</div>
                                                        {/* <Title_Text text={shared_info.lootboxes[i-2].name} text_color={"var(--title_green)"} outline_color={"black"} override_class={"carousel_title"}/> */}
                                                        <div className="carousel_discount"><b>{shared_info.lootboxes[i-2].discount}% OFF!</b></div>
                                                        <img src={blue_swirl_background} className="carousel_img_background"/>
                                                        <img src={lootbox_imgs[i-2]} className="carousel_img"/>
                                                        <div className="price_indicator">
                                                            
                                                            <div className="inner_dollar_price"><b>${shared_info.lootboxes[i-2].usd_price}</b></div>
                                                            <div className="inner_apemax_qty">{short_format_number(shared_info.lootboxes[i-2].quantity)} ApeMax</div>
                                                        </div>
                                                        <div className="carousel_buttons">
                                                            <div className="carousel_button carousel_button_extra"
                                                                onClick={()=>open_purchase_funnel(false)}
                                                                onTouchEnd={(e)=>{
                                                                    if (validate_touch(e)) {
                                                                        e.preventDefault();
                                                                        open_purchase_funnel(false)
                                                                    }
                                                                }}><b>Buy with Crypto</b></div>
                                                            <div className="carousel_button limited_offer_button"
                                                            >Limited Time Offer</div>
                                                            
                                                            {/* <div className="carousel_button carousel_button_deselected"
                                                                onClick={()=>open_purchase_funnel(true)}
                                                                onTouchEnd={(e)=>{
                                                                    if (validate_touch(e)) {
                                                                        e.preventDefault();
                                                                        open_purchase_funnel(true);
                                                                    }
                                                                }}><b>Buy with Credit Card</b><div className="coming_soon_extra_text">Coming Soon</div></div> */}

                                                            <div className="carousel_button_cover"></div>
                                                        </div>
                                                        <div className="carousel_cover"></div>
                                                    </div>
                                                );
                                            }
                                        }
                                        return items
                                    })()}
                                </div>
                                
                            </div>
                            <div className="fade_carousel_left"></div>
                            <div className="fade_carousel_right"></div>
                            <div className="carousel_go_decrement" onClick={decrement_carousel}
                                onTouchEnd={(e)=>{
                                    if (validate_touch(e)) {
                                        e.preventDefault();
                                        decrement_carousel();
                                    }
                                }}
                                ref={carousel_go_decrement}>←</div>
                            <div className="carousel_go_increment" onClick={increment_carousel}
                                onTouchEnd={(e)=>{
                                    if (validate_touch(e)) {
                                        e.preventDefault();
                                        increment_carousel();
                                    }
                                }}
                                ref={carousel_go_increment}>→</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Loot_Boxes;