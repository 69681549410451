import { useEffect } from 'react';
import { attribute_purchase } from "../Services/api.jsx";

const UseTrackUser = () => {
  useEffect(() => {
    const url = new URL(window.location.href);
    const params = url.searchParams;

    // UTM parameters to track
    let existing_data = JSON.parse(localStorage.getItem('user_tracking_data')) || {
      utm_params: [],
      referrer: [],
      ref_timestamp: []
    };

    function isArray(o) {
      return Object.prototype.toString.call(o) === '[object Array]'; 
    }

    if (!isArray(existing_data.ref_timestamp)) {
      existing_data = {
        utm_params: [],
        referrer: [],
        ref_timestamp: []
      };
    }

    console.log(existing_data);

    let ref_param = "";
    if (params.get("ref")) {
      ref_param = params.get("ref");
      existing_data.utm_params.push(ref_param);
    }

    if (document.referrer && document.referrer != "") {
      existing_data.referrer.push(document.referrer);
    }

    let temp = [];
    for (let i = 0; i < existing_data.utm_params.length; i++) {
      if (existing_data.utm_params[i] != "") {
        temp.push(existing_data.utm_params[i])
      }
    }

    existing_data.utm_params = Array.from(new Set(temp));
    temp = [];

    for (let i = 0; i < existing_data.referrer.length; i++) {
      if (existing_data.referrer[i] != "") {
        temp.push(existing_data.referrer[i])
      }
    }
    existing_data.referrer = Array.from(new Set(temp));

    existing_data.ref_timestamp.push(Math.floor(Date.now() / 1000));
    let num_stamps = existing_data.ref_timestamp.length;
    if (num_stamps > 3) {
      existing_data.ref_timestamp = [
        existing_data.ref_timestamp[num_stamps-3],
        existing_data.ref_timestamp[num_stamps-2],
        existing_data.ref_timestamp[num_stamps-1]
      ]
    }

    // const utm_parameters = ['ref'];
    
    // let tracking_data = {
    //   utm_params: [],
    //   referrer: document.referrer,
    //   ref_timestamp: Math.floor(Date.now() / 1000)
    // };

    // Get UTM parameters
    // utm_parameters.forEach(param => {
    //   if (params.get(param)) {
    //     tracking_data.utm_params[param] = params.get(param);
    //   }
    // });

    // if (params.get(param)) {
    //     tracking_data.utm_params = params.get(param);
    // }

    // // Get existing data from local storage
    // let existing_data = JSON.parse(localStorage.getItem('user_tracking_data')) || [];

    // // Save new data
    // existing_data.push(tracking_data);


    localStorage.setItem('user_tracking_data', JSON.stringify(existing_data));
  }, []);

  // Function to retrieve data
  const get_tracking_data = () => {
    return JSON.parse(localStorage.getItem('user_tracking_data')) || {};
  };

  const attribute_sale = async (

    purchase_id,
    usd_value,
    currency_index,
    is_lootbox,
    purchase_timestamp,

    client_user_agent,
    fbc,
    fbp,
    twclid,

    agreed_tos,
    hash

  ) => {
    try {

        let { utm_params, referrer, ref_timestamp } = JSON.parse(localStorage.getItem('user_tracking_data'));


        await attribute_purchase(
            utm_params,
            referrer,
            ref_timestamp,
        
            purchase_id,
            usd_value,
            currency_index,
            is_lootbox,
            purchase_timestamp,
        
            client_user_agent,
            fbc,
            fbp,
            twclid,
            
            agreed_tos,
            hash
        );

    } catch (error) {}
  }

  return { get_tracking_data, attribute_sale };
};

export default UseTrackUser;
