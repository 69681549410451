import { useState, useRef, useEffect } from 'react';
import { get_img } from "../Services/images.jsx";
import { validate_touch } from "../Services/helper_functions.jsx";


import Title_Text from "./title_text.jsx";

import { useSwipeable } from 'react-swipeable';

let key_overhang = get_img("key_overhang");
let blue_swirl_background = get_img("blue_swirl_background");
let battle_unicorn_riding_rainbow_in_space = get_img("battle_unicorn_riding_rainbow_in_space");

let roadmap_imgs = [];

for (let i = 0; i < 5; i++) {
    roadmap_imgs.push(get_img(`key_features_${i}`))
}


function Key_Features({launch_purchase_funnel}) {

    const [currentIndex, setCurrentIndex] = useState(2);

    function open_purchase_funnel(credit_card=false) {
        launch_purchase_funnel(0,credit_card,false);
    }

    function go_to_how_to_buy() {
        const element = document.querySelector('.how_to_buy');
        element.scrollIntoView({ behavior: 'smooth' });
    }


    const swipe_handlers = useSwipeable({
        onSwipedLeft: () => {
            increment_carousel();
        },
        onSwipedRight: () => {
            decrement_carousel();
        },
        preventDefaultTouchmoveEvent: true,
        trackMouse: false
    });

    const carousel_container = useRef(null);
    useEffect(() => {
        if (carousel_container.current) {

            carousel_container.current.scroll({
            left: (2510-carousel_container.current.offsetWidth)/2 + 282*(currentIndex-2),
            behavior: 'smooth'
            });

            set_carousel_views(currentIndex);
        }
    },[currentIndex]);

    const [carouselItems, setCarouselItems] = useState([]);

    useEffect(() => {
        if (carouselItems.length > 0) {
            set_carousel_views(currentIndex);
        }
    }, [currentIndex, carouselItems]);

    const addToCarouselItems = (el) => {
        if (el && !carouselItems.includes(el)) {
            setCarouselItems(prevItems => [...prevItems, el]);
        }
    };

    let carousel_go_decrement = useRef();
    let carousel_go_increment = useRef();

    function set_carousel_views(curIndex) {

        

        carousel_go_decrement.current.style.opacity = curIndex == 0 ? '0.25' : '1';
        carousel_go_increment.current.style.opacity = curIndex == 4 ? '0.25' : '1';


        for (let i = 0; i < carouselItems.length; i++) {
            let item = carouselItems[i];

            if (i == curIndex) {
                item.className = 'carousel_item carousel_item_full';
            }
            else if (i+1 == curIndex || i-1 == curIndex) {
                item.className = 'carousel_item carousel_item_medium';
            }
            else {
                item.className = 'carousel_item carousel_item_small';
            }
        }
    }

    function increment_carousel() {
        let curIndex = currentIndex;
        curIndex = curIndex < 4 ? curIndex+1 : 4;
        setCurrentIndex(curIndex);
    }

    function decrement_carousel() {
        let curIndex = currentIndex;
        curIndex = curIndex > 0 ? curIndex-1 : 0;
        setCurrentIndex(curIndex);
    }


    useEffect(() => {
        let prev_width = 0;
        window.addEventListener('resize', function() {

            if (prev_width == window.innerWidth) {
                return;
            }

            prev_width = window.innerWidth;

            if (carousel_container.current) {
                setCurrentIndex(currentIndex);
                carousel_container.current.scroll({
                    left: (2510-carousel_container.current.offsetWidth)/2 + 282*(currentIndex-2),
                    behavior: 'smooth'
                });
                set_carousel_views(currentIndex);
            }
        });
    },[]);

    
    return (
        <div className="component" style={{backgroundColor: 'var(--light_blue)'}}>
            

            <div className="inner_component inner_component_sequential">
                <div className="standard_block">
                    <div className="safe_inner">
                        <div className="inner_half_split">
                            <Title_Text text={<>AN AWESOME NEW MEME COIN</>} text_color={"var(--title_blue)"} outline_color={"black"}/>
                            <div className="inner_title">
                            Get ready to dive into the fun world of ApeMax! ApeMax is an awesome and fun new meme coin.<br/><br/>Start your ApeMax journey today!</div>
                            <div className="full_width_button"
                                onClick={()=>window.open("https://apemax.io/uniswap_guide.pdf?v=1")}
                                onTouchEnd={(e)=>{
                                    if (validate_touch(e)) {
                                        e.preventDefault();
                                        window.open("https://apemax.io/uniswap_guide.pdf?v=1")
                                    }
                                }}><b>How to Buy on Uniswap</b></div>
                            <div className="full_width_button"
                                onClick={()=>window.location = "/staking"}
                                onTouchEnd={(e)=>{
                                    if (validate_touch(e)) {
                                        e.preventDefault();
                                        window.location = "/staking"
                                    }
                                }}><b>Staking</b></div>

                            {/* <div className="full_width_button"
                                onClick={()=>open_purchase_funnel(false)}
                                onTouchEnd={(e)=>{
                                    if (validate_touch(e)) {
                                        e.preventDefault();
                                        open_purchase_funnel(false)
                                    }
                                }}><b>Buy with Crypto</b></div>
                            <div className="full_width_button credit_card_dissabled"
                                onClick={()=>open_purchase_funnel(true)}
                                onTouchEnd={(e)=>{
                                    if (validate_touch(e)) {
                                        e.preventDefault();
                                        open_purchase_funnel(true)
                                    }
                                }}
                                ><b>Buy with Credit Card</b><div className="coming_soon_extra_text">Coming Soon</div></div>
                            <img className="all_cryptos_img" src={get_img("all_cryptos")}/>
                            <div className="full_width_more_info"
                                onClick={go_to_how_to_buy}
                                onTouchEnd={(e)=>{
                                    if (validate_touch(e)) {
                                        e.preventDefault();
                                        go_to_how_to_buy();
                                    }
                                }}><u>How to buy?</u></div> */}
                        </div>
                        <div className="inner_half_split">
                        <img src={battle_unicorn_riding_rainbow_in_space} className="full_img_in_split"/>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    );
}

export default Key_Features;