import { useState, useRef, useEffect, useCallback } from 'react';



import Presale_Stage from "../Components/presale_stage.jsx";
import Live_Sales from "../Components/live_sales.jsx";
import Earn_With_Apemax from "../Components/earn_with_apemax.jsx";
import Loot_Boxes from "../Components/loot_boxes.jsx";
import Returns_Calculator from "../Components/returns_calculator.jsx";
import Tokenomics_And_Supply from "../Components/tokenomics_and_supply.jsx";
import Safe_Zone from "../Components/safe_zone.jsx";
import How_To_Buy from "../Components/how_to_buy.jsx";
import Roadmap from "../Components/roadmap_new.jsx";
import Talk_To_Us from "../Components/talk_to_us.jsx";

import Purchase_Funnel from "./purchase_funnel.jsx";
import New_Purchase_Funnel from "./new_purchase_funnel.jsx";
import Blocked_Countries from "./blocked_countries.jsx";
import Key_Features from '../Components/key_features';

import Wrong_Network from "./wrong_network.jsx";

import Title_Text from "../Components/title_text.jsx";

import { get_img } from "../Services/images.jsx";

import { check_if_location_blocked } from "../Services/location.jsx";

import { get_info, total_sales, project_ranking, get_usd_sales } from '../Services/api';

import { track_event, preload_img, validate_touch } from "../Services/helper_functions.jsx";

import { uuid } from 'uuidv4';

import Transaction_Tracker from "./transaction_tracker.jsx";

import Connect_Wallet from "./connect_wallet.jsx";

import Discalimer from "../Components/disclaimer.jsx";

import { useSwipeable } from 'react-swipeable';

import Profile_Details from "./profile.jsx";

import { useAccount } from 'wagmi';

import external_links from "../Config/external_links.json";

import { get_global } from '../Services/web3_functions.jsx';

import Server_Outage from "./server_outage.jsx"


let apemax_rocket = get_img('apemax_rocket')
let ape_and_charts = get_img('ape_and_charts')
let mobile_logo = get_img('mobile_logo');

function Home() {

    const { isConnected } = useAccount();

    // -- PURCHASING

    // function open_purchase_funnel() {
    //     document.querySelector('.purchase_funnel').style.display = 'block';
    // }

    const [shared_info, set_shared_info] = useState();
    const [selected_lootbox_index, set_selected_lootbox_index] = useState(2);
    const [is_lootbox_selected, set_is_lootbox_selected] = useState(false);
    const [is_credit_card_selected, set_is_credit_card_selected] = useState(false);
    const [s,ss] = useState(false);
    const [should_block, set_should_block] = useState(false);

    const [global_currency_index, set_global_currency_index] = useState(0);

    const [tracking_hash, set_tracking_hash] = useState("0x0000000");

    const has_mounted = useRef(false);

    useEffect(() => {

        (async function(){
            if (has_mounted.current == false) {

                has_mounted.current = true;

                


                // Get data

                Promise.all([
                    get_info(),
                    // total_sales(),
                    project_ranking(),
                    get_usd_sales(),
                    get_global(),

                ]).then(([info, ranking, usd_sales, global]) => {

      
                    let { base_price, eth_price, lootboxes, next_price_increase, server_time, exchange_rate, polygon_exchange_rate, bnb_exchange_rate } = info;
                
                    set_shared_info({
                        base_price,
                        eth_price,
                        lootboxes,
                        next_price_increase,
                        server_time,
                        exchange_rate,
                        polygon_exchange_rate,
                        bnb_exchange_rate,
                        // sales_breakdown,
                        usd_sales,
                        ranking,
                        total_minted: Number(global.total_minted) / 10**18
                    });
                }).catch(error => {
                    console.error('Error in fetching data:', error);
                });

                check_if_location_blocked().then((is_blocked) => {
                    if (is_blocked) {
                        set_should_block(true);
                    }
                }).catch(errpr => {});

                nav_to_crumb(0);
                
                if (window.innerWidth < 767) {
                    await Promise.all(mobile_banners.map(img => preload_img(img)));
                }
                else {
                    await Promise.all(banner_imgs.map(img => preload_img(img)));
                }
                
                

                // // Nav banners
                // let next_crumb = 0;
                // let interval = setInterval(function() {
                //     next_crumb++;
                //     next_crumb = next_crumb == banner_imgs.length ? 0 : next_crumb;
                //     nav_to_crumb(next_crumb,true);
                // },3000);
                // set_auto_nav_timer(interval);
                

                // 
                

            }
        })();

    },[]);


    const swipe_handlers = useSwipeable({
        // onSwipedLeft: () => {
        //     let next_crumb = crumb_index;
        //     next_crumb++;
        //     next_crumb = next_crumb == banner_imgs.length ? 0 : next_crumb;
        //     nav_to_crumb(next_crumb);
        // },
        // onSwipedRight: () => {
        //     let next_crumb = crumb_index;
        //     next_crumb--;
        //     next_crumb = next_crumb == -1 ? banner_imgs.length-1 : next_crumb;
        //     nav_to_crumb(next_crumb);
        // },
        preventDefaultTouchmoveEvent: true,
        trackMouse: true
    });
    
    const [purchase_id, set_purchase_id] = useState();

    function launch_purchase_funnel(lootbox_index=0,is_credit_card=false,is_lootbox=false) {

        if (should_block) {
            alert("ApeMax is currently unavailable for purchase in your country or region");;
            return;
        }

        set_is_lootbox_selected(is_lootbox);
        set_selected_lootbox_index(lootbox_index);
        set_is_credit_card_selected(is_credit_card);
        ss(true);

        let new_id = uuid();
        set_purchase_id(new_id);
        track_event(new_id, 0, 0);

        document.querySelector('body').style.overflow = 'hidden'
        document.querySelector('.purchase_funnel').style.display = 'block';
    }
    
/*
    function launch_purchase_funnel(lootbox_index=0,is_credit_card=false,is_lootbox=false) {

        set_is_lootbox_selected(is_lootbox);
        set_selected_lootbox_index(lootbox_index);

        let new_id = uuid();
        set_purchase_id(new_id);
        track_event(new_id, 0, 0);

        document.querySelector('body').style.overflow = 'hidden'
        document.querySelector('.new_purchase_funnel').style.display = 'block';
    }
*/

    function open_profile() {
        document.querySelector('body').style.overflow = 'hidden';
        document.querySelector('.profile_pop_up').style.display = 'block';
    }

    // -- CONNECTION BASIC
    function connect_wallet() {
        if (!isConnected) {
            document.querySelector('body').style.overflow = 'hidden';
            document.querySelector('.connect_wallet').style.display = 'block';
        }
        else {
            document.querySelector('body').style.overflow = 'hidden';
            document.querySelector('.profile_pop_up').style.display = 'block';
        }
    }
    
    
/*


    let languages = ["EN","FR","ES","CN","EN","FR","ES","CN","EN","FR","ES","CN"];
    let emojis = ["🇬🇧","🇫🇷","🇪🇸","🇨🇳","🇬🇧","🇫🇷","🇪🇸","🇨🇳","🇬🇧","🇫🇷","🇪🇸","🇨🇳"]
    
    const [language_vis_0, set_language_vis_0] = useState(false);
    const [language_vis_1, set_language_vis_1] = useState(false);


    useEffect(() => {
        document.querySelector('.language_drop_down').style.display = language_vis_0 ? 'block':'none';
    },[language_vis_0]);

    useEffect(() => {
        document.querySelector('.footer_language_drop_down').style.display = language_vis_1 ? 'block':'none';
    },[language_vis_1]);

    function show_language_0() {
        set_language_vis_0(!language_vis_0);
    }

    function show_language_1() {
        set_language_vis_1(!language_vis_1);
    }

    const changeLanguage = (languageCode) => {

        // languageCode = languageCode.toLowerCase();

        // window.googleTranslateElementInit();
        // if (window.google && window.google.translate && window.google.translate.TranslateElement) {
        //     const element = document.getElementById('google_translate_element');
        //     console.log(element)
        //     const instance = element.querySelector(':scope > .goog-te-gadget');
        //     if (instance) {
        //       const selectElement = instance.querySelector('select.goog-te-combo');
        //       console.log(selectElement)
        //       if (selectElement) {
        //         selectElement.value = languageCode;
        //         selectElement.dispatchEvent(new Event('change'));
        //       }
        //     }
        //   }
    };
    
    function set_langage(index) {
        set_language_vis_0(false);
        set_language_vis_1(false);
        changeLanguage(languages[index]);
        document.querySelectorAll('.language_selector')[0].innerHTML = `<b>${emojis[index]} ${languages[index]} ▼</b>`
        document.querySelectorAll('.language_selector')[1].innerHTML = `<b>${emojis[index]} ${languages[index]} ▲</b>`
    }

    
    const ignoreClasses = ['language_selector', 'footer_language_drop_down', 'language_drop_down'];

    const closeLanguages = useCallback((event) => {
        let target = event.target;
        let ignoreClick = false;

        while (target !== null) {
        if (ignoreClasses.some(className => target.classList.contains(className))) {
            ignoreClick = true;
            break;
        }
        target = target.parentElement;
        }

        if (!ignoreClick) {
            set_language_vis_0(false);
            set_language_vis_1(false);
        }
    }, []);
*/
    const closeLanguages = useCallback((event) => {});


    // Handle breadcrumbs
    let [crumb_index, set_crumb_index] = useState(0);
    let [auto_nav_timer, set_auto_nav_timer] = useState();
    let banner_image_ref = useRef();
    let banner_imgs = [
        get_img('banner_desktop_0',''),
        // get_img('banner_desktop_1',''),
        // get_img('banner_desktop_2',''),
        // get_img('banner_desktop_3','')
    ]
    let mobile_banners = [
        get_img('banner_mobile_0'),
        // get_img('banner_mobile_1'),
        // get_img('banner_mobile_2'),
        // get_img('banner_mobile_3'),
    ]

    

    function nav_to_crumb(index, auto=false) {

        if (!auto) {
            clearInterval(auto_nav_timer);
        }

        let crumbs = document.querySelectorAll('.bread_crumb');

        for (let i = 0; i < banner_imgs.length; i++) {
            if (i==index) {
                crumbs[i].className = 'bread_crumb bread_crumb_selected'
            }
            else {
                crumbs[i].className = 'bread_crumb'
            }
        }
        set_crumb_index(index)

        banner_image_ref.current.className = 'inner_img_block';
        setTimeout(function() {
            document.documentElement.style.setProperty('--banner_desktop', `url('${banner_imgs[index]}')`);
            document.documentElement.style.setProperty('--banner_mobile', `url('${mobile_banners[index]}')`);

            setTimeout(function() {
                banner_image_ref.current.className = 'inner_img_block inner_img_block_visible';
            },0);
        },500);
    }

    

    function go_to_home() {
        window.location = "/";
    }

    
    return (
        <div onClick={closeLanguages}>
            <div className="component" style={{backgroundColor: 'var(--light_blue)'}}>
                <div className="white_header">
                    <div className='additional_banner' style={{width: "100%", height: "50px", backgroundColor: "white", textAlign: "center", lineHeight: "50px"}}> <b>🚀 Live On Uniswap 🎉</b></div>
                </div>
                
                <div className="main_header">
                    
                    <img src={apemax_rocket} onClick={go_to_home} className="logo"/>
                    <Title_Text text={<>APEMAX</>} text_color={"var(--title_blue)"} outline_color={"black"} override_class={"text_logo"} on_click={go_to_home}/>
                    {/* <div className="text_logo" onClick={go_to_home}>APEMAX</div> */}
                    {/* <div className={isConnected?"credit_card_button_header":"credit_card_button_header credit_card_header"} style={{boxShadow: `var(--micro_shadow)`}}
                    
                    onClick={()=>{
                        isConnected ?
                        open_profile() :
                        launch_purchase_funnel(0,true,false)
                    }}

                    onTouchEnd={(e)=>{
                        if (validate_touch(e)) {
                            e.preventDefault();
                            isConnected ?
                            open_profile() :
                            launch_purchase_funnel(0,true,false)
                        }
                    }}
                    
                    
                    >{
                        isConnected ?
                        <b>My Profile</b> :
                        <><b>Buy with Credit Card</b><div className="coming_soon_extra_text coming_soon_extra_text_header">Coming Soon</div></>
                    }</div> */}

                    <div className="credit_card_button_header" style={{boxShadow: `var(--micro_shadow)`}}
                        onClick={connect_wallet}
                        onTouchEnd={(e)=>{
                            if (validate_touch(e)) {
                                e.preventDefault();
                                connect_wallet();
                            }
                        }}
                    ><b>{isConnected ? "My Profile" : "Connect Wallet"}</b></div>

                    <div className="crypto_button_header" style={{boxShadow: `var(--micro_shadow)`}}

                    onClick={()=>window.open(external_links.uniswap_guide)}

                    onTouchEnd={(e)=>{
                        if (validate_touch(e)) {
                            e.preventDefault();
                            window.open(external_links.uniswap_guide);
                        }
                    }}
                    
                    
                    ><b>Trade on Uniswap</b></div>
                    {/* <div className="language_selector" onClick={show_language_0} style={{boxShadow: `var(--micro_shadow)`}}><b>🇬🇧 EN ▼</b></div>
                    <div className="language_drop_down">{(function(){
                        // FILL DEM LANGUAGES;
                        let items = [];
                        
                        for (let i = 0; i < languages.length; i++) {
                            let curI = i;
                            items.push(<div className="language_item" onClick={() => {set_langage(curI)}}>
                                {emojis[i]} {languages[i]}
                            </div>)
                        }
                        return items;
                    })()}</div> */}
                </div>
                
                
                <div className="inner_component">
                    <div className="img_block" {...swipe_handlers}>
                        <div className="inner_img_block inner_img_block_visible" ref={banner_image_ref}/>
                        <div className="bread_crumb_container" style={{display:"none"}}>
                            {(function(){
                                let crumbs = [];
                                for (let i = 0; i < banner_imgs.length; i++) {

                                    let curI = i;

                                    if (i == crumb_index) {
                                        crumbs.push(
                                            <div className="bread_crumb bread_crumb_selected"
                                            onClick={()=>{nav_to_crumb(curI)}}
                                            onTouchEnd={(e)=>{
                                                if (validate_touch(e)) {
                                                    e.preventDefault();
                                                    nav_to_crumb(curI)
                                                }
                                            }}
                                            ></div>
                                        )
                                    }
                                    else {
                                        crumbs.push(
                                            <div className="bread_crumb"
                                            onClick={()=>{nav_to_crumb(curI)}}
                                            onTouchEnd={(e)=>{
                                                if (validate_touch(e)) {
                                                    e.preventDefault();
                                                    nav_to_crumb(curI)
                                                }
                                            }}
                                            ></div>
                                        )
                                    }
                                    
                                }
                                return crumbs;
                            })()}
                        </div>
                    </div>
                </div>
                {/* <div className="inner_component inner_component_sequential">
                    <div className="standard_block">
                        <div className="safe_inner">
                            <div className="inner_half_split">
                                <Title_Text text={<>WHAT IS APEMAX?</>} text_color={"var(--title_blue)"} outline_color={"black"} override_class={"title_text nerf_top_margin"}/>
                                <div className="inner_title"><b>ApeMax is a revolutionary token that lets you earn passively.</b><br/><br/>Stake on your favorite influencers, crypto projects, or charities, and ApeMax turns your support into hefty rewards for you.<br/><br/>Join the growing ApeMax community and stake your way to the moon!<br/><br/></div>
                            </div>
                            <div className="inner_half_split">
                            <img src={ape_and_charts} className="full_img_in_split"/>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>


            {/* <Presale_Stage launch_purchase_funnel={launch_purchase_funnel} shared_info={shared_info}/> */}
            {/* <Live_Sales launch_purchase_funnel={launch_purchase_funnel} shared_info={shared_info}/> */}
            
            <Key_Features launch_purchase_funnel={launch_purchase_funnel} />
            {/* <Earn_With_Apemax launch_purchase_funnel={launch_purchase_funnel} shared_info={shared_info}/> */}
            {/* <Loot_Boxes launch_purchase_funnel={launch_purchase_funnel} shared_info={shared_info}/> */}

            {/* <Returns_Calculator launch_purchase_funnel={launch_purchase_funnel} shared_info={shared_info}/> */}
            <Tokenomics_And_Supply/>
            <Safe_Zone launch_purchase_funnel={launch_purchase_funnel}/>
            {/* <How_To_Buy launch_purchase_funnel={launch_purchase_funnel}/> */}
            <Roadmap/>




            <Talk_To_Us/>


            





            



            


            <div className="component" style={{backgroundColor: 'white', overflow: 'visible'}}>
                <div className="main_footer">
                    <img src={apemax_rocket} className="footer_logo"/>
                    <Title_Text text={"APEMAX"} text_color={"var(--title_blue)"} outline_color={"black"} override_class={"footer_text_logo"}/>
                    {/* <div className="footer_text_logo">APEMAX</div> */}
                    <div className="credit_card_button_header card_footer button_footer"
                        onClick={()=>window.open(external_links.uniswap)}
                        onTouchEnd={(e)=>{
                            if (validate_touch(e)) {
                                e.preventDefault();
                                window.open(external_links.uniswap);
                            }
                        }}
                    ><b>Sell ApeMax</b>
                    {/* <div className="coming_soon_extra_text">Coming Soon</div> */}
                    </div>
                    <div className="crypto_button_header crypto_footer button_footer"
                        onClick={()=>window.open(external_links.uniswap)}
                        onTouchEnd={(e)=>{
                            if (validate_touch(e)) {
                                e.preventDefault();
                                window.open(external_links.uniswap);
                            }
                        }}
                    ><b>Buy ApeMax</b>
                    {/* <div className="coming_soon_extra_text">Coming Soon</div> */}
                    </div>
                    {/* <div className="language_selector language_selector_footer" onClick={show_language_1}><b>🇬🇧 EN ▲</b></div>
                    <div className="footer_language_drop_down">{(function(){
                        // FILL DEM LANGUAGES;
                        let items = [];
                        
                        for (let i = 0; i < languages.length; i++) {
                            let curI = i;
                            items.push(<div className="language_item" onClick={() => {set_langage(curI)}}>
                                {emojis[i]} {languages[i]}
                            </div>)
                        }
                        return items;
                    })()}</div> */}

                    <div className="footer_options_container">
                        <div className="about_apemax_footer">
                            <b className="footer_small_title">About</b>
                            <br/><u className="clickable_text" onClick={()=>{window.open(external_links.whitepaper)}}>Tokenomics</u>
                            <br/><u className="clickable_text" onClick={()=>{window.open(external_links.guides)}}>Guides</u>
                        </div>
                        <div className="documents_footer">
                            <b className="footer_small_title">Docs</b>
                            <br/><u className="clickable_text" onClick={()=>{window.open(external_links.whitepaper)}}>Whitepaper</u>
                            <br/><u className="clickable_text" onClick={()=>{window.open(external_links.audit)}}>Audit Report</u>
                        </div>
                        <div className="terms_footer">
                            <b className="footer_small_title">Terms</b>
                            <br/><u className="clickable_text" onClick={()=>{window.open(external_links.terms_of_use)}}>Terms of Use</u>
                            <br/><u className="clickable_text" onClick={()=>{window.open(external_links.privacy_policy)}}>Privacy Policy</u>
                            <br/><u className="clickable_text" onClick={()=>{window.open(external_links.disclaimer)}}>Disclaimer</u>
                        </div>
                        <div className="social_footer">
                            <b className="footer_small_title">Social</b>
                            <br/><u className="clickable_text" onClick={()=>{window.open(external_links.twitter)}}>Twitter</u>
                            <br/><u className="clickable_text" onClick={()=>{window.open(external_links.discord)}}>Discord</u>
                            <br/><u className="clickable_text" onClick={()=>{window.open(external_links.telegram)}}>Telegram</u>
                        </div>
                    </div>
                    <div className="footer_push"></div>
                    <div className="disclaimer_container">
                        <Discalimer help={false}/>
                    </div>
                    

                </div>
                
            </div>

            <Profile_Details set_hash={set_tracking_hash} set_global_currency_index={set_global_currency_index}/>
            <Transaction_Tracker hash={tracking_hash}/>
            
            <Connect_Wallet/>
            <Purchase_Funnel shared_info={shared_info} init_lootbox_index={selected_lootbox_index} lootboxes={is_lootbox_selected} credit_card={is_credit_card_selected} set_credit_card={set_is_credit_card_selected} purchase_id={purchase_id} s={s} ss={ss} set_global_currency_index={set_global_currency_index}/>
            {/* <Purchase_Funnel lootboxes={true} shared_info={shared_info}/> */}
            
            <New_Purchase_Funnel shared_info={shared_info} purchase_id={purchase_id} init_lootbox_index={selected_lootbox_index} is_lootbox={is_lootbox_selected}/>
            {/* <New_Purchase_Funnel/> */}

            <Wrong_Network global_currency_index={global_currency_index} set_global_currency_index={set_global_currency_index}/>
            <Blocked_Countries/>

            {/* <Server_Outage/> */}
                
        </div>
    )
}

export default Home;