import image_mapping from "../Config/image_mapping.json";

/*
s =   90×  90 = Small Square
b =  160× 160 = Big Square 
t =  160× 160 = Small Thumbnail 
m =  320× 320 = Medium Thumbnail 
l =  640× 640 = Large Thumbnail
h = 1024×1024 = Huge Thumbnail
*/
export const get_img = (name, size="") => {

    // if (name.includes("banner")) {
    //     return `https://apemax.io/Images/${name}.png?v=3`;
    // }

    if (["bunting_overhang","key_features_0","kyc_icons","banner","tokenomics_and_supply"].includes(name)) {
        return `https://apemax.co/Images/${name}.png?v=8`;
    }



    

    return `https://apemax.co/Images/${name}.png?v=1`;

    // let full_img = image_mapping[`${name}.png`].url;
    // let resized_img = full_img.split(".png");
    // resized_img = `${resized_img[0]}${size}.png`
    // return resized_img;
}