import { useState, useEffect, useRef } from 'react';

import { check_if_location_blocked } from "../Services/location.jsx";
import { get_img } from "../Services/images.jsx";

let geo_blocked_icon = get_img("geo_blocked_icon");

function Blocked_Countries() {

    let pop_up_container = useRef();
    let [is_blocked, set_is_blocked] = useState();

    function close_pop_up() {
        set_is_geo_blocked(true);
        pop_up_container.current.style.display = 'none';
        document.querySelector('body').style.overflow = 'auto'
    }

    function exit_website() {
        window.location = "https://www.wikipedia.org/"
    }

    function get_geo_block() {
        const stored_data = JSON.parse(localStorage.getItem('is_geo_blocked'));
        if (stored_data && new Date().getTime() < stored_data.expiration_time) {
          return Boolean(stored_data.value);
        } else {
          localStorage.removeItem('is_geo_blocked');
          return undefined;
        }
    }

    function set_is_geo_blocked(value) {
        const expiration_time = new Date().getTime() + (24 * 60 * 60 * 1000); // 24 hours in milliseconds
        const bool_value = Boolean(value);
        localStorage.setItem('is_geo_blocked', JSON.stringify({ value: bool_value, expiration_time }));
    }

    const has_mounted = useRef(false);

    useEffect(() => {
        /*
        (async function(){
            if (has_mounted.current == false) {
                
                has_mounted.current = true;

                if (is_blocked == void 0 && !get_geo_block()) {
                    let blocked = await check_if_location_blocked();
                    set_is_blocked(blocked);
                    pop_up_container.current.style.display = blocked ? 'block' : 'none';
                    document.querySelector('body').style.overflow = blocked ? 'hidden' : 'auto';
                }
            }
        })();
        */
    },[]);


    return (
        <div className="overlay_container blocked_countries" ref={pop_up_container}>
            <div className="pop_up_container pop_up_container_small">
                
                <div className="pop_up_title_simple"><b>Warning</b></div>
                <img className="pop_up_icon" src={geo_blocked_icon}/>
                <div className="warning_title"><b>Blocked Country</b></div>
                
                <div className="warning_text">It looks like you are trying to access the ApeMax website from a country or region that is blocked. If you are a citizen of the United States, Canada, or on the list of sanctioned countries, you are prohibited from purchasing ApeMax.</div>
                <div className="confirm_button"
                    onClick={close_pop_up}
                    onTouchEnd={(e)=>{
                        e.preventDefault();
                        close_pop_up();
                    }}
                ><b>I am not from a blocked country</b></div>
                <div className="disagree_button"
                    onClick={exit_website}
                    onTouchEnd={(e)=>{
                        e.preventDefault();
                        exit_website();
                    }}
                ><u>Exit Website</u></div>
            </div>
        </div>
    );
}

export default Blocked_Countries;