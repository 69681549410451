import { useState, useRef, useEffect } from 'react';
import Title_Text from "./title_text.jsx";
import { short_format_number, validate_touch } from "../Services/helper_functions.jsx";
import { get_img } from "../Services/images.jsx";

let jackpot_overhang = get_img("jackpot_overhang");
let dot_money_background = get_img("dot_money_background");
let globe_slider = get_img("globe_slider");
let rocket_slider = get_img("rocket_slider");
let moon_slider = get_img("moon_slider");
let indicator_slider = get_img("indicator_slider");
let hand_slider = get_img("hand_slider");
let jetpack_ape = get_img("jetpack_ape");
let coin_overlay = get_img("coin_overlay");


function Returns_Calculator({launch_purchase_funnel, shared_info}) {

    const [is_mobile, set_is_mobile] = useState(false);
    useEffect(() => {
        const user_agent = window.navigator.userAgent;
        const is_mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(user_agent);
        set_is_mobile(is_mobile);
    },[]);

    function open_purchase_funnel(credit_card=false) {
        launch_purchase_funnel(0,credit_card,false);
    }

    function go_to_how_to_buy() {
        const element = document.querySelector('.how_to_buy');
        element.scrollIntoView({ behavior: 'smooth' });
    }

    const containerRef = useRef(null);
    
    function price_to_distance(price) {

        let full_distance = containerRef.current.offsetWidth-100;

        let first_half = full_distance/2 + 7;
        if (price <= 0.001) {
            let min = 0.0001;
            let max = 0.001; 
            let range = max-min;
            let distance = first_half * (price - min) / range;
            return distance;
        }

        let second_half = full_distance/2 - 7;
        let min = 0.001;
        let max = 1;
        let range = max-min;
        let distance = second_half * (price - min) / range; 
        distance += first_half;

        return distance;

    }

    function distance_to_price(distance) {


        let full_distance = containerRef.current.offsetWidth-100;


        let first_half = full_distance/2 + 7;
        if (distance <= first_half) {
            let min = 0.0001;
            let max = 0.001; 
            let range = max-min;
            let price = range * (distance+50) / first_half + min;
            return price
        }

        let second_half = full_distance/2 - 7;
        let min = 0.001;
        let max = 1;
        let range = max-min;
        distance -= first_half;
        let price = range * (distance+50) / second_half + min;
        return price;

    }


    const [position, setPosition] = useState(0);
    const [selectedWidth, setSelectedWidth] = useState(0);
    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState(0);

    function update_values(current_price, init_price=-1) {

        let indicators = [
            'indicator_container_start',
            'indicator_container_launch',
            'indicator_container_moon',
            'indicator_container_current'
        ];

        

        let quantity = parseFloat(document.querySelector(".calculator_input_input").value);

        if (init_price != -1) {
            let prices = [
                0.0001,
                0.001,
                1,
                init_price
            ];

            for (let i = 0; i < indicators.length; i++) {
                let indicator = document.querySelector(`.${indicators[i]}`);
                let indicator_price = indicator.querySelector('.price_indicator_inner');
                let indicator_conversion = indicator.querySelector('.price_conversion_indicator_inner');
                indicator_price.innerHTML = `<b>$${i==3?prices[i].toFixed(6):prices[i]}</b>`;

                indicator_conversion.innerHTML = `$${short_format_number(quantity * prices[i])}`;
            }
        }

        document.querySelector('.calculator_value_converted_inner').innerHTML = `$${short_format_number(quantity * current_price)}`

    }

    useEffect(() => {

        let prev_width;

        function reset() {

            if (window.innerWidth == prev_width) {
                return;
            }
            prev_width = window.innerWidth;

            document.querySelector(".calculator_input_input").value = "1000000";

            let current_price = shared_info.base_price;

            let distance = price_to_distance(current_price);
            setSelectedWidth(distance)
            setPosition(distance-50);
            
            document.querySelector('.bottom_slider_indicator_launch').style.left = `${price_to_distance(0.001)+46}px`;
            document.querySelector('.indicator_container_launch').style.left = `${price_to_distance(0.001)+16}px`;

            document.querySelector('.top_slider_indicator_current').style.left = `${distance+46}px`;
            document.querySelector('.indicator_container_current').style.left = `${distance+16}px`;

            update_values(current_price, current_price);

        }

        if (shared_info != void 0) {
            window.addEventListener('resize', reset);
            reset();
        }
        


    },[shared_info]);
    

    const handleMouseDown = (event) => {
        setIsDragging(true);

        document.querySelector(".hand_slider").style.display = 'none';
        document.querySelector(".rocket_copy").style.display = 'none';

        if (event.touches && event.touches.length > 0) {
            setStartX(event.touches[0].pageX - position);
        }
        else {
            setStartX(event.pageX - position);
        }
        
    };

    function update_qty() {
        let current_price = distance_to_price(position);
        update_values(current_price);
    }

    const handleMouseMove = (event) => {
        if (isDragging) {

            if (event.touches && event.touches.length > 0) {
                event.preventDefault();
            }
    

            let newPosition;

            if (event.touches && event.touches.length > 0) {
                newPosition = event.touches[0].pageX - startX;
            }   
            else {
                newPosition = event.pageX - startX;
            }

             
            newPosition = newPosition < -50 ? -50 : newPosition;
            let maxWidth = containerRef.current.offsetWidth-150;
            newPosition = newPosition > maxWidth ? maxWidth : newPosition;
            setSelectedWidth(newPosition+50)
            setPosition(newPosition);
            let current_price = distance_to_price(newPosition);
            update_values(current_price);
        }
    };

    // const handleMouseUp = () => {
    //     setIsDragging(false);
    // };

    // const handleMouseLeave = () => {
    //     setIsDragging(false);
    // };

    const handleMouseUpOrLeave = () => {
        if (isDragging) {
            setIsDragging(false);
        }
    };


    return (
        <div className="component" style={{backgroundColor: 'var(--light_blue)'}}>
            <div className="inner_component inner_component_with_overhang">
                
                
                <div className="block_with_inner"  style={{backgroundImage: `url(${dot_money_background})`}}>
                    <div className="inner_block">
                        <Title_Text text={<>RETURNS CALCULATOR</>} text_color={"var(--title_blue)"} outline_color={"black"}/>
                        <div className="inner_title">Enter how much ApeMax you own in the textbox below</div>
                        
                        <div className="calclulator_input_container">
                            <div className="calculator_input_label">$ApeMax</div>
                            <input className="calculator_input_input no_spinner" type={"number"} onChange={update_qty} step="100000" min="0"   pattern="\d+(\.\d{1,2})?" inputmode="decimal"/>
                        </div>
                        <div className="calculator_value_converted">
                            <b className="calculator_value_converted_inner">Loading...</b>
                            <img src={coin_overlay} className="coin_overlay_0"/>
                            <img src={coin_overlay} className="coin_overlay_1"/>
                            <img src={coin_overlay} className="coin_overlay_2"/>
                            <img src={coin_overlay} className="coin_overlay_3"/>
                            <img src={coin_overlay} className="coin_overlay_4"/>
                            <img src={coin_overlay} className="coin_overlay_5"/>
                        </div>
                        
                        
                        
                        <div className="inner_title">Move the slider to see how much $APEMAX could be worth at different prices!</div>

                        <div className="calculator_slider_container" ref={containerRef}>
                            <div className="calculator_bar_full"></div>
                            <div className="calculator_bar_selected"  style={{ width: selectedWidth }}></div>
                            <img src={globe_slider} className="globe_slider"/>
                            <img src={moon_slider} className="moon_slider"/>
                            

                            <img src={indicator_slider} className="top_slider_indicator_current"/>
                            <img src={indicator_slider} className="bottom_slider_indicator_start"/>
                            <img src={indicator_slider} className="bottom_slider_indicator_launch"/>
                            <img src={indicator_slider} className="bottom_slider_indicator_moon"/>
                            <div className="indicator_container_start">
                                <div className="price_indicator_inner"><b>$0.0001</b></div>
                                <div className="price_conversion_indicator_inner">($1.1M)</div>
                                <div className="stage_indicator_inner"><b>Start</b></div>
                            </div>
                            <div className="indicator_container_launch">
                                <div className="price_indicator_inner"><b>$0.001</b></div>
                                <div className="price_conversion_indicator_inner">($1.1M)</div>
                                <div className="stage_indicator_inner"><b>Launch</b></div>
                            </div>
                            <div className="indicator_container_moon">
                                <div className="price_indicator_inner"><b>$1</b></div>
                                <div className="price_conversion_indicator_inner">($1.1M)</div>
                                <div className="stage_indicator_inner"><b>The Moon</b></div>
                            </div>
                            <div className="indicator_container_current">
                                <div className="price_indicator_inner"><b>$0.002</b></div>
                                <div className="price_conversion_indicator_inner">($1.1M)</div>
                                <div className="stage_indicator_inner"><b>Current</b></div>
                            </div>

                            <div className="rocket_slider_container"

                                onMouseDown={!is_mobile ? handleMouseDown : null}
                                onMouseMove={!is_mobile ? handleMouseMove : null}
                                onMouseUp={!is_mobile ? handleMouseUpOrLeave : null}
                                onMouseLeave={!is_mobile ? handleMouseUpOrLeave : null}
                                onTouchStart={is_mobile ? handleMouseDown : null}
                                onTouchMove={is_mobile ? handleMouseMove : null}
                                onTouchEnd={is_mobile ? handleMouseUpOrLeave : null}
                                onTouchCancel={is_mobile ? handleMouseUpOrLeave : null}

                                style={{ left: position }}
                            
                            >

                                <img src={rocket_slider} className="rocket_slider"/>
                                <img src={rocket_slider} className="rocket_slider rocket_copy"/>
                                <img src={hand_slider} className="hand_slider"/>

                            </div>

                            

                        </div>

                    </div>
                </div>
                <img src={jackpot_overhang} className="overhang"/>
               
            </div>
            <div className="inner_component inner_component_sequential_extra">
                <div className="standard_block">
                    <div className="safe_inner">
                        <div className="inner_half_split float_right">
                            <Title_Text text={<>WHAT ARE YOU WAITING FOR?</>} text_color={"var(--title_blue)"} outline_color={"black"}/>
                            <div className="full_width_button"
                            onClick={()=>open_purchase_funnel(false)}
                            onTouchEnd={(e)=>{
                                if (validate_touch(e)) {
                                    e.preventDefault();
                                    open_purchase_funnel(false);
                                }
                            }}><b>Buy with Crypto</b></div>
                            <div className="full_width_button credit_card_dissabled"
                                onClick={()=>open_purchase_funnel(true)}
                                onTouchEnd={(e)=>{
                                    if (validate_touch(e)) {
                                        e.preventDefault();
                                        open_purchase_funnel(true);
                                    }
                                }}><b>Buy with Credit Card</b><div className="coming_soon_extra_text">Coming Soon</div></div>
                            <img className="all_cryptos_img" src={get_img("all_cryptos")}/>
                            <div className="full_width_more_info"
                                onClick={go_to_how_to_buy}
                                onTouchEnd={(e)=>{
                                    if (validate_touch(e)) {
                                        e.preventDefault();
                                        go_to_how_to_buy();
                                    }
                                }}
                            ><u>How to buy?</u></div>
                        </div>
                        <div className="inner_half_split">
                            <img src={jetpack_ape} className="full_img_in_split"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Returns_Calculator;