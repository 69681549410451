import { useEffect, useState } from 'react';
import { get_img } from "../Services/images";
let sparkles_inner = get_img("sparkles_inner");
let sparkles_outline = get_img("sparkles_outline");

function getCssVarValue(varName) {
    varName = varName.replace("var(","").replace(")","")
    return getComputedStyle(document.documentElement).getPropertyValue(varName).trim();
}

async function apply_overlay(img_url, color) {
    color = getCssVarValue(color);
    const img = new Image();
    img.crossOrigin = 'anonymous';

    await new Promise((resolve, reject) => {
        img.onload = resolve;
        img.onerror = reject;
        img.src = img_url;
    });

    const canvas = document.createElement('canvas');
    canvas.width = img.width;
    canvas.height = img.height;
    const ctx = canvas.getContext('2d');
    ctx.fillStyle = color;
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    ctx.globalCompositeOperation = 'destination-in';
    ctx.drawImage(img, 0, 0);
  
    return canvas.toDataURL();
}


function Title_Text({text, text_color, outline_color, override_class, on_click}) {

    const [sparkles_inner_overlay, set_sparkles_inner_overlay] = useState(void 0);
    useEffect(() => {
        setTimeout(function() {
            if (window.location.origin != "http://localhost:3000") {
                apply_overlay(sparkles_inner, text_color).then(set_sparkles_inner_overlay);
            }
        }, 2000);
    }, []);

    return (
        <div className={override_class == void 0 ? "title_text": override_class}
            onClick={()=>{if(on_click!=void 0){on_click()}}}
            onTouchEnd={(e)=>{
                e.preventDefault();
                if(on_click!=void 0){on_click();}
            }}
            >

            
            <a className="main_text">
                {text}
                {text != void 0 && sparkles_inner_overlay != void 0 ?
                    <>
                    <div className="inline_sparkles_container_left">
                        <img className="sparkle_left_outline" src={sparkles_inner_overlay}/>
                        <img className="sparkle_left_outline" src={sparkles_outline}/>
                    </div>
                    <div className="inline_sparkles_container_right">
                        <img className="sparkle_right_outline" src={sparkles_inner_overlay}/>
                        <img className="sparkle_right_outline" src={sparkles_outline}/>
                    </div>
                    </>
                    :
                    <></>
                }
                
            </a>

            <a className="title_text_back" style={{color: outline_color}}>{text}</a>
            <a className="title_text_front" style={{color: text_color, WebkitTextStrokeColor: outline_color}}>{text}</a>
        </div>
    )

}

export default Title_Text;