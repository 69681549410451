import external_links from "../Config/external_links.json";

function Discalimer({help=true}) {
    return (
        <div className="discalimer_text">
            <br/>
            DISCLAIMER<br/><br/>
            By purchasing ApeMax, you are hereby agreeing to our <u className="clickable_text" onClick={()=>{window.open(external_links.terms_of_use)}}>Terms of Service</u> and <u className="clickable_text" onClick={()=>{window.open(external_links.privacy_policy)}}>Privacy Policy</u>. You also warrant that you have read in full and understood the <u className="clickable_text" onClick={()=>{window.open(external_links.disclaimer)}}>ApeMax Disclaimer</u> on the risks associated with purchasing cryptocurrencies like ApeMax. By completing a purchase, you are also hereby declaring that you are not a citizen or resident of the United States, Canada, any banned country, or any other country where the purchase of ApeMax may be prohibited by law. A complete list of banned countries is <u className="clickable_text" onClick={()=>{window.open(external_links.blocked_countries)}}>available here</u>. Our list of blocked countries and terms of service may be subject to updates so please make sure to always check the latest version.
            {
            help==true?
            <>
            <br/><br/>
            NEED HELP?<br/><br/>
            If you are having difficulty purchasing, need assistance, or have questions about ApeMax please feel free to reach out to our <u className="clickable_text" onClick={()=>{window.open(external_links.discord)}}>Customer Support Team</u>.
            </>
            :<>
            <br/><br/>
            While you can stake your ApeMax coins and potentially earn more ApeMax coins, ApeMax is not an investment, but is created as a fun way to support projects and creators. We do not guarantee or represent that ApeMax holds any intrinsic value. We do not represent that ApeMax will increase in price after the presale or that it will have any value after the presale. We make no representations or warranties regarding ApeMax, and we are not liable for any losses or errors that may occur during its use. All users should exercise caution and use ApeMax entirely at their own risk.
            <br/><br/>
            Users can stake their ApeMax tokens on various staking contracts. These contracts relate to individuals, organizations, protocols, and other creators that have no formal affiliation with or endorsement from ApeMax. While we strive to verify that the smart contract or wallet address tied to a specific staking contract is genuinely owned and controlled by its named entity, we cannot guarantee its absolute accuracy or authenticity. We in no way represent that the list of entities will be updated or updatable in the future. 
            <br/><br/>
            By using the ApeMax platform and product, you accept it "as is", acknowledging that we may not update, enhance, or maintain it regularly. The services and interface may be unavailable or discontinued at any time. However, the underlying smart contract remains directly accessible on the blockchain, independent of our provided service or interface.
            <br/><br/>
            © 2023 ApeMax. All Rights Reserved.
            </>    
            }
        </div>
    )
}


export default Discalimer;