import { useRef, useEffect, useState } from 'react';

import { useAccount, useConnect } from 'wagmi';
import { useWeb3Modal } from '@web3modal/react';
import { InjectedConnector, fetchBalance } from '@wagmi/core';
import { CoinbaseWalletConnector } from '@wagmi/core/connectors/coinbaseWallet';
import { LedgerConnector } from '@wagmi/connectors/ledger';
import { MetaMaskConnector } from '@wagmi/core/connectors/metaMask';
import { rpc_url  } from '../Config/config';



import { get_img } from "../Services/images.jsx";
import { validate_touch } from "../Services/helper_functions.jsx";

import { mainnet, polygon, bsc, sepolia, polygonMumbai, hardhat } from "wagmi/chains";
const projectId = '3e07e365ebfe5653a62f32020fb507a1';

let cross_logo = get_img("cross_logo");
let apemax_rocket = get_img("apemax_rocket");
let metmask_icon = get_img("metmask_icon");
let walletconnect_icon = get_img("walletconnect_icon");
let coinbase_wallet_icon = get_img("coinbase_wallet_icon");
let ledger_wallet_icon = get_img("ledger_wallet_icon");
let plugin_wallet_icon = get_img("plugin_wallet_icon");
let other_wallets_icon = get_img("other_wallets_icon");




function Connect_Wallet() {

  const { connect, connectors } = useConnect();

    const injected_connector = connectors[1]; // new InjectedConnector();
    const coinbase_connector = new CoinbaseWalletConnector({
        options: {
          appName: 'wagmi.sh',
          jsonRpcUrl: rpc_url,
        },
    });
    const ledger_connector = new LedgerConnector({
      chains: [mainnet, polygon, bsc],
      projectId,
    });
    const metamask_connector = new MetaMaskConnector()



    const purchase_funnel_ref = useRef();

    const web3Modal = useWeb3Modal();
    


    const { isConnected } = useAccount();

    function close_pop_up() {
        document.querySelector('body').style.overflow = 'auto';
        purchase_funnel_ref.current.style.display = 'none';
    }

    useEffect(()=>{
        if (isConnected) {
            close_pop_up();
        }
    },[isConnected]);

    async function handler_injected_provider() {
        if (window.ethereum) {
            await connect({ connector: injected_connector });
        } else {
            await web3Modal.open();
        }
    }

    return (
        <div ref={purchase_funnel_ref} className="overlay_container connect_wallet">
          <div className="pop_up_close_outter" onClick={close_pop_up} onTouchEnd={(e) => {
            if (validate_touch(e)) {
              e.preventDefault();
              close_pop_up();
            }
          }}></div>
      
          <div className="pop_up_container">
            <img className="pop_up_close" src={cross_logo} onClick={close_pop_up} onTouchEnd={(e) => {
              if (validate_touch(e)) {
                e.preventDefault();
                close_pop_up();
              }
            }}/>
            <div className="pop_up_title"><b>Connect Wallet</b></div>
            <div className="pop_up_inner">
              <div className="purchase_qty_container">
                <img className="purchase_stage_icon" src={apemax_rocket}/>
                <div className="purchase_stage_title"><b>Select Wallet Provider</b></div>
                <div className="button_purchase_funnel" onClick={handler_injected_provider} onTouchEnd={(e) => {
                    if (validate_touch(e)) {
                      e.preventDefault();
                      handler_injected_provider();
                    }
                  }}>
                  <img className="purchase_button_icon" src={metmask_icon}/>
                  Metamask
                </div>
                <div className="button_purchase_funnel" onClick={() => web3Modal.open()} onTouchEnd={(e) => {
                  if (validate_touch(e)) {
                    e.preventDefault(); web3Modal.open();
                  }}}>
                  <img className="purchase_button_icon" src={walletconnect_icon}/>
                  Wallet Connect
                </div>
                <div className="button_purchase_funnel" onClick={() => connect({ connector: coinbase_connector })} onTouchEnd={(e) => {
                  if (validate_touch(e)) {
                    e.preventDefault(); connect({ connector: coinbase_connector });
                  }}}>
                  <img className="purchase_button_icon" src={coinbase_wallet_icon}/>
                  Coinbase Wallet
                </div>
                <div className="button_purchase_funnel" onClick={() => connect({ connector: ledger_connector })} onTouchEnd={(e) => {
                  if (validate_touch(e)) {
                    e.preventDefault(); connect({ connector: ledger_connector });
                
                  }}}>
                  <img className="purchase_button_icon" src={ledger_wallet_icon}/>
                  Ledger Wallet
                </div>
                <div className="button_purchase_funnel" onClick={handler_injected_provider} onTouchEnd={(e) => {
                    if (validate_touch(e)) {
                      e.preventDefault(); handler_injected_provider();
                    }}}>
                  <img className="purchase_button_icon" src={plugin_wallet_icon}/>
                  Browser Wallet
                </div>
                <div className="button_purchase_funnel" onClick={() => web3Modal.open()} onTouchEnd={(e) => {
                    if (validate_touch(e)) {
                      e.preventDefault(); web3Modal.open();
                    }}}>
                  <img className="purchase_button_icon" src={other_wallets_icon}/>
                  Most Other Wallets
                </div>
              </div>
            </div>
          </div>
        </div>
      )
      
}

export default Connect_Wallet;