import BigNumber from 'bignumber.js';

function short_format_number(number) {

    if (!number) { return 0; }
    if (number == "") { return 0; }
    if (number == "NaN") { return 0; }

    if (number < 1) {
        return number.toFixed(2);
    }

    const suffixes = ['', 'K', 'M', 'B', 'T'];
    const suffixIndex = Math.floor(Math.log10(number) / 3);

    if (suffixIndex == 0) {
        return number.toFixed(2);
    }

    const shortNumber = number / Math.pow(10, suffixIndex * 3);
    const formattedNumber = shortNumber.toFixed(1);

    return `${formattedNumber}${suffixes[suffixIndex]}`;
}

function shorten_crypto_string(input_string, start_length=6, end_length=4) {
    if (!input_string || typeof input_string !== 'string') {
      throw new Error('Input must be a valid string');
    }
  
    if (input_string.length <= start_length + end_length) {
      return input_string;
    }
  
    const start = input_string.slice(0, start_length);
    const end = input_string.slice(-end_length);
    return `${start}...${end}`;
}

function format_timestamp(timestamp) {
    const date = new Date(timestamp * 1000); // Convert the timestamp to milliseconds
    const hours = date.getHours();
    const minutes = date.getMinutes();
  
    // Format the time with leading zeros if necessary
    const formattedTime = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
    return formattedTime;
  }

function cast_to_bignumber(array) {
    for (let i = 0; i < array.length; i++) {
        array[i] = new BigNumber(array[i]).toFixed();
    }
    return array;
}

function format_timestamp_text(timestamp) {
    if (!timestamp || isNaN(timestamp)) {
      return "0 Seconds";
    }
  
    const secondsInMinute = 60;
    const minutesInHour = 60;
    const hoursInDay = 24;
    const daysInMonth = 30;
    const monthsInYear = 12;
  
    const minutes = timestamp / secondsInMinute;
    const hours = minutes / minutesInHour;
    const days = hours / hoursInDay;
    const months = days / daysInMonth;
    const years = months / monthsInYear;
  
    if (years >= 1) {
      return years.toFixed(2) + " Years";
    } else if (months >= 1) {
      return months.toFixed(2) + " Months";
    } else if (days >= 1) {
      return days.toFixed(2) + " Days";
    } else if (hours >= 1) {
      return hours.toFixed(2) + " Hours";
    } else if (hours >= 1) {
      return minutes.toFixed(2) + " Mins";
    } else {
      return minutes.toFixed(2) + " Seconds";
    }
}

function process_rewards(inputObj, option) {
  const filledData = fill_missing_data(inputObj, option);
  const rewardAmounts = [];
  const timeDescriptions = [];

  const sortedKeys = Object.keys(filledData).sort((a, b) => parseInt(a) - parseInt(b));

  for (const key of sortedKeys) {
    const rewardData = filledData[key];

    rewardAmounts.push(rewardData / 10 ** 18);
    timeDescriptions.push(get_time_description(key, option));
  }

  return [rewardAmounts, timeDescriptions];
}

function fill_missing_data(inputObj, option) {
  const now = new Date();
  const filledData = { ...inputObj };

  if (option === "monthly") {
    for (let i = 0; i < 12; i++) {
      const month = new Date(now.getFullYear(), now.getMonth() - i);
      const monthTimestamp = Math.floor(month.getTime() / 1000);

      if (!filledData.hasOwnProperty(monthTimestamp)) {
        filledData[monthTimestamp] = 0;
      }
    }
  } else if (option === "daily") {
    for (let i = 0; i < 30; i++) {
      const day = new Date(now.getFullYear(), now.getMonth(), now.getDate() - i);
      const dayTimestamp = Math.floor(day.getTime() / 1000);

      if (!filledData.hasOwnProperty(dayTimestamp)) {
        filledData[dayTimestamp] = 0;
      }
    }
  } else {
    for (let i = 0; i < 24; i++) {
      const hour = new Date(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours() - i);
      const hourTimestamp = Math.floor(hour.getTime() / 1000);

      if (!filledData.hasOwnProperty(hourTimestamp)) {
        filledData[hourTimestamp] = 0;
      }
    }
  }

  return filledData;
}
  
  function get_time_description(timestamp, option) {
    const date = new Date(timestamp * 1000);
  
    if (option === "monthly") {
      const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      return monthNames[date.getMonth()];
    } else if (option === "daily") {
      const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      const day = date.getDate();
      const month = monthNames[date.getMonth()];
      return `${day}${get_ordinal_suffix(day)} ${month}`;
    } else {
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      return `${hours}:${minutes}`;
    }
  }
  
  function get_ordinal_suffix(number) {
    const j = number % 10;
    const k = number % 100;
  
    if (j === 1 && k !== 11) {
      return "st";
    }
    if (j === 2 && k !== 12) {
      return "nd";
    }
    if (j === 3 && k !== 13) {
      return "rd";
    }
    return "th";
  }
  
function track_event(purchase_id, event_index, usd_price) {
  // FB
  try {
      window.fbq(
        'track',
        ['AddToCart','InitiateCheckout','Purchase'][event_index], 
        event_index == 0 ?
          {
            content_ids: [purchase_id],
            external_id: purchase_id
          }
        :
          {
              value: usd_price,
              currency: 'USD',
              content_ids: [purchase_id],
              external_id: purchase_id
          }
      );
  } catch (error) {}

  // Twitter
  try {
      window.twq(
        'event',
        ['tw-oera3-oerab','tw-oera3-oera6','tw-oera3-oeraa'][event_index],
        event_index == 0 ?
          {
            conversion_id: purchase_id
          }
        :
          {
            value: usd_price,
            currency: 'USD',
            conversion_id: purchase_id
          }
      );
  } catch (error) {}

  // Google
  try {
      window.gtag(
        'event',
        ['add_to_cart','initiate_checkout','purchase'][event_index],
        event_index == 0 ?
          {
            transaction_id: purchase_id,
            'send_to': 'G-NRZC8RYELE'
          }
        :
          {
            value: usd_price,
            currency: 'USD',
            transaction_id: purchase_id,
            'send_to': 'G-NRZC8RYELE'
          }
      );
  } catch (error) {}

  // try {
  //   window.gtag(
  //     'event',
  //     ['add_to_cart','initiate_checkout','purchase'][event_index],
  //     event_index == 0 ?
  //       {
  //         transaction_id: purchase_id,
  //         'send_to': 'GTM-N4BTD3ZC'
  //       }
  //     :
  //       {
  //         value: usd_price,
  //         currency: 'USD',
  //         transaction_id: purchase_id,
  //         'send_to': 'GTM-N4BTD3ZC'
  //       }
  //   );
  // } catch (error) {}

  // <!-- Google Tag Manager -->
  // <script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  //   new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  //   j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  //   'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  //   })(window,document,'script','dataLayer','GTM-N4BTD3ZC');</script>
  //   <!-- End Google Tag Manager -->

  // <!-- Google Tag Manager (noscript) -->
  // <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-N4BTD3ZC"
  //   height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
  // <!-- End Google Tag Manager (noscript) -->


  // Google 2
  try {
    window.gtag(
      'event',
      'conversion',
      event_index == 0 ?
        {
          transaction_id: purchase_id,
          'send_to': ['AW-11390172334/3d3jCI7o__wYEK75oLcq','AW-11390172334/IqVlCJHo__wYEK75oLcq','AW-11390172334/ku2FCIvo__wYEK75oLcq'][event_index]
        }
      :
        {
          value: usd_price,
          currency: 'USD',
          transaction_id: purchase_id,
          'send_to': ['AW-11390172334/3d3jCI7o__wYEK75oLcq','AW-11390172334/IqVlCJHo__wYEK75oLcq','AW-11390172334/ku2FCIvo__wYEK75oLcq'][event_index]
        }
    );
  } catch (error) {}

  try {
    // document.title = `ApeMax | ${['Add To Cart','Initiate Checkout','Purchase'][event_index]}`;
    
    var newURL = updateURLParameter(window.location.href, 'event', ['add_to_cart','initiate_checkout','purchase'][event_index]);
    window.history.pushState({}, null, newURL);
  
  } catch (error) {}
}


function updateURLParameter(url, param, value) {
  var separator = (url.indexOf('?') > -1) ? '&' : '?';
  var newParam = param + '=' + value;
  var params = new RegExp('([?&])' + param + '[^&]*');
  
  if (url.match(params)) {
    return url.replace(params, '$1' + newParam);
  } else {
    return url + separator + newParam;
  }
}

function preload_img(url) {
  return new Promise((resolve, reject) => {
    const image = new Image();

    image.onload = () => resolve(url);
    image.onerror = () => reject(new Error(`Failed to load image at url: ${url}`));

    image.src = url;
  });
}

function validate_touch(event) {
  const rect = event.target.getBoundingClientRect();
  const touch = event.changedTouches[0];

  const isInsideButton = (
    touch.clientX >= rect.left &&
    touch.clientX <= rect.right &&
    touch.clientY >= rect.top &&
    touch.clientY <= rect.bottom
  );

  return isInsideButton;
}

function is_mobile_device() {
  const userAgent = navigator.userAgent.toLowerCase();
  const mobileKeywords = ['mobile', 'android', 'iphone', 'ipad', 'windows phone'];

  return mobileKeywords.some(keyword => userAgent.includes(keyword));
}

export {
    short_format_number,
    shorten_crypto_string,
    format_timestamp,
    cast_to_bignumber,
    format_timestamp_text,
    process_rewards,
    track_event,
    preload_img,
    validate_touch,
    is_mobile_device
}