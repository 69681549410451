import { useState, useEffect, useRef } from 'react';

import { get_img } from "../Services/images.jsx";

import external_links from "../Config/external_links.json";

let geo_blocked_icon = get_img("geo_blocked_icon");

function Server_Outage() {

    let pop_up_container = useRef();


    function close_pop_up() {
        pop_up_container.current.style.display = 'none';
        document.querySelector('body').style.overflow = 'auto'
    }

    const has_mounted = useRef(false);

    useEffect(() => {

    },[]);


    return (
        <div className="overlay_container blocked_countries" ref={pop_up_container} style={{display: 'block'}}>
            <div className="pop_up_container pop_up_container_small">
                <div className="pop_up_title_simple"><b>Error</b></div>
                <br/>
                <img className="pop_up_icon" src={geo_blocked_icon}/>
                <div className="warning_title"><b>Server Outage</b></div>
                <div className="warning_text">We're currently experiencing a temporary service outage affecting the ApeMax website. We sincerely apologize for any inconvenience this may cause.<br/><br/>Thank you for your patience and understanding.</div>
                <div className="confirm_button"
                    onClick={() => { window.open(external_links.telegram) }}
                    onTouchEnd={(e)=>{
                        e.preventDefault();
                        window.open(external_links.telegram);
                    }}
                ><b>Join Telegram</b></div>
                <br/>
            </div>
        </div>
    );
}

export default Server_Outage;