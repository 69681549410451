import { useState, useEffect, useRef } from 'react';
import { useAccount, useNetwork } from 'wagmi';
import { switchNetwork } from '@wagmi/core';
import { network_id } from "../Config/config.jsx";
import { get_img } from "../Services/images.jsx";
import { validate_touch } from "../Services/helper_functions.jsx";


let three_cryptos = get_img("all_cryptos");


function Wrong_Network({global_currency_index=1,set_global_currency_index=function(){}}) {

    let { chain } = useNetwork();
    let { isConnected } = useAccount();

    const [selected_chain, set_selected_chain] = useState(0);

    const interval_checker = useRef(null);

    function sleep(ms) {
        return new Promise((resolve) => setTimeout(resolve, ms));
    }

    function adjust_network() {

        console.log({global_currency_index})
        if (global_currency_index==0) { return; }
        
        if (isConnected && chain.id != global_currency_index) {
            pop_up_container.current.style.display = 'block';
            document.querySelector('body').style.overflow = 'hidden';
        }
        else {
            pop_up_container.current.style.display = 'none';
            document.querySelector('body').style.overflow = 'auto';
        }
    }

    useEffect(()=> {

        // if (chain) {
        //     console.log("HERE", chain.id);
        // }

        if (isConnected && chain && chain.id && chain.id == global_currency_index) {
            pop_up_container.current.style.display = 'none';
            document.querySelector('body').style.overflow = 'auto';
            set_global_currency_index(0);
        }

    },[chain])

    useEffect(()=> {

        adjust_network();

    },[global_currency_index])

    let pop_up_container = useRef();

    async function switch_network() {
        console.log("PLEASE SWITCH TO CHAIN", global_currency_index, isConnected);
        try {
            await switchNetwork({chainId:global_currency_index});
        } catch (error) {
            console.log(error)
        }      
    }

    function cancel_switch() {
        clearInterval(interval_checker.current);
        pop_up_container.current.style.display = 'none';
        document.querySelector('body').style.overflow = 'auto';
        set_global_currency_index(0);
    }

    return (
        <div className="overlay_container blocked_countries" ref={pop_up_container}>
            <div className="pop_up_container pop_up_container_small">
                <div className="pop_up_title_simple"><b>Switch Network</b></div>
                <img className="pop_up_icon_large" src={three_cryptos}/>
                <div className="warning_title"><b>Switch Network</b></div>
                <div className="warning_text">It looks like you're connected to the wrong blockchain! Please make sure to switch networks to continue</div>
                {/* {   
                !window.ethereum ?
                    <></>
                    : */}
                    <div className="confirm_button"
                        onClick={switch_network}
                        onTouchEnd={(e)=>{
                            if (validate_touch(e)) {
                                e.preventDefault();
                                switch_network();
                            }
                        }}
                        ><b>Switch To {{0:"Loading",1:"Ethereum",137:"Polygon Matic",56:"Binance Smart Chain"}[global_currency_index]}</b></div>
                {/* } */}
                <div className="disagree_button"
                    onClick={cancel_switch}
                    onTouchEnd={(e)=>{
                        if (validate_touch(e)) {
                            e.preventDefault();
                            cancel_switch();
                        }
                    }}
                    ><b>Cancel</b></div>
            </div>
        </div>
    );

}

export default Wrong_Network;