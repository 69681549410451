

import { useState, useRef, useEffect, useCallback } from 'react';
import { uuid } from 'uuidv4';

import Key_Features_Carousel from '../Components/key_features_carousel';
import Loot_Boxes_Carousel from '../Components/loot_boxes_carousel';
import Wrong_Network from "./wrong_network.jsx";
import Purchase_Funnel from "./purchase_funnel.jsx";
import Title_Text from "../Components/title_text.jsx";
import Discalimer from "../Components/disclaimer.jsx";

import external_links from "../Config/external_links.json";


import { track_event, preload_img, validate_touch } from "../Services/helper_functions.jsx";


import { get_info, total_sales, project_ranking, register_email } from '../Services/api';

import { get_img } from "../Services/images.jsx";


let banner_desktop_0 = get_img("banner_desktop_0");
let banner_desktop_2 = get_img("banner_desktop_2");
let banner_desktop_3 = get_img("banner_desktop_3");
let apemax_rocket = get_img('apemax_rocket');
let alarm_clock_overlay = get_img("alarm_clock_overlay");

let discord_icon = get_img("discord_icon");
let twitter_icon = get_img("twitter_icon");
let telegram_icon = get_img("telegram_icon");
let apemax_news_logo = get_img("apemax_news_logo");
let ballons_icon = get_img("ballons_icon");

let article_banners = [];
for (let i = 0; i < 4; i++) {
    let article_banner = get_img(`article_banner_${i}`);
    article_banners.push(article_banner);
}





function News_Page() {

    const has_mounted = useRef(false);
    const [shared_info, set_shared_info] = useState();
    const [selected_lootbox_index, set_selected_lootbox_index] = useState(2);
    const [is_lootbox_selected, set_is_lootbox_selected] = useState(false);
    const [is_credit_card_selected, set_is_credit_card_selected] = useState(false);
    const [s,ss] = useState(false);

    const [global_currency_index, set_global_currency_index] = useState(0);

    const email_input = useRef();
    async function add_email() {
        try {
            await register_email(email_input.current.value);
            alert("Thank you for your submission!.");
        } catch (error) {
            alert("Oh no, it looks like your response is invalid or incomplete.")
        }
    }

    const [time_diffs, set_time_diffs] = useState(['00','00','00','00']);
    const [time_difference, set_time_difference] = useState(0);
    const [countdown_taget, set_countdown_target] = useState(0);

    function get_time_diff() {
        let adjusted_time_now = (Date.now() / 1000) + time_difference;
        let time_diff = countdown_taget - adjusted_time_now;

        if (time_diff < 0) {
            window.location.reload();
        }
    
        let times = ['days', 'hours', 'minutes', 'seconds'];
        let time_units = {'days': 86400, 'hours': 3600, 'minutes': 60, 'seconds': 1};

        let time_diffs = {};
        let temp = [];

        for (let i = 0; i < times.length; i++) {
            let time = times[i];
            time_diffs[time] = Math.floor(time_diff / time_units[time]);
            temp.push(time_diffs[time].toString().padStart(2, '0'))
            time_diff %= time_units[time];
        }

        set_time_diffs(temp)
    }

    useEffect(()=> {
        if (countdown_taget!=0) {
            setInterval(get_time_diff, 1000);
        }

        const script = document.createElement("script");

        script.src = "https://widgets.coingecko.com/coingecko-coin-price-marquee-widget.js";
        script.async = true;

        document.body.appendChild(script);

    },[countdown_taget]);


    useEffect(() => {

        (async function() {
            if (has_mounted.current == false) {

                has_mounted.current = true;

                


                // Get data

                Promise.all([
                    get_info()
                ]).then(([info, sales_breakdown, ranking]) => {
                    let { base_price, eth_price, lootboxes, next_price_increase, server_time, exchange_rate, polygon_exchange_rate, bnb_exchange_rate } = info;
                
                    set_shared_info({
                        base_price,
                        eth_price,
                        lootboxes,
                        next_price_increase,
                        server_time,
                        exchange_rate,
                        polygon_exchange_rate,
                        bnb_exchange_rate,
                        sales_breakdown,
                        ranking
                    });

                    console.log({next_price_increase})
                    set_countdown_target(next_price_increase);
                    server_time = server_time / 1000;
                    let client_time = Date.now() / 1000;
                    set_time_difference(server_time - client_time);

                    
                }).catch(error => {
                    console.error('Error in fetching data:', error);
                });

                
            }
        })();
    },[]);

    const [purchase_id, set_purchase_id] = useState();

    function launch_purchase_funnel(lootbox_index=0,is_credit_card=false,is_lootbox=false) {
        set_is_lootbox_selected(is_lootbox);
        set_selected_lootbox_index(lootbox_index);
        set_is_credit_card_selected(is_credit_card);
        ss(true);

        let new_id = uuid();
        set_purchase_id(new_id);
        track_event(new_id, 0, 0);

        document.querySelector('body').style.overflow = 'hidden'
        document.querySelector('.purchase_funnel').style.display = 'block';
    }
    
    return (
        <div>
            <div className="news_container">
                <img className="apemax_news_logo" src={apemax_news_logo}/>
                <div className="remove_lr_borders">
                <coingecko-coin-price-marquee-widget  coin-ids="bitcoin,ethereum,dogecoin,shiba-inu,pepe,floki" currency="usd" background-color="#ffffff" locale="en" font-color="#000000"></coingecko-coin-price-marquee-widget>
                </div>
                
                <div className="news_safe_inner">
                    <h3>Top Articles</h3>
                </div>
                <div className="other_articles_container">
                    <div className="other_articles_inner">
                    {function() {
                        let articles = [];

                        for (let i = 0; i < article_banners.length; i++) {
                            articles.push(
                                <div onClick={()=>{window.location = `article?index=${i}`}} className="other_article_div" style={i==3?{marginRight:'0px'}:{}}>
                                    <img src={article_banners[i]} className="other_article"/>
                                </div>
                            )
                        }

                        return articles;
                    }()}
                    </div>
                </div>
                <div className="extra_line_spacer"></div>
                <div className="news_safe_inner">
                    <h1>What is ApeMax and why should I care?</h1>
                </div>
                <img className="banner_img" src={banner_desktop_0}/>
                <div className="news_header">
                    <div className="news_icon" style={{backgroundColor: 'var(--medium_red)'}}>News</div>
                    <div className="news_icon" style={{backgroundColor: 'var(--dark_blue)'}}>Guides</div>
                    <div className="social_icon" onClick={()=>{window.open(external_links.twitter)}}>
                        <img className="social_icon_inner" src={twitter_icon} />
                    </div>

                    <div className="social_icon" onClick={()=>{window.open(external_links.discord)}}>
                        <img className="social_icon_inner" src={discord_icon} />
                    </div>

                    <div className="social_icon" onClick={()=>{window.open(external_links.telegram)}}>
                        <img className="social_icon_inner" src={telegram_icon} />
                    </div>
                
                </div>
                
                <div className="news_safe_inner">
                    <p>Over the past few weeks, investors’ interest in crypto presales have skyrocketed. In particular, the recent boom has been fueled by interest in Altcoins and Memecoin token presales which are gaining new fans and buyers at record speed. In this veritable mess of memes, we will discuss in this article how ApeMax towers over the competition and is poised to skyrocket to the moon. You can find more information on the <u className="clickable_text"

                        onClick={()=>{
                            window.location = 'https://apemax.io';
                        }}

                        onTouchEnd={(e)=>{
                            if (validate_touch(e)) {
                                e.preventDefault();
                                window.location = 'https://apemax.io';
                            }
                        }}

                    >Official ApeMax Website</u>.</p>
                    <br/>
                    <h3>What is ApeMax?</h3>
                    <br/>
                    <p>ApeMax is not just another cryptocurrency. It’s a revolutionary token that allows you to earn passively by staking on your favorite influencers, crypto projects, or charities. Your support is transformed into hefty rewards, making ApeMax a crypto with a difference.</p>
                </div>
                <div className="banner_container">
                    <div className="news_safe_inner">
                        <div className="indented_text">
                            <div className="indented_above"><h3 className="indented_title">Don't Miss The Deal of Lifetime</h3></div>
                            <p className="indented_below">ApeMax price increases automatically every 24 hours. The next price increase is in {time_diffs[1]} hours, {time_diffs[2]} minutes, {time_diffs[3]} seconds</p>
                        </div>
                        <img className="left_icon" src={alarm_clock_overlay}/>
                    </div>
                    <div className="inline_full_button"
                    
                    onClick={()=>{
                        launch_purchase_funnel(0,false,false)
                    }}

                    onTouchEnd={(e)=>{
                        if (validate_touch(e)) {
                            e.preventDefault();
                            launch_purchase_funnel(0,false,false);
                        }
                    }}
                    
                    ><b>Buy ApeMax</b></div>
                    <div className="inline_full_button secondary_button"

                    onClick={()=>{
                        window.location = '/';
                    }}

                    onTouchEnd={(e)=>{
                        if (validate_touch(e)) {
                            e.preventDefault();
                            window.location = '/';
                        }
                    }}

                    ><b>Visit ApeMax Website</b></div>

                </div>
                <div className="news_safe_inner">
                    <p>To understand the potential of ApeMax, let’s revisit the story of a crypto investor who saw the potential in Shiba Inu early on. He invested $8,000 into the meme coin, a seemingly insignificant amount at the time. Fast forward a year later, his investment was worth an astronomical $5.7 billion! This story highlights the incredible potential that lies within the crypto market.</p>
                    <br/>
                    <p>So, could ApeMax be the next Shiba Inu? The potential is definitely there. Unlike meme coins, which are often driven by hype and speculation, ApeMax offers real utility. Users can immediately stake on their favorite influencers, projects, and charities, earning more ApeMax coins in the process. This innovative system creates a positive feedback loop.</p>
                    <br/>
                    <p>Imagine being part of the ApeMax community from the start, staking your coins, and seeing your investment grow exponentially as the token gains traction — just like our Shiba Inu investor.</p>
                </div>
                <div className="news_safe_inner">
                    <h3>Key Features</h3><br/>
                    <p>ApeMax is not just a meme coin. It's packed full of features and utility from day one. With such sound fundementals, the sky is the limit for ApeMax.</p>
                    <Key_Features_Carousel launch_purchase_funnel={launch_purchase_funnel} />
                </div>
                <div className="news_safe_inner">
                    <p>ApeMax isn’t just about potential profits. It’s also about creating a new economic model that offers value to its users. By turning support into rewards, ApeMax is not just another cryptocurrency — it’s a revolution. This real utility could make ApeMax a more stable and reliable purchase than many other cryptocurrencies, offering both short-term gains and long-term growth.</p>
                    <br/>
                    <p>The ApeMax pre-sale has gotten off to an amazing start. You now have a unique opportunity to buy in at the lowest prices ever.</p>
                </div>
                <img className="banner_img" src={banner_desktop_3}/>
                <div className="news_safe_inner">
                    <p>One of the key factors contributing to ApeMax's potential for success is its strong and rapidly growing community. With a dedicated and passionate user base, ApeMax has the potential to create a network effect that fuels its growth.</p>
                    <br/>
                    <p>Furthermore, ApeMax has implemented rigorous security measures to ensure the safety of its users' investments. The project has undergone extensive audits by reputable third-party security firms, ensuring that the smart contracts and infrastructure are free from vulnerabilities and potential exploits.</p>
                </div>
                
                <div className="banner_container">
                    <div className="news_safe_inner">
                        <div className="indented_text">
                            <div className="indented_above"><h3 className="indented_title">Get the latest news</h3></div>
                            <p className="indented_below">Never miss any of the ApeMax updates by subscribing to our newsletter! For the best community ApeMax news, you're not going to want to miss a beat.</p>
                        </div>
                        <img className="left_icon" src={ballons_icon}/>
                    </div>
                    {/* <div className="news_safe_inner"> */}
                        <input className="purchase_funnel_input email_input" placeholder='Email address' ref={email_input}/>
                        <div className="submit_email_button">
                            <div className="submit_email_button_label" onClick={async (event)=> {
                                const btn = event.target;
                                btn.style.pointerEvents = "none";
                                btn.style.border = "5px solid var(--light_gray)";
                                btn.style.backgroundColor = "var(--mid_gray)";
                                btn.innerHTML = "Loading..."
                                await add_email();
                                btn.style = "";
                                btn.innerHTML = "Submit"
                            }}
                            onTouchEnd={async (e)=>{
                                if (validate_touch(e)) {
                                    e.preventDefault();

                                    const btn = e.target;
                                    btn.style.pointerEvents = "none";
                                    btn.style.border = "5px solid var(--light_gray)";
                                    btn.style.backgroundColor = "var(--mid_gray)";
                                    btn.innerHTML = "Loading..."
                                    await add_email();
                                    btn.style = "";
                                    btn.innerHTML = "Submit"
                                }
                            }}><b>Submit</b></div>
                        </div>
                    {/* </div> */}
                    <br/><br/>
                </div>

                <div className="news_safe_inner">
                    <h3>Loot Boxes</h3><br/>
                    <p>Looking for the best deal on ApeMax coins? Discover guaranteed savings with our ApeMax Lootbox deals! Each purchase delivers discounted ApeMax tokens, ensuring you get more bang for your buck.</p>
                    <Loot_Boxes_Carousel launch_purchase_funnel={launch_purchase_funnel} shared_info={shared_info}/>
                </div>
                <div className="news_safe_inner">
                    <p>In conclusion, ApeMax represents a unique opportunity for investors to participate in a promising cryptocurrency project that offers both potential profits and real utility. With its innovative staking system and growing community, ApeMax has the potential to stand out in the highly competitive crypto market.</p>
                </div>
                <div className="banner_container">
                    <div className="news_safe_inner">
                        <div className="indented_text">
                            <div className="indented_above"><h3 className="indented_title">Still not convinced?</h3></div>
                            <p className="indented_below">Want to discover the ApeMax universe before apeing in? Find out more about ApeMax on our website or by reading our whitepaper.</p>
                        </div>
                        <img className="left_icon" src={apemax_rocket}/>
                    </div>
                    <div className="inline_full_button"

                        onClick={()=>{
                            window.location = '/';
                        }}

                        onTouchEnd={(e)=>{
                            if (validate_touch(e)) {
                                e.preventDefault();
                                window.location = '/';
                            }
                        }}
                    
                    ><b>Visit ApeMax Website</b></div>
                    <div className="inline_full_button secondary_button"
                        onClick={()=>{
                            window.open(external_links.whitepaper)
                        }}

                        onTouchEnd={(e)=>{
                            if (validate_touch(e)) {
                                e.preventDefault();
                                window.open(external_links.whitepaper)
                            }
                        }}
                    
                    ><b>Read The Whitepaper</b></div>
                </div>
                <br/>
                <img className="banner_img" src={banner_desktop_2}/>
                
                

                <Discalimer help={false}/>
                <br/>
            </div>
            
            <Purchase_Funnel shared_info={shared_info} init_lootbox_index={selected_lootbox_index} lootboxes={is_lootbox_selected} credit_card={is_credit_card_selected} set_credit_card={set_is_credit_card_selected} purchase_id={purchase_id} s={s} ss={ss} set_global_currency_index={set_global_currency_index}/>
            <Wrong_Network global_currency_index={global_currency_index} set_global_currency_index={set_global_currency_index}/>

        </div>
    )
}

export default News_Page;