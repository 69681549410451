const selected_network = "mainnet";
// const selected_network = "sepolia";

export const network_id = {
    sepolia: 11155111,
    mumbai: 80001,
    hardhat: 31337,
    mainnet: 1
}[selected_network];

export const rpc_url = {
    sepolia: `https://proportionate-necessary-daylight.ethereum-sepolia.quiknode.pro/a5dc0ac3d52fdfacb546de05565d1f0e28835bfd/`,
    mumbai: `https://chaotic-maximum-morning.matic-testnet.quiknode.pro/23a98e58860c10e6f06b39f0829a9fbca4d2a777/`,
    hardhat: `http://127.0.0.1:8545`,
    mainnet: `https://old-burned-pine.quiknode.pro/e74f028a836baed99734a4776ca8a297e7398d01/`
}[selected_network];


export const usdt_address = {
    sepolia: "0xB6434EE024892CBD8e3364048a259Ef779542475",
    mumbai: "0x466DD1e48570FAA2E7f69B75139813e4F8EF75c2",
    hardhat: "",
    mainnet: "0xdAC17F958D2ee523a2206206994597C13D831ec7"
}[selected_network];

export const usdc_address = {
    sepolia: "0x6f14C02Fc1F78322cFd7d707aB90f18baD3B54f5",
    mumbai: "0xd33602Ce228aDBc90625e4FC8071aAE0CAd11Fe9",
    hardhat: "",
    mainnet: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48"
}[selected_network];


export const block_explorer = {
    sepolia: "https://sepolia.etherscan.io/tx",
    mumbai: "https://mumbai.polygonscan.com/tx",
    hardhat: "",
    mainnet: "https://etherscan.io/tx"
}[selected_network];

export const stable_coin_decimals = {
    sepolia: 18,
    mumbai: 6,
    hardhat: 18,
    mainnet: 6
}[selected_network];


export const token_address = `0x853B7eA613c9F8e6bcd21985C0911bdaD8422842`;

export const nft_address = `0x37Df094FDf0c99E8729439762A773fCBb6b654a6`;

export const getter_address = `0x2B583085A88c5EEaf1A8DC49C5Ebd8AFb5d559B9`;


// export const api_endpoint = `http://localhost:8080`;
export const api_endpoint = `https://api.apemax.io`;



// export const admin_wallet = `0xfe7474462F0d520B3A41bBE3813dd9aE6B5190B8`;
// export const admin_wallet = `0x9daAFB52Ca3635ad88fC325EE8Dd0A6413c3b2F6`;
// export const admin_wallet = `0xc68303a7f94e7FBcC2096634125e6854887eC013`;
export const admin_wallet = `0xD467392496Bd63D34F782A33ac4c797A9eA832fb`;

export const polygon_rpc_url = `https://intensive-prettiest-lake.matic.quiknode.pro/0c95af79036fd57c0737a2520e39992b82e7a392/`;
export const polygon_nft_address = `0xde798add0f5b2ffb69f55fcb692edcc7dc9e9c0d`;

export const bnb_rpc_url = `https://prettiest-distinguished-meadow.bsc.quiknode.pro/9a9f361d202fe7c7205dc7e20bfcb679776f9d7d/`;
export const busd_address = `0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56`;

export const proxy_staking = "0xc6ecbdc5b6573c26e6fb2a4bc435cef5e2246639"