import { useState, useRef, useEffect } from 'react';
import Title_Text from "./title_text.jsx";
import { useSwipeable } from 'react-swipeable';
import { get_img } from "../Services/images.jsx";
import { validate_touch } from "../Services/helper_functions.jsx";

let money_tree_overhang = get_img("money_tree_overhang");
let green_radial_background = get_img("green_radial_background");

let roadmap_imgs = [];
for (let i = 0; i < 5; i++) {
    roadmap_imgs.push(get_img(`roadmap_${i}`));
}

function Roadmap() {

    const [currentIndex, setCurrentIndex] = useState(2);

    const swipe_handlers = useSwipeable({
        onSwipedLeft: () => {
            increment_carousel();
        },
        onSwipedRight: () => {
            decrement_carousel();
        },
        preventDefaultTouchmoveEvent: true,
        trackMouse: false
    });

    const carousel_container = useRef(null);
    useEffect(() => {
        if (carousel_container.current) {

            carousel_container.current.scroll({
            left: (2510-carousel_container.current.offsetWidth)/2 + 282*(currentIndex-2),
            behavior: 'smooth'
            });

            set_carousel_views(currentIndex);
        }
    },[currentIndex]);

    const [carouselItems, setCarouselItems] = useState([]);

    useEffect(() => {
        if (carouselItems.length > 0) {
            set_carousel_views(currentIndex);
        }
    }, [currentIndex, carouselItems]);

    const addToCarouselItems = (el) => {
        if (el && !carouselItems.includes(el)) {
            setCarouselItems(prevItems => [...prevItems, el]);
        }
    };

    let carousel_go_decrement = useRef();
    let carousel_go_increment = useRef();

    function set_carousel_views(curIndex) {

        

        carousel_go_decrement.current.style.opacity = curIndex == 0 ? '0.25' : '1';
        carousel_go_increment.current.style.opacity = curIndex == 4 ? '0.25' : '1';


        for (let i = 0; i < carouselItems.length; i++) {
            let item = carouselItems[i];

            if (i == curIndex) {
                item.className = 'carousel_item carousel_item_full';
            }
            else if (i+1 == curIndex || i-1 == curIndex) {
                item.className = 'carousel_item carousel_item_medium';
            }
            else {
                item.className = 'carousel_item carousel_item_small';
            }
        }
    }

    function increment_carousel() {
        let curIndex = currentIndex;
        curIndex = curIndex < 4 ? curIndex+1 : 4;
        setCurrentIndex(curIndex);
    }

    function decrement_carousel() {
        let curIndex = currentIndex;
        curIndex = curIndex > 0 ? curIndex-1 : 0;
        setCurrentIndex(curIndex);
    }


    useEffect(() => {
        let prev_width = 0;
        window.addEventListener('resize', function() {

            if (prev_width == window.innerWidth) {
                return;
            }

            prev_width = window.innerWidth;
            
            if (carousel_container.current) {
                setCurrentIndex(currentIndex);
                carousel_container.current.scroll({
                    left: (2510-carousel_container.current.offsetWidth)/2 + 282*(currentIndex-2),
                    behavior: 'smooth'
                });
                set_carousel_views(currentIndex);
            }
        });
    },[]);

    
    return (
        <div className="component" style={{backgroundColor: 'var(--light_green)'}}>
            <div className="inner_component inner_component_with_overhang inner_component_with_overhang_less">
                <img src={money_tree_overhang} className="overhang"/>
                <div className="standard_block">
                    <div className="safe_inner">
                        <Title_Text text={<>ROADMAP</>} text_color={"var(--title_green)"} outline_color={"black"}/>
                        <div className="inner_title">Discover the journey ahead with ApeMax! Get insights into our schedule and future plans.</div>
                        <div className="carousel_super_container" {...swipe_handlers}>
                            <div className="carousel_container" ref={carousel_container}>
                                <div className="carousel_inner_max">
                                    {(function() {
                                        
                                        
                                        
                                        let roadmap_titles = [
                                            "Planning",
                                            "Development",
                                            "Compliance",
                                            "Pre-Sale",
                                            "DEX Launch"
                                        ];

                                        let roadmap_content = [
                                            [
                                                "ApeMax idea is born",
                                                "Tokenomics & features",
                                                "Brand identity design"
                                            ],
                                            [
                                                "Smart contract",
                                                "Staking interface",
                                                "Website & graphics"
                                            ],
                                            [
                                                "Smart contract audit",
                                                "Code published",
                                                "Whitepaper published"
                                            ],
                                            [
                                                "Staking open",
                                                "Socials open",
                                                "Featured in press"
                                            ],
                                            [
                                                "Transfers enabled",
                                                "Trading enabled",
                                                "Uniswap launch"
                                            ],
                                        ]


                                        let items = [];
                                        for (let i = 0; i < 9; i++) {

                                            let curI = i;

                                            if (i < 2 || i > 6) {
                        
                                                items.push(<div className="empty_carousel_item" style={{marginRight: i==8?'0px':'20px'}}></div>);
                                                continue;
                                            }

                                            items.push(
                                                <div className="carousel_item" onClick={() => {setCurrentIndex(curI-2);}} ref={addToCarouselItems}>
                                                    {/* <Title_Text text={roadmap_titles[i-2]} text_color={"var(--title_green)"} outline_color={"black"} override_class={"carousel_title"}/> */}
                                                    <div className="carousel_title carousel_title_high">{roadmap_titles[i-2]}</div>
                                                    <img src={green_radial_background} className="roadmap_img_background"/>
                                                    <img src={roadmap_imgs[i-2]} className="roadmap_img"/>
                                                    <div className="roadmap_status_indicator">
                                                        {
                                                            i-2 <= 4 ?
                                                            <a style={{color: `var(--medium_green)`}}>Completed</a> :
                                                            // i-2 == 3 ?
                                                            <a style={{color: `var(--funky_purple)`}}>Ongoing</a>
                                                            // "Soon"
                                                        }
                                                    </div>

                                                    <div className="roadmap_list">
                                                        <div className="roadmap_list_item">{roadmap_content[i-2][0]}</div>
                                                        <div className="roadmap_list_item">{roadmap_content[i-2][1]}</div>
                                                        <div className="roadmap_list_item">{roadmap_content[i-2][2]}</div>
                                                    </div>
                                                </div>
                                            );
                                        }
                                        return items
                                    })()}
                                </div>
                                
                            </div>
                            <div className="fade_carousel_left"></div>
                            <div className="fade_carousel_right"></div>
                            <div className="carousel_go_decrement" onClick={decrement_carousel}
                            onTouchEnd={(e)=>{
                                if (validate_touch(e)) {
                                    e.preventDefault();
                                    decrement_carousel();
                                }
                            }} ref={carousel_go_decrement}>←</div>
                            <div className="carousel_go_increment" onClick={increment_carousel}
                            onTouchEnd={(e)=>{
                                if (validate_touch(e)) {
                                    e.preventDefault();
                                    increment_carousel();
                                }
                            }} ref={carousel_go_increment}>→</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Roadmap;