import { useEffect, useState, useRef } from 'react';
import { short_format_number, validate_touch } from "../Services/helper_functions.jsx";
import Title_Text from "./title_text.jsx";
import { get_img } from "../Services/images.jsx";

let planet_overhang = get_img("planet_overhang");
let rocket_background_orange = get_img("rocket_background_orange");
let to_the_moon_with_background = get_img("to_the_moon_with_background");
let to_the_moon = get_img("to_the_moon");
let alarm_clock_overlay = get_img("alarm_clock_overlay");

function Presale_Stage({launch_purchase_funnel, shared_info }) {

    const [countdown_taget, set_countdown_target] = useState(0);
    const [time_difference, set_time_difference] = useState(0);
    const [time_diffs, set_time_diffs] = useState(['00','00','00','00']);
    const countdown_bar_inner_ref = useRef();

    const [amount_raised, set_amount_raised] = useState("");

    function open_purchase_funnel(credit_card=false) {
        launch_purchase_funnel(0,credit_card,false)
    }

    function go_to_how_to_buy() {
        const element = document.querySelector('.how_to_buy');
        element.scrollIntoView({ behavior: 'smooth' });
    }

    // get_usd_sales


    useEffect(()=>{
        if (shared_info) {
            set_countdown_target(shared_info.next_price_increase);
            let server_time = shared_info.server_time / 1000;
            let client_time = Date.now() / 1000;
            set_time_difference(server_time - client_time);

            set_amount_raised((shared_info.usd_sales.value).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','));
/*
            let total_eth = 0;
            let total_stables = 0;
            let total_polygon = 0;
            let total_busd = 0;
            let total_bnb = 0;
            for (let index in shared_info.sales_breakdown) {
                if (["0","3"].includes(index)) {
                    total_eth += shared_info.sales_breakdown[index];
                }
                else if (["1","2"].includes(index)) {
                    total_stables += shared_info.sales_breakdown[index];
                }
                else if (["4","5"].includes(index)) {
                    console.log({index})
                    total_polygon += shared_info.sales_breakdown[index];
                }
                else if (["6"].includes(index)) {
                    total_bnb += shared_info.sales_breakdown[index];
                }
                else if (["7"].includes(index)) {
                    total_busd += shared_info.sales_breakdown[index];
                }
            }


            let total = total_eth * shared_info.exchange_rate;
            total = total / (10**18);
            total += total_stables / (10**6)

            total += (total_polygon * shared_info.polygon_exchange_rate / (10**18))

            total += (total_bnb * shared_info.bnb_exchange_rate / (10**18));
            total += (total_busd / (10**18));

            total += 45000;
            total = (total).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            set_amount_raised(total);
*/
        }
    },[shared_info]);





    useEffect(()=> {
        if (countdown_taget!=0) {
            setInterval(get_time_diff, 1000);
        }
    },[countdown_taget]);
    

    

    function get_time_diff() {
        let adjusted_time_now = (Date.now() / 1000) + time_difference;
        let time_diff = countdown_taget - adjusted_time_now;
/*
        if (time_diff < 0) {
            window.location.reload();
        }
*/
        if (countdown_bar_inner_ref.current) {
            countdown_bar_inner_ref.current.style.width = `${196 * (1-(time_diff / (24*60*60)))}px`;
        }
    
        let times = ['days', 'hours', 'minutes', 'seconds'];
        let time_units = {'days': 86400, 'hours': 3600, 'minutes': 60, 'seconds': 1};

        let time_diffs = {};
        let temp = [];

        for (let i = 0; i < times.length; i++) {
            let time = times[i];
            time_diffs[time] = Math.floor(time_diff / time_units[time]);
            temp.push(time_diffs[time].toString().padStart(2, '0'))
            time_diff %= time_units[time];
        }

        set_time_diffs(temp)
    }

    return (
        <div className="component" style={{backgroundColor: 'var(--light_orange)'}}>
            <div className="inner_component inner_component_with_overhang">
                
                
                <div className="block_with_inner"  style={{backgroundImage: `url(${rocket_background_orange})`}}>
                    <div className="inner_block">
                        <Title_Text text={<>PRE-SALE<br/>STAGE HAS STARTED</>} text_color={"var(--title_orange)"} outline_color={"black"}/>
                        <div className="countdown_container">
                            <div className="inner_title"><b>1 ApeMax = {shared_info? `$${shared_info.base_price.toFixed(8)}` : "Loading..."}</b></div>
                            <div className="inner_title">{
                                amount_raised == "" ?
                                "Loading..." :
                                `$${amount_raised} Sold`
                                }
                                </div>
                            <div className="smaller_title">Price Increases In:</div>
                            <div className="countdown_numbers_inner">
                                <div className="countdown_number">{time_diffs[0]}</div>
                                <div className="countdown_seperator">:</div>
                                <div className="countdown_number">{time_diffs[1]}</div>
                                <div className="countdown_seperator">:</div>
                                <div className="countdown_number">{time_diffs[2]}</div>
                                <div className="countdown_seperator">:</div>
                                <div className="countdown_number">{time_diffs[3]}</div>
                            </div>
                            <div className="countdown_description_inner">
                                <div className="countdown_descriptor">DAY</div>
                                <div className="countdown_seperator"></div>
                                <div className="countdown_descriptor">HR</div>
                                <div className="countdown_seperator"></div>
                                <div className="countdown_descriptor">MIN</div>
                                <div className="countdown_seperator"></div>
                                <div className="countdown_descriptor">SEC</div>
                            </div>
                            <div className="increase_time_bar_container">
                                <div className="increase_time_bar_outer">
                                    <div className="increase_time_bar_inner" ref={countdown_bar_inner_ref}></div>
                                </div>
                            </div>
                            <div className="smaller_title"><b>Price Increases Every 24 Hours</b></div>
                            <div className="smaller_title">Don't miss out on the ApeMax Presale</div>
                            {
                                shared_info ? 
                                <div className="smaller_title">{short_format_number(shared_info.total_minted).replace("B","")} / 20 Billion ApeMax Sold</div> :
                                <></>
                            }
                            
                            
                            <img className="alarm_clock_overlay_0" src={alarm_clock_overlay}/>
                            <img className="alarm_clock_overlay_1" src={alarm_clock_overlay}/>
                        </div>
                        <div className="full_width_button"
                            onClick={()=>open_purchase_funnel(false)}
                            onTouchEnd={(e)=>{
                                if (validate_touch(e)) {
                                    e.preventDefault();
                                    open_purchase_funnel(false);
                                }
                            }}><b>Buy with Crypto</b></div>
                        <div className="full_width_button credit_card_dissabled"
                            onClick={()=>open_purchase_funnel(true)}
                            onTouchEnd={(e)=>{
                                if (validate_touch(e)) {
                                    e.preventDefault();
                                    open_purchase_funnel(true);
                                }
                            }}><b>Buy with Credit Card</b><div className="coming_soon_extra_text">Coming Soon</div></div>
                        
                        <img className="all_cryptos_img" src={get_img("all_cryptos")}/>
                        <div className="full_width_more_info"
                            onClick={go_to_how_to_buy}
                            onTouchEnd={(e)=>{
                                if (validate_touch(e)) {
                                    e.preventDefault();
                                    go_to_how_to_buy();
                                }
                            }}
                        ><u>How to buy?</u></div>
                        
                        
                    </div>
                </div>
                <img src={planet_overhang} className="overhang"/>
                <img src={to_the_moon} className="underhang desktop_only"/>
                <img src={to_the_moon_with_background} className="full_width_image mobile_only"/>
            </div>

        </div>
    )
}

export default Presale_Stage;