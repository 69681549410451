import React, { useRef, useEffect } from 'react';
import Chart from 'chart.js/auto';

function Revenue_Chart({ data=[], labels=[] }) {
  const chartRef = useRef(null);
  const chartInstance = useRef(null);

  useEffect(() => {
    const myChartRef = chartRef.current.getContext('2d');

    // Destroy previous chart instance if it exists
    if (chartInstance.current !== null) {
      chartInstance.current.destroy();
    }

    chartInstance.current = new Chart(myChartRef, {
      type: 'bar',
      data: {
        labels: labels,
        datasets: [{
          label: 'Rewards',
          data: data,
          backgroundColor: '#d7f4fb',
          borderColor: '#338afc',
          borderWidth: 1
        }]
      },
      options: {
        scales: {
          y: {
            beginAtZero: true
          }
        },
        maintainAspectRatio: false
      }
    });

    return () => {
      // Destroy chart instance on component unmount
      if (chartInstance.current !== null) {
        chartInstance.current.destroy();
      }
    };
  }, [data]);

  return (
    <div className="rewards_chart">
        <canvas ref={chartRef}/>
    </div>
  );
}

export default Revenue_Chart;
