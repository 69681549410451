import React from 'react';
import { short_format_number } from '../Services/helper_functions';
import Title_Text from "./title_text.jsx";
import { get_img } from "../Services/images.jsx";

let contract_imgs = [];
for (let i = 0; i < 100; i++) {
    contract_imgs.push(get_img(i));
}

function RankingTable({ranking, set_selected_project_index}) {


    function show_project(index) {
        set_selected_project_index(index);
        document.querySelector('body').style.overflow = 'hidden';
        document.querySelector(".overlay_container").style.display = "block";
    }

    function calculate_apr(total_staked, total_rewards) {
        let apr = 100 * (365*24*60*60) * (total_rewards / total_staked) / (Math.floor(Date.now() / 1000) - 1685248970);
        return apr <= 0 || total_staked == 0 ? "0.00" : apr.toFixed(2);
    }

    return (
        <div className="table_container">
            <div className="safe_inner">
                <Title_Text text={<>STAKING RANKING</>} text_color={"var(--title_blue)"} outline_color={"black"} override_class={"title_text ranking_title"}/>
                <div className="inner_title">ApeMax staking model works to distribute reward pseudorandomly to the various stakable entities. The more there is staked on a project, the higher the likelyhood for it to receive rewards. You can find more details in our whitepaper. Listed below are the top stakable entities. Connect your wallet and click on the one's you want to stake on.</div>
            </div>

            

            <div className="table_overflow_container">

                <div className="description_row">
                    <div className="ranking_counter_indicator">Rank</div>
                    <div className="row_name_indicator">Stakable Entity</div>
                    <div className="row_amount_staked">Amount Staked</div>
                    <div className="row_amount_staked">Rewards Earned</div>
                    <div className="row_amount_staked">Estimated ApeMax APR</div>
                    {/* <div className="row_amount_staked">APR</div> */}
                </div>
                {(function(){

                    let rows = [];
                    for (let i = 0; i < ranking.length; i++) {
                        let curI = i;

                        rows.push(<div className={i==0?"row_item row_item_first":"row_item"}
                            onClick={() => show_project(curI)}>

                            <div className="ranking_counter">{i+1}</div>
                            <img src={contract_imgs[ranking[i].contract_index]} className="ranking_icon"/>
                            <div className="row_contract_name">{ranking[i].name}</div>
                            <div className="row_amount_staked">{short_format_number(ranking[i].total_staked/10**18)}</div>
                            <div className="row_amount_staked">{short_format_number(ranking[i].total_rewards/10**18)}</div>
                            <div className="row_amount_staked">{calculate_apr(ranking[i].total_staked,ranking[i].total_rewards)}%</div>
                            {/* <div className="row_amount_staked">Available</div> */}
                            {/* <div className="row_amount_staked">{ranking[i].apr.toFixed(2)}%</div> */}
                        </div>);
                    }

                    return rows;

                })()}
            </div>
            <div className="safe_inner"></div>
        </div>
    )
};

export default RankingTable;
