import { useRef, useEffect, useState } from 'react';

import { ethers } from "ethers";
import contract_abi from "../Config/token_abi.json";
import { token_address, rpc_url, stable_coin_decimals } from "../Config/config.jsx";

import { short_format_number, format_timestamp, validate_touch } from "../Services/helper_functions.jsx";

import { get_sales } from "../Services/api.jsx";

import Title_Text from "./title_text.jsx";
import Dollar_Bill from './dollar_bill.jsx';
import { get_img } from "../Services/images.jsx";

let lights_overhang = get_img("lights_overhang");
let live_sales_background = get_img("live_sales_background");
let usd_c_icon = get_img("usd_c_icon");
let usd_t_icon = get_img("usd_t_icon");
let ethereum_icon = get_img("ethereum_icon");
let bnb_icon = get_img("bnb_icon");
let busd_icon = get_img("busd_icon");
let polygon_icon = get_img("polygon_icon");

let credit_card_icon = get_img("credit_card_icon");
let piggie_bank = get_img("piggie_bank");
let dollar_bill_overlay = get_img("dollar_bill_overlay");

const get_random_arbitrary = (min, max) => {
    return Math.random() * (max - min) + min;
};
  

function Live_Sales({launch_purchase_funnel, shared_info}) {

    let [is_fetching, set_is_fetching] = useState(false);
    let [sales_history, set_sales_history] = useState([]);

    function open_purchase_funnel(credit_card=false) {
        launch_purchase_funnel(0,credit_card,false);
    }

    function go_to_how_to_buy() {
        const element = document.querySelector('.how_to_buy');
        element.scrollIntoView({ behavior: 'smooth' });
    }

    const [bills, set_bills] = useState([]);

    useEffect(() => {
        const interval = setInterval(() => {
        set_bills(prev_bills => [...prev_bills, {
            id: Math.random(),
            left: Math.random() > 0.5 ?
            get_random_arbitrary(-15, 0):
            get_random_arbitrary(85, 100),
            rotation: get_random_arbitrary(0, 50),
            top: 100,
        }]);
        }, 500);

        return () => clearInterval(interval);
    }, []);
    

   

    useEffect(()=>{

        /*
        actual_info.push({
                    currency_index: info.args.currency_index,
                    currency: ["ETH","USDT","USDC","US$"][info.args.currency_index],
                    timestamp: formatTimestamp(info.args.timestamp),
                    amount_paid:
                        info.args.currency_index == 0 ?
                            (Number(info.args.amount_paid)/10**18).toFixed(4) :
                            info.args.currency_index == 3 ?
                            (shared_info.exchange_rate * Number(info.args.amount_paid)/10**18).toFixed(2) :
                            (Number(info.args.amount_paid)/10**18).toFixed(2)
                    ,
                    amount_minted: short_format_number(Number(info.args.amount_minted)/10**18)
                });
        */

        async function fetch() {
            let sales_history = await get_sales(10,0);
            let actual_info = [];
            sales_history.forEach(item => {

                let { currency_index, amount_paid, amount_minted, timestamp } = item;

                actual_info.push({
                    currency_index,
                    payment_method: ["Ethereum","USDT","USDC","Credit Card","Credit Card","Polygon","BNB","BUSD"][currency_index],
                    currency: ["ETH","USDT","USDC","US$","US$","Matic","BNB","BUSD"][currency_index],
                    timestamp: format_timestamp(timestamp),
                    amount_paid:
                        currency_index == 0 ?
                            (Number(amount_paid)/10**18).toFixed(4) :
                            currency_index == 3 ?
                            (shared_info.exchange_rate * Number(amount_paid)/10**18).toFixed(2) :
                            currency_index == 4 ?
                            (shared_info.polygon_exchange_rate * Number(amount_paid)/10**18).toFixed(2) :
                            currency_index == 7 ?
                            (Number(amount_paid)/10**18).toFixed(2) :
                            currency_index > 4 ?
                            (Number(amount_paid)/10**18).toFixed(4) :
                            (Number(amount_paid)/10**stable_coin_decimals).toFixed(2),

                    amount_minted: short_format_number(Number(amount_minted)/10**18)
                });
            });

            set_sales_history(actual_info);
        }

        // sales(max_per_page, page_number)

        // async function fetch() {
        //     let provider = new ethers.providers.JsonRpcProvider(rpc_url)
        //     let contract = new ethers.Contract(token_address, contract_abi, provider);
        //     let event_filter = contract.filters._mint_apemax(null,null,null,null,null);
        //     let blockNumber = await provider.getBlockNumber();

        //     let collected_events = [];

        //     for (let i = 0; i < 10; i++) {

        //         if (collected_events.length >= 10) {
        //             break;
        //         }

        //         event_filter = {
        //             ...event_filter,
        //             fromBlock: blockNumber-10000,
        //             toBlock: blockNumber,
        //         };

        //         let events = await provider.getLogs(event_filter);

        //         for (let x = 0; x < events.length; x++) {
        //             collected_events.push(events[x]);
        //             if (collected_events.length >= 10) {
        //                 break;
        //             } 
        //         }

        //         blockNumber-=10000;
        //     }

        //     let actual_info = [];
        //     collected_events.forEach(event => {
        //         let info = contract.interface.parseLog(event);
        //         actual_info.push({
        //             currency_index: info.args.currency_index,
        //             currency: ["ETH","USDT","USDC","US$"][info.args.currency_index],
        //             timestamp: formatTimestamp(info.args.timestamp),
        //             amount_paid:
        //                 info.args.currency_index == 0 ?
        //                     (Number(info.args.amount_paid)/10**18).toFixed(4) :
        //                     info.args.currency_index == 3 ?
        //                     (shared_info.exchange_rate * Number(info.args.amount_paid)/10**18).toFixed(2) :
        //                     (Number(info.args.amount_paid)/10**18).toFixed(2)
        //             ,
        //             amount_minted: short_format_number(Number(info.args.amount_minted)/10**18)
        //         });
        //     });

        //     set_sales_history(actual_info);
        // }

        if (shared_info != void 0 && is_fetching == false) {
            fetch();
            set_is_fetching(true);
        }
    },[shared_info]);

    return (
        <div className="component" style={{backgroundColor: 'var(--light_purple)'}}>
            <div className="inner_component inner_component_with_overhang">
                
                <div className="block_with_inner"  style={{backgroundImage: `url(${live_sales_background})`}}>
                    <div className="inner_block">
                        <Title_Text text={<>LIVE SALES</>} text_color={"var(--title_purple)"} outline_color={"black"}/>
                        <div className="inner_title">Latest 10 purchases, of ApeMax will appear below. You can refresh the page to get updates.</div>
                        <div className="live_sales_super">
                            <div className="live_sales_scrollable">
                                {
                                    (function() {

                                        let indicators = [ethereum_icon, usd_t_icon, usd_c_icon, credit_card_icon, credit_card_icon, polygon_icon, bnb_icon, busd_icon];

                                        let rows = [];
                                        for (let i = 0; i < sales_history.length; i++) {

                                            let item = sales_history[i];
                                            
                                            let type_index = Math.floor(Math.random()*4);
                                            rows.push(
                                                <div className="live_sale_item" style={{marginBottom: `${i==sales_history.length-1?'20px':'0px'}`}}>
                                                    
                                                    <img src={indicators[item.currency_index]} className="live_sales_img"/>
                                                    <div className="live_sales_purchase_text"><b>{item.payment_method}</b></div>
                                                    <div className="live_sales_price_text">{item.currency} {item.amount_paid} ({item.amount_minted})</div>
                                                    <div className="live_sales_time_text">{item.timestamp}</div>

                                                </div>
                                            )
                                        }
                                        return rows;
                                    })()
                                }
                                
                            </div>
                            <div className="top_fade_white"></div>
                            <div className="bottom_fade_white"></div>
                        </div>
                        {bills.map(bill => (
                            <Dollar_Bill
                            key={bill.id}
                            left={bill.left}
                            rotation={bill.rotation}
                            top={bill.top}
                            />
                        ))}
                        {/* <img className="dollar_bill_overlay_0" src={dollar_bill_overlay}/> */}
                        {/* <img className="dollar_bill_overlay_1" src={dollar_bill_overlay}/> */}
                    </div>
                </div>
                <img src={lights_overhang} className="overhang"/>
            </div>
            <div className="inner_component inner_component_sequential">
                <div className="standard_block">
                    <div className="safe_inner">
                        <div className="inner_half_split">
                            <Title_Text text={<>WHAT ARE YOU WAITING FOR?</>} text_color={"var(--title_purple)"} outline_color={"black"}/>
                            <div className="full_width_button"
                                onClick={()=>open_purchase_funnel(false)}
                                onTouchEnd={(e)=>{
                                    if (validate_touch(e)) {
                                        e.preventDefault();
                                        open_purchase_funnel(false)
                                    }
                                }}><b>Buy with Crypto</b></div>
                            <div className="full_width_button credit_card_dissabled"
                                onClick={()=>open_purchase_funnel(true)}
                                onTouchEnd={(e)=>{
                                    if (validate_touch(e)) {
                                        e.preventDefault();
                                        open_purchase_funnel(true)
                                    }
                                }}><b>Buy with Credit Card</b><div className="coming_soon_extra_text">Coming Soon</div></div>
                            <img className="all_cryptos_img" src={get_img("all_cryptos")}/>
                            <div className="full_width_more_info"
                                onClick={go_to_how_to_buy}
                                onTouchEnd={(e)=>{
                                    if (validate_touch(e)) {
                                        e.preventDefault();
                                        go_to_how_to_buy();
                                    }
                                }}><u>How to buy?</u></div>
                        </div>
                        <div className="inner_half_split">
                        <img src={piggie_bank} className="smaller_width_image"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Live_Sales;