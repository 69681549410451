import { short_format_number, validate_touch } from '../Services/helper_functions';

import Title_Text from "./title_text.jsx";

import { get_img } from "../Services/images.jsx";

let contract_imgs = [];
for (let i = 0; i < 100; i++) {
    contract_imgs.push(get_img(i,"s"));
}


function Earn_With_Apemax({launch_purchase_funnel, shared_info}) {

    function open_purchase_funnel(credit_card=false) {
        launch_purchase_funnel(0,credit_card,false);
    }

    function go_to_how_to_buy() {
        const element = document.querySelector('.how_to_buy');
        element.scrollIntoView({ behavior: 'smooth' });
    }

    function go_to_staking() {
        window.location = 'ranking';
    }


    return (
        <div className="component" style={{backgroundColor: 'var(--light_pink)'}}>  

            <div className="inner_component inner_component_with_overhang inner_component_with_overhang_less">
                
                <div className="block_with_inner"  style={{backgroundImage: `url(${get_img('pink_charts_background','')}`}}>
                    <div className="inner_block">
                        <Title_Text text={<>EARN WITH APEMAX</>} text_color={"var(--title_pink)"} outline_color={"black"}/>
                        <div className="inner_title">Earn passively with ApeMax staking! Simply stake on what you love, and as the community boosts, your earnings grow. It's that straightforward.<br/><br/>Check out the top 5 projects below.</div>
                        
                        

                        <div className="ranking_container">
                            <div className="ranking_top_10">
                                <div className="description_ranking">
                                    <div className="ranking_counter_indicator">Rank</div>
                                    <div className="contract_name_indicator">Project</div>
                                    <div className="ranking_amount_staked"></div>
                                    <div className="ranking_amount_staked">Amount Staked</div>
                                    {/* <div className="ranking_amount_staked">Rewards Earned</div> */}
                                </div>
                                {(function() {
                                    let rows = [];

                                    if (shared_info != void 0) {
                                        let ranking = shared_info.ranking;
                                        for (let i = 0; i < 5; i++) {
                                            rows.push(
                                                <div className="ranking_item">
                                                    <div className="ranking_counter">{i+1}</div>
                                                    <img src={contract_imgs[ranking[i].contract_index]} className="ranking_icon"/>
                                                    <div className="ranking_contract_name">{ranking[i].name}</div>
                                                    <div className="ranking_amount_staked"></div>
                                                    <div className="ranking_amount_staked">{short_format_number(ranking[i].total_staked/10**18)}</div>
                                                    {/* <div className="ranking_amount_staked">{short_format_number(ranking[i].total_rewards/10**18)}</div> */}
                                                </div>
                                            )
                                        }
                                    }
                                    return rows;
                                })()}
                            </div>
                        </div>

                        {/* <div className="full_width_more_info" onClick={go_to_staking}
                        onTouchEnd={(e)=>{
                            if (validate_touch(e)) {
                                e.preventDefault();
                                go_to_staking();
                            }
                        }}><u>View All Projects</u></div> */}

                        
                    </div>

                    <div className="inner_block">
                        <img src={get_img('magnet_overhang','')} className="extra_top_image"/>
                        <br/>
                        <Title_Text text={<>HOW IT WORKS</>} text_color={"var(--title_pink)"} outline_color={"black"} override_class={"title_text title_text_extra"}/>
                        <div className="counter_row">
                            <div className="counter_item">Buy ApeMax Tokens</div>
                            <div className="counter_circle">1</div>
                        </div>
                        <div className="counter_row">
                            <div className="counter_item counter_item_1">Stake Your ApeMax</div>
                            <div className="counter_circle counter_circle_1">2</div>
                        </div>
                        <div className="counter_row">
                            <div className="counter_item counter_item_2">Earn Free ApeMax</div>
                            <div className="counter_circle counter_circle_2">3</div>
                        </div>

                        <div className="inner_title">Yes it's that easy!<br/>Stake. Earn. Repeat.</div>
                    </div>
                    
                </div>
                
                <img src={get_img(`bunting_overhang`,'')} className="overhang"/>
                

            </div>

            <div className="inner_component inner_component_sequential">
                <div className="standard_block">
                    <div className="safe_inner">
                        <div className="inner_half_split">
                            <Title_Text text={<>GET STARTED TODAY!</>} text_color={"var(--title_pink)"} outline_color={"black"} override_class={"title_text nerf_top_margin"}/>
                            <div className="full_width_button" onClick={go_to_staking}
                            onTouchEnd={(e)=>{
                                if (validate_touch(e)) {
                                    e.preventDefault();
                                    go_to_staking();
                                }
                            }}><b>Stake ApeMax</b></div>
                            {/* <div className="full_width_button" onClick={()=>open_purchase_funnel(false)}
                            onTouchEnd={(e)=>{
                                if (validate_touch(e)) {
                                    e.preventDefault();
                                    open_purchase_funnel(false)
                                }
                            }}><b>Buy with Crypto</b></div>
                            <div className="full_width_button credit_card_dissabled" onClick={()=>open_purchase_funnel(true)}
                            onTouchEnd={(e)=>{
                                if (validate_touch(e)) {
                                    e.preventDefault();
                                    open_purchase_funnel(true)
                                }
                            }}><b>Buy with Credit Card</b><div className="coming_soon_extra_text">Coming Soon</div></div>
                            <img className="all_cryptos_img" src={get_img("all_cryptos")}/>
                            <div className="full_width_more_info" onClick={go_to_how_to_buy}
                            onTouchEnd={(e)=>{
                                if (validate_touch(e)) {
                                    e.preventDefault();
                                    go_to_how_to_buy();
                                }
                            }}><u>How to buy?</u></div> */}
                        </div>
                        <div className="inner_half_split">
                        <img src={get_img(`laptop_ape`)} className="full_img_in_split"/>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    );
}

export default Earn_With_Apemax;