import { useState, useRef, useEffect } from 'react';
import { get_img } from "../Services/images.jsx";
import { validate_touch } from "../Services/helper_functions.jsx";


import Title_Text from "./title_text.jsx";

import { useSwipeable } from 'react-swipeable';

let key_overhang = get_img("key_overhang");
let blue_swirl_background = get_img("blue_swirl_background");
let battle_unicorn_riding_rainbow_in_space = get_img("battle_unicorn_riding_rainbow_in_space");

let roadmap_imgs = [];

for (let i = 0; i < 5; i++) {
    roadmap_imgs.push(get_img(`key_features_${i}`))
}


function Key_Features_Carousel() {

    const [currentIndex, setCurrentIndex] = useState(2);

    const swipe_handlers = useSwipeable({
        onSwipedLeft: () => {
            increment_carousel();
        },
        onSwipedRight: () => {
            decrement_carousel();
        },
        preventDefaultTouchmoveEvent: true,
        trackMouse: false
    });

    const carousel_container = useRef(null);
    useEffect(() => {
        if (carousel_container.current) {

            carousel_container.current.scroll({
            left: (2510-carousel_container.current.offsetWidth)/2 + 282*(currentIndex-2),
            behavior: 'smooth'
            });

            set_carousel_views(currentIndex);
        }
    },[currentIndex]);

    const [carouselItems, setCarouselItems] = useState([]);

    useEffect(() => {
        if (carouselItems.length > 0) {
            set_carousel_views(currentIndex);
        }
    }, [currentIndex, carouselItems]);

    const addToCarouselItems = (el) => {
        if (el && !carouselItems.includes(el)) {
            setCarouselItems(prevItems => [...prevItems, el]);
        }
    };

    let carousel_go_decrement = useRef();
    let carousel_go_increment = useRef();

    function set_carousel_views(curIndex) {

        

        carousel_go_decrement.current.style.opacity = curIndex == 0 ? '0.25' : '1';
        carousel_go_increment.current.style.opacity = curIndex == 4 ? '0.25' : '1';


        for (let i = 0; i < carouselItems.length; i++) {
            let item = carouselItems[i];

            if (i == curIndex) {
                item.className = 'carousel_item carousel_item_full';
            }
            else if (i+1 == curIndex || i-1 == curIndex) {
                item.className = 'carousel_item carousel_item_medium';
            }
            else {
                item.className = 'carousel_item carousel_item_small';
            }
        }
    }

    function increment_carousel() {
        let curIndex = currentIndex;
        curIndex = curIndex < 4 ? curIndex+1 : 4;
        setCurrentIndex(curIndex);
    }

    function decrement_carousel() {
        let curIndex = currentIndex;
        curIndex = curIndex > 0 ? curIndex-1 : 0;
        setCurrentIndex(curIndex);
    }


    useEffect(() => {
        let prev_width = 0;
        window.addEventListener('resize', function() {

            if (prev_width == window.innerWidth) {
                return;
            }

            prev_width = window.innerWidth;

            if (carousel_container.current) {
                setCurrentIndex(currentIndex);
                carousel_container.current.scroll({
                    left: (2510-carousel_container.current.offsetWidth)/2 + 282*(currentIndex-2),
                    behavior: 'smooth'
                });
                set_carousel_views(currentIndex);
            }
        });
    },[]);

    
    return (
        <div className="carousel_super_container" {...swipe_handlers}>
            <div className="carousel_container" ref={carousel_container}>
                <div className="carousel_inner_max">
                    {(function() {
                        
                        
                        
                        let roadmap_titles = [
                            "Safe and Secure",
                            "Stake and Earn",
                            "Best Price Now",
                            "Instant Custody",
                            "Real Tokenomics"
                        ];

                        let roadmap_content = [
                            [
                                "ERC-20 standard",
                                "Professionally audited",
                                "Ethereum backed"
                            ],
                            [
                                "Staking open soon",
                                "No staking minimums",
                                "Easy staking UI"
                            ],
                            [
                                "Auto price increase",
                                "Every 24 hours",
                                "During pre-sale"
                            ],
                            [
                                "Self custody",
                                "No hidden fees",
                                "Rug proof"
                            ],
                            [
                                "Decentralized protocol",
                                "Audited staking mechanics",
                                "Real utility"
                            ],
                        ]

                        let extra_text = [
                            "Safety First",
                            "Earn Big",
                            "Guaranteed",
                            "Really Yours",
                            "Future Proof"
                        ]


                        let items = [];
                        for (let i = 0; i < 9; i++) {

                            let curI = i;

                            if (i < 2 || i > 6) {
        
                                items.push(<div className="empty_carousel_item" style={{marginRight: i==8?'0px':'20px'}}></div>);
                                continue;
                            }

                            items.push(
                                <div className="carousel_item"
                                    onClick={() => {setCurrentIndex(curI-2);}}
                                    // onTouchEnd={(e)=>{
                                    //     e.preventDefault();
                                    //     setCurrentIndex(curI-2);
                                    // }}
                                    ref={addToCarouselItems}>
                                    <div className="carousel_title carousel_title_high">{roadmap_titles[i-2]}</div>
                                    {/* <Title_Text text={roadmap_titles[i-2]} text_color={"var(--title_blue)"} outline_color={"black"} override_class={"carousel_title"}/> */}
                                    <img src={blue_swirl_background} className="roadmap_img_background"/>
                                    <img src={roadmap_imgs[i-2]} className="features_img"/>
                                    <div className="roadmap_status_indicator">
                                        <a style={{color: `var(--medium_green)`}}>{extra_text[i-2]}</a>
                                    </div>

                                    <div className="roadmap_list">
                                        <div className="roadmap_list_item">{roadmap_content[i-2][0]}</div>
                                        <div className="roadmap_list_item">{roadmap_content[i-2][1]}</div>
                                        <div className="roadmap_list_item">{roadmap_content[i-2][2]}</div>
                                    </div>
                                </div>
                            );
                        }
                        return items
                    })()}
                </div>
                
            </div>
            <div className="fade_carousel_left"></div>
            <div className="fade_carousel_right"></div>
            <div className="carousel_go_decrement"
                onClick={decrement_carousel}
                onTouchEnd={(e)=>{
                    if (validate_touch(e)) {
                        e.preventDefault();
                        decrement_carousel();
                    }
                }}
                ref={carousel_go_decrement}>←</div>
            <div className="carousel_go_increment"
                onClick={increment_carousel}
                onTouchEnd={(e)=>{
                    if (validate_touch(e)) {
                        e.preventDefault();
                        increment_carousel();
                    }
                }}
                ref={carousel_go_increment}>→</div>
        </div>
    );
}

export default Key_Features_Carousel;