import { useState, useRef, useEffect, useCallback } from 'react';
import { contract_activity, rewards_for_contract } from "../Services/api.jsx";
import { short_format_number, format_timestamp, shorten_crypto_string, format_timestamp_text, process_rewards, validate_touch } from "../Services/helper_functions.jsx";
import { block_explorer } from "../Config/config.jsx";
import { stake_apemax, get_staking_fees, get_contract, get_unclaimed_creator_rewards, update_royalties, claim_creator_rewards } from "../Services/web3_functions.jsx";
import { useAccount, useNetwork } from 'wagmi';
import { waitForTransaction } from '@wagmi/core'
import Revenue_Chart from "../Components/chart.jsx";
import { get_img } from "../Services/images.jsx";



let lootbox_0 = get_img("lootbox_0");
let cross_logo = get_img("cross_logo");

let distribute_rewards_icon = get_img("distribute_rewards_icon");
let stake_icon = get_img("stake_icon");
let royalties_icon = get_img("royalties_icon");
let contract_icon = get_img("contract_icon");
let unstake_icon = get_img("unstake_icon");
let claim_icon = get_img("claim_icon");

let refresh_icon = get_img("refresh_icon");
let year_icon = get_img("year_icon");
let month_icon = get_img("month_icon");
let day_icon = get_img("day_icon");


let time_icons = [year_icon, month_icon, day_icon];


let contract_imgs = [];
for (let i = 0; i < 100; i++) {
    contract_imgs.push(get_img(i));
}


let sample_img = "https://i.seadn.io/gae/Ju9CkWtV-1Okvf45wo8UctR-M9He2PjILP0oOvxE89AyiPPGtrR3gysu1Zgy0hjd2xKIgjJJtWIc0ybj4Vd7wv8t3pxDGHoJBzDB?auto=format&w=1920"


let contract_events = {
    '_stake_tokens':['amount_staked',' $APEMAX Staked', stake_icon],
    '_unstake_tokens':['amount_staked',' $APEMAX Unstaked', unstake_icon],
    '_claim_staking_rewards':['amount_claimed',' $APEMAX Claimed', claim_icon],
    '_create_staking_contract':['','Staking Contract Created', contract_icon],
    '_claim_creator_rewards':['amount_claimed',' $APEMAX Claimed', lootbox_0],
    '_update_royalties':['royalties','% Royalties Update', royalties_icon],
    '_distribute_rewards':['reward_amount','$APEMAX Earned', distribute_rewards_icon]
}

function Discalimer() {
    return (
        <div className="discalimer_text">
            <br/>
            LEGEND<br/><br/>

            [1]: Project Ranking is the rank of the project compared to all other existing projects in terms of amount staked.<br/><br/>
            [2]: Total Staked is the amount of $APEMAX token currently staked on this project.<br/><br/>
            [3]: Total Reward Earned is the amount of $APEMAX earned from staking on this project. Finders fees are not included.<br/><br/>
            [4]: Estimated APR is the estimated percentage return over one year calculated by comparing the rewards earned to the amounts staked. If there is not enough data for a full year this figure is extrapolated based on the available data and therefore this is only an estimation.  Finders fees are not included in this calculation.<br/><br/>
            [5]: The Royalty rate attributed to the creator. Royalties are levied directly on tax and subsidy based rewards. Royalties are also applied as staking fees but at a rate that is 100 times lower.<br/><br/>
            [6]: The Staking Delay is the a nerf used to discourage over staking on popular projects. During the staking delay period, a stake will not produce any rewards.<br/><br/>
            NEED HELP?<br/><br/>
            If you are having difficulty purchasing, need assistance, or have questions about ApeMax please feel free to reach out to our <u className="clickable_text">Customer Support Team</u>.
        </div>
    )
}

function Project_Details({ranking, selected_project_index, global, set_hash, set_global_currency_index}) {

    // FIX CHAIN

    let { chain } = useNetwork();

    async function fix_network() {

        if (isConnected) {
            set_global_currency_index(56);
        }
    }

    function check_network() {
        if (isConnected) {
            if (chain.id != 56) {
                return false;
            }
        }

        return true;
    }

    // TX TRACKING
    function track_transaction(hash) {
        set_hash(hash);
        close_pop_up();
        document.querySelector(".transaction_tracker").style.display = "block";
    }

    function calculate_apr(total_staked, total_rewards) {
        let apr = 100 * (365*24*60*60) * (total_rewards / total_staked) / (Math.floor(Date.now() / 1000) - 1685248970);
        return apr <= 0 || total_staked == 0 ? "0.00" : apr.toFixed(2);
    }


    // SAMPLE CHART DATA
    const data = [10, 20, 30, 40, 50, 60, 70];

    function close_pop_up() {
        document.querySelector('body').style.overflow = 'auto';
        document.querySelector('.overlay_container').style.display = 'none';
    }

    const [recent_activity, set_recent_activity] = useState([]);
    const { isConnected, address } = useAccount();
    const [timeoutId, setTimeoutId] = useState(null);

    const [rewards_chart_data, set_rewards_chart_data] = useState([]);
    const [rewards_chart_labels, set_rewards_chart_labels] = useState([]);

    

    const [estimated_fees, set_estimated_fees] = useState({
        creator: 0,
        ministerial: 0,
        staker: 0,
        tax: 0,
        total: 0,
        stake_value: 0
    })

    const stake_input_amount = useRef();

    const royalties_input_amount = useRef();

    async function update_staking_fees() {

        let stake_value = stake_input_amount.current.value

        let staking_fees = await get_staking_fees(stake_value*10**18, ranking[selected_project_index].contract_index);
        let fees = {
            creator: 0,
            ministerial: 0,
            staker: 0,
            tax: 0,
            total: 0
        }
        for (let key in fees) {
            fees[key] = short_format_number(Number(staking_fees[key])/ 10**18)
        }
       
        fees['stake_value'] = short_format_number(Number(stake_value)-(Number(staking_fees.total)/ 10**18));

        set_estimated_fees(fees);
    }

    const handleInputChange = (event) => {
        clearTimeout(timeoutId);
    
        const newTimeoutId = setTimeout(() => {
            update_staking_fees();
        }, 2500);
    
        setTimeoutId(newTimeoutId);
    };
    

    const [royalties, set_royalties] = useState("Loading...");
    const [delay_nerf, set_delay_nerf] = useState("Loading...");

    function delay_function(amount_staked, total_staked, number_of_staking_contracts) {
        const decimals = Math.pow(10, 18);
        const a = amount_staked;
        const t = total_staked;
        const n = number_of_staking_contracts;
      
        let delay =
          315360000 *
          Math.pow((decimals * a) / t, 3) *
          n /
          10000 /
          Math.pow(decimals, 3);
      
        const max_uint32 = Math.pow(2, 32) - 1;
      
        if (delay > max_uint32) {
          return max_uint32;
        }
      
        return delay;
      }

      
    useEffect(()=> {

        if (selected_project_index != void 0) {

            (async function() {
                try {

                    update_contract_info();

                    update_staking_fees();

                    update_chart(0);

                    update_recent_activty();  

                } catch (error) {

                    
                }
            
            })()
        }

    },[selected_project_index]);

    // ----- Update contract inf -----
    let [is_contract_owner, set_is_contract_owner] = useState(false);
    let [contract_info_updated, set_contract_info_updated] = useState("");
    let [creator_rewards, set_creator_rewards] = useState("");
    async function update_contract_info() {
        let contract_info = await get_contract(ranking[selected_project_index].contract_index);

        console.log({contract_info});
        set_royalties(`${Number(contract_info.royalties)/100}%`)

        let delay_est = delay_function(Number(contract_info.total_staked), Number(global.total_staked), Number(global.contract_count));
        delay_est = format_timestamp_text(delay_est);
        set_delay_nerf(delay_est);

        set_contract_info_updated(format_timestamp(Date.now()/1000));

        if (address && contract_info.owner_address.toLowerCase() == address.toLowerCase()) {
            set_is_contract_owner(true);
            let rewards = await get_unclaimed_creator_rewards(ranking[selected_project_index].contract_index);
            rewards = short_format_number(rewards / 10**18);
            set_creator_rewards(rewards);
        }

    }

    useEffect(()=> {
        if (royalties_input_amount.current && is_contract_owner) {
            royalties_input_amount.current.value = royalties;
        }
    },[is_contract_owner])


    // ----- Recent activity -----
    let [activity_last_updated, set_activity_last_updated] = useState("");
    async function update_recent_activty() {

        let formatted_activity = [];
        try {

            let activity = await contract_activity(ranking[selected_project_index].contract_index, 20, 0);

            activity.forEach(act => {
                let map = contract_events[act.event];
                let desc = `${
                    map[0].length > 0 ?
                        map[0] == 'royalties' ?
                            act[map[0]].toFixed(2) :
                            short_format_number(act[map[0]] / 10**18)
                        :
                        ''
                }${map[1]}`;

                formatted_activity.push({
                    desc,
                    hash: act.hash,
                    timestamp: format_timestamp(act.timestamp),
                    icon: map[2]
                })

            })

        } catch (error) {}

        set_recent_activity(formatted_activity);

        set_activity_last_updated(format_timestamp(Date.now()/1000));
    }


    // ----- Updating chart ----
    
    let date_titles = ["Year","Month","Day"];
    let date_options = ["monthly","daily","hourly"];
    let [selected_option_index, set_selected_option_index] = useState(0);
    let [chart_last_updated, set_chart_last_updated] = useState("");

    async function update_chart(option_index) {
        try {

            set_selected_option_index(option_index)

            let option = date_options[option_index];

            let time_now = Date.now()/1000;
            let last_time = [
                time_now - (60*60*24*365),
                time_now - (60*60*24*30),
                time_now - (60*60*24)
            ]
            last_time = last_time[option_index];

            let rewards_history = await rewards_for_contract(
                ranking[selected_project_index].contract_index,
                last_time,
                time_now,
                option
            );

            console.log(rewards_history)

            let output = process_rewards(rewards_history,option);
            set_rewards_chart_data(output[0])
            set_rewards_chart_labels(output[1])
            
            
            

            //rewards_for_contract(contract_index, start_timestamp, end_timestamp, option)
        } catch (error) {
            set_rewards_chart_data([])
            set_rewards_chart_labels([])
        }

        set_chart_last_updated(format_timestamp(Date.now()/1000));
    }

    const [data_selector_visible, set_data_selector_visible] = useState(false);

    useEffect(() => {

        let timing_drop_down_list = document.querySelector(".timing_drop_down_list");
        if (timing_drop_down_list) {
            timing_drop_down_list.style.display = data_selector_visible ? 'block':'none';
        }
        
    },[data_selector_visible])




    


    // -- Staking

    useEffect(()=> {
        if (stake_input_amount.current) {
            stake_input_amount.current.value = 1000000;
        }
    },[]);

    const stake_fn = async () => {
        if (isConnected) {

            if (!check_network()) {
                fix_network();
                return;
            }

            if (stake_input_amount.current.value <= 0) {
                alert("Oops! It seems like you've discovered the secret realm of negative numbers. Unfortunately, our system only accepts positive vibes (and numbers)!")
                return;
            }

            let result = await stake_apemax(address, stake_input_amount.current.value * 10**18, ranking[selected_project_index].contract_index);
            if (result.status == 200) {
                // alert("Staking transaction sent! It will take some time for it to be confirmed by the blockchain. After it is confirmed it will take around 60 seconds for it to appear on your profile.")
                // let data = await waitForTransaction({hash:result.hash});
                // console.log({data});

                track_transaction(result.hash);
            }
            else if (result.status == 400) {
                alert("You don't have sufficient $APEMAX.")
            }
            else {
                alert("An error has occured. Please make sure you have enough BNB to cover the transaction gas before trying again. If the problem persists please contact our customer support team.")
            }
        }
        else {
            document.querySelector('.connect_wallet').style.display = 'block';
        }
    }

    const roylaties_update = async () => {

        if (!check_network()) {
            fix_network();
            return;
        }

        let contract_index = ranking[selected_project_index].contract_index;

        if (royalties_input_amount.current.value <= 0) {
            alert("Oops! It seems like you've discovered the secret realm of negative numbers. Unfortunately, our system only accepts positive vibes (and numbers)!")
            return;
        }

        let royalties_value = Math.floor(royalties_input_amount.current.value * 100);
        let result = await update_royalties(contract_index, royalties_value);
        if (result.status == 200) {
            // alert("Royalties update transaction sent! It will take some time for it to be confirmed by the blockchain. After it is confirmed it will take around 60 seconds for it to appear on your profile.")
            // let data = await waitForTransaction({hash:result.hash});
            // console.log({data});
            track_transaction(result.hash);
        }
        else {
            alert("An error has occured. Please make sure you have enough BNB to cover the transaction gas before trying again. If the problem persists please contact our customer support team.")
        }
    }

    const creator_claim = async () => {

        if (!check_network()) {
            fix_network();
            return;
        }

        let contract_index = ranking[selected_project_index].contract_index;
        let result = await claim_creator_rewards(contract_index);
        if (result.status == 200) {
            alert("Claim transaction sent! It will take some time for it to be confirmed by the blockchain. After it is confirmed it will take around 60 seconds for it to appear on your profile.")
            let data = await waitForTransaction({hash:result.hash});
            console.log({data});
        }
        else {
            alert("An error has occured. Please make sure you have enough BNB to cover the transaction gas before trying again. If the problem persists please contact our customer support team.")
        }
    }



    return (
        <div className="overlay_container">
            <div className="pop_up_close_outter"
                onClick={close_pop_up}
                onTouchEnd={(e)=>{
                    if (validate_touch(e)) {
                        e.preventDefault();
                        close_pop_up();
                    }
                }}
                ></div>
            <div className="pop_up_container">
                <div className="pop_up_title"><b>Project Details</b></div>
                <img className="pop_up_close" src={cross_logo}
                    onClick={close_pop_up}
                    onTouchEnd={(e)=>{
                        if (validate_touch(e)) {
                            e.preventDefault();
                            close_pop_up();
                        }
                    }}
                    />
                
                <div className="pop_up_inner">
                    <div className="info_container info_container_large">
                        <img src={ranking.length>0 && selected_project_index!=void 0?contract_imgs[ranking[selected_project_index].contract_index]:""} className="info_image_icon"/>
                        <div className="info_name"><b>{ranking.length>0 && selected_project_index!=void 0? ranking[selected_project_index].name:""}</b>
                            <img className="refresh_button" src={refresh_icon} onClick={async (event) => {
                                const refreshButton = event.target;
                                refreshButton.classList.add('spin_refresh');
                                await update_contract_info();
                                refreshButton.classList.remove('spin_refresh');
                            }}
                            onTouchEnd={async (e)=>{
                                if (validate_touch(e)) {
                                    e.preventDefault();

                                    const refreshButton = e.target;
                                    refreshButton.classList.add('spin_refresh');
                                    await update_contract_info();
                                    refreshButton.classList.remove('spin_refresh');
                                }
                            }}/>
                            <div className="last_updated">Last updated {contract_info_updated}</div>
                        </div>
                        <div className="key_figures">
                            <div className="key_figure_indicator">
                                <div className="key_figure_amount"><b>#{selected_project_index!=void 0?selected_project_index+1:""}</b></div>
                                <div className="key_figures_amount_title">Project Ranking<sup> [1]</sup></div>
                            </div>
                            <div className="key_figure_indicator key_figure_indicator_no_margin">
                                <div className="key_figure_amount"><b>{ranking.length>0 && selected_project_index!=void 0 ? short_format_number(ranking[selected_project_index].total_staked/10**18) : ""}</b></div>
                                <div className="key_figures_amount_title">Total Staked<sup> [2]</sup></div>
                            </div>
                            <div className="key_figure_indicator">
                                <div className="key_figure_amount"><b>{ranking.length>0 && selected_project_index!=void 0 ? short_format_number(ranking[selected_project_index].total_rewards/10**18) : ""}</b></div>
                                <div className="key_figures_amount_title">Total Rewards Earned<sup> [3]</sup></div>
                            </div>
                            <div className="key_figure_indicator key_figure_indicator_no_margin">
                                <div className="key_figure_amount"><b>{ranking.length>0 && selected_project_index!=void 0? calculate_apr(ranking[selected_project_index].total_staked,ranking[selected_project_index].total_rewards) : ""}%</b></div>
                                <div className="key_figures_amount_title">Estimated ApeMax APR<sup> [4]</sup></div>
                            </div>
                            <div className="key_figure_indicator">
                                <div className="key_figure_amount"><b>{royalties}</b></div>
                                <div className="key_figures_amount_title">Royalties<sup> [5]</sup></div>
                            </div>
                            <div className="key_figure_indicator key_figure_indicator_no_margin">
                                <div className="key_figure_amount"><b>{delay_nerf}</b></div>
                                <div className="key_figures_amount_title">Staking Delay<sup> [6]</sup></div>
                            </div>
                        </div>
                    </div>
                    {
                        is_contract_owner ?
                        <div className="creator_space_container">
                            <div className="project_stake_title"><b>Creator Space</b></div>
                            <div className="regular_label"
                                onClick={roylaties_update}
                                onTouchEnd={(e)=>{
                                    if (validate_touch(e)) {
                                        e.preventDefault();
                                        roylaties_update();
                                    }
                                }}
                            >Set Royalties</div>
                            <input className="royalties_input_amount" type={"number"} step="0.01" ref={royalties_input_amount} min="0"  pattern="\d+(\.\d{1,2})?" inputmode="decimal"/>
                            <div className="regular_button"><b>Update Royalties</b></div>
                            <div className="creator_claim_preview">
                                <div className="fees_inner">Claim Rewards:</div>
                                <div className="line_sep"></div>
                                <div className="fees_inner">Estimated Rewards<div className="fees_value">{creator_rewards} $ApeMax</div></div>
                            </div>
                            <div className="regular_button"
                                onClick={creator_claim}
                                onTouchEnd={(e)=>{
                                    if (validate_touch(e)) {
                                        e.preventDefault();
                                        creator_claim();
                                    }
                                }}><b>Claim Rewards</b></div>
                        </div>
                        :
                        <div></div>
                    }
                    <div className="project_stake_container">
                        <div className="project_stake_title"><b>Stake ApeMax</b></div>
                        <div className="stake_input_label">Amount of $ApeMax</div>
                        <input className="stake_input_amount" type={"number"} step="100000" ref={stake_input_amount} onChange={handleInputChange} min="\d+(\.\d{1,2})?"  pattern="\d*" inputmode="decimal"/>
                        <div className="stake_fees_preview">
                            <div className="fees_inner">Estimated Fees:</div>
                            <div className="line_sep"></div>
                            <div className="fees_inner">Creator Royalties<div className="fees_value">{estimated_fees.creator}</div></div>
                            <div className="fees_inner">Ministerial Fees<div className="fees_value">{estimated_fees.ministerial}</div></div>
                            <div className="fees_inner">Finders Fees<div className="fees_value">{estimated_fees.staker}</div></div>
                            <div className="fees_inner">Tax<div className="fees_value">{estimated_fees.tax}</div></div>
                            <div className="line_sep"></div>
                            <div className="fees_inner">Total<div className="fees_value">{estimated_fees.total}</div></div>
                            <div className="fees_inner"><b>Stake Value<div className="fees_value">{estimated_fees.stake_value}</div></b></div>
                        </div>
                        <div className="stake_button"
                            onClick={stake_fn}
                            onTouchEnd={(e)=>{
                                if (validate_touch(e)) {
                                    e.preventDefault();
                                    stake_fn();
                                }
                            }}
                        ><b>{isConnected?"Stake Now":"Connect Wallet"}</b></div>
                    </div>
                    <div className="project_chart_container">
                        <div className="project_stake_title"><b>Reward Historic</b>
                            <img className="refresh_button" src={refresh_icon} onClick={async (event) => {
                                const refreshButton = event.target;
                                refreshButton.classList.add('spin_refresh');
                                await update_chart(selected_option_index);
                                refreshButton.classList.remove('spin_refresh');
                            }}
                            onTouchEnd={async (e)=>{
                                if (validate_touch(e)) {
                                    e.preventDefault();

                                    const refreshButton = e.target;
                                    refreshButton.classList.add('spin_refresh');
                                    await update_chart(selected_option_index);
                                    refreshButton.classList.remove('spin_refresh');
                                }
                            }}/>
                            <div className="last_updated">Last updated {chart_last_updated}</div>
                        </div>
                        <div className="timing_drop_down"
                            onClick={()=>{set_data_selector_visible(!data_selector_visible)}}
                            onTouchEnd={(e)=>{
                                if (validate_touch(e)) {
                                    e.preventDefault();
                                    set_data_selector_visible(!data_selector_visible);
                                }
                            }}
                        >
                            <img className="purchase_currency_drop_down_icon" src={time_icons[selected_option_index]}/>
                            <div className="purchase_currency_drop_down_label"><b>{date_titles[selected_option_index]}</b></div>
                            <div className="purchase_currency_drop_down_extra">▼</div>
                        </div>
                        <Revenue_Chart data={rewards_chart_data} labels={rewards_chart_labels}/>
                        <div className="timing_drop_down_list">
                            {(function() {
                                let btns = [];
                                for (let i = 0; i < 3; i++) {
                                    let curI = i;
                                    btns.push(
                                        <div className="purchase_currency_drop_down_item" onClick={() => {
                                                update_chart(curI)
                                                set_data_selector_visible(false);
                                            }}
                                            onTouchEnd={(e)=>{
                                                if (validate_touch(e)) {
                                                    e.preventDefault();
                                                    update_chart(curI);
                                                    set_data_selector_visible(false);
                                                }
                                            }}>
                                            <img className="purchase_currency_drop_down_icon" src={time_icons[i]}/>
                                            <div className="purchase_currency_drop_down_label">{date_titles[i]}</div>
                                        </div>
                                    )
                                }
                                return btns;
                            })()}
                        </div>
                    </div>
                    <div className="project_activity_container">
                        <div className="project_stake_title"><b>Recent Activity</b>
                            <img className="refresh_button" src={refresh_icon} onClick={async (event) => {
                                const refreshButton = event.target;
                                refreshButton.classList.add('spin_refresh');
                                await update_recent_activty();
                                refreshButton.classList.remove('spin_refresh');
                            }}
                            onTouchEnd={async (e)=>{
                                if (validate_touch(e)) {
                                    e.preventDefault();

                                    const refreshButton = e.target;
                                    refreshButton.classList.add('spin_refresh');
                                    await update_recent_activty();
                                    refreshButton.classList.remove('spin_refresh');
                                }
                            }}/>
                            <div className="last_updated">Last updated {activity_last_updated}</div>
                        </div>
                        <div className="project_activity_inner_container">
                            {(function() {
                                let rows = [];
                                for (let i = 0; i < recent_activity.length; i++) {
                                    let curI = i;
                                    rows.push(<div className="activity_row">
                                        <img className="activity_icon" src={recent_activity[i].icon}/>
                                        <div className="activity_title"><b>{recent_activity[i].desc}</b></div>
                                        <div className="activity_sub_title clickable_text"
                                            onClick={()=>{window.open(`https://bscscan.com/tx/${recent_activity[curI].hash}`)}}
                                            onTouchEnd={(e)=>{
                                                if (validate_touch(e)) {
                                                    e.preventDefault();
                                                    window.open(`https://bscscan.com/tx/${recent_activity[curI].hash}`)
                                                }
                                            }}
                                        ><u>{shorten_crypto_string(recent_activity[i].hash)}</u></div>
                                        <div className="activity_time_label">{recent_activity[i].timestamp}</div>
                                    </div>);
                                }
                                return rows;
                            })()}
                        </div>
                    </div>

                    <Discalimer/>

                </div>
            </div>
        </div>
    );
}

export default Project_Details;